import Header from "@/components/Header";

const FinanceCheck = () => {
  return (
    <div>
      <Header title="Сверка по агенту" />
    </div>
  );
};

export default FinanceCheck;
