import Modal from "@/components/UI/Modal";
import ModalHeader from "@/components/UI/Modal/ModalHeader";
import ModalBody from "@/components/UI/Modal/ModalBody";
import ModalFooter from "@/components/UI/Modal/ModalFooter";
import Button from "@/components/UI/Button";
import Flex from "@/components/UI/Flex";
import { CalendarFormModalProps } from "./types";
import Input from "@/components/UI/Input";
import { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import { isAxiosError } from "axios";
import { triggerEvent } from "@/utils/event";
import { FormFields } from "./constants";
import { EVENTS } from "@/constants";
import {
  fetchCreateCarServiceBay,
  fetchUpdateCarServiceBay,
} from "@/features/Calendar/services";
import { postSchema } from "./schema";
import { useAppProvider } from "@/providers/AppProvider/hooks";
import { ServiceBay } from "@/features/Order/types";
import styles from "./styles.module.scss";

export const CalendarPostModal = ({
  post,
  isOpen,
  onClose,
}: CalendarFormModalProps) => {
  // @ts-ignore
  const [serviceBay, setServiceBay] = useState<ServiceBay>(() => ({
    ...{ title: "" },
    ...post,
  }));
  const { currentSubdivision } = useAppProvider();
  const [isFetching, setIsFetching] = useState(false);

  const handleSubmit = async (values: any) => {
    setIsFetching(true);

    try {
      if (post?._id) {
        await fetchUpdateCarServiceBay(post?._id, {
          title: values.title,
        });
        toast.success("Пост успешно изменен");
      } else {
        await fetchCreateCarServiceBay({
          ...values,
          subdivision: currentSubdivision?._id,
        });
        toast.success("Пост успешно добавлен");
      }
      triggerEvent(EVENTS.REFRESH_DATA);
      onClose?.();
    } catch (err) {
      setIsFetching(false);

      if (isAxiosError(err)) {
        toast.error(err?.response?.data?.error || err?.response?.data?.message);
      }
    }
  };

  return (
    <Modal className={styles.modal} isOpen={isOpen}>
      <Formik
        initialValues={serviceBay}
        onSubmit={handleSubmit}
        validationSchema={postSchema}
      >
        {({ errors, dirty, values, setFieldValue, handleChange }) => (
          <Form>
            <ModalHeader onClose={onClose}>
              <h2>{Boolean(post) ? "Редактировать пост" : "Новый пост"}</h2>
            </ModalHeader>
            <ModalBody>
              <Input
                error={errors?.title}
                name={FormFields.Title}
                onChange={handleChange}
                label="Название поста"
                placeholder="Введите название поста"
                value={values?.title}
              />
            </ModalBody>
            <ModalFooter>
              <Flex alignItems="center">
                <Button disabled={isFetching || !dirty} type="submit">
                  Сохранить
                </Button>
                <Button
                  disabled={isFetching}
                  onClick={onClose}
                  variant="secondary"
                >
                  Отмена
                </Button>
              </Flex>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
