export const getCookie = (cookieName: string) => {
  const name = `${cookieName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookies = decodedCookie.split(";");

  for (let i = 0; i < cookies.length; i += 1) {
    let cookie = cookies[i];

    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1);
    }

    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }

  return "";
};

export const setCookie = (name: string, value: string, daysAlive: number) => {
  const currentTime = new Date();
  let expires = "";

  if (daysAlive) {
    currentTime.setTime(
      currentTime.getTime() + daysAlive * 24 * 60 * 60 * 1000
    );
    expires = `expires=${currentTime.toUTCString()}`;
  }
  document.cookie = `${name}=${value};${expires};path=/`;
};

export const removeCookie = (name: string) => setCookie(name, "", -999);
