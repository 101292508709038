import api from "@/api";
import {
  AgentsFromServer,
  FetchAgentsService,
  GetIndividualAgentsRequestPayload,
  GetIndividualAgentsService,
} from "./types";
import { agentsSerialize } from "./serializer";
import {
  CounterpartAccountEntity,
  IndividualAgent,
  LegalAgent,
  PaginationInput,
} from "@/types";

// @ts-ignore
export const fetchAgents: FetchAgentsService = async ({
  pageNumber,
  pageSize,
}) => {
  const params: PaginationInput = {
    pageNumber: pageNumber || 1,
    pageSize: pageSize || 20,
  };

  const response = await api.get<AgentsFromServer<CounterpartAccountEntity>>(
    "/counterpart",
    {
      params,
    },
  );

  return agentsSerialize(response.data);
};

export const getIndividualAgents: GetIndividualAgentsService = async ({
  pageNumber,
  pageSize,
}) => {
  const params: GetIndividualAgentsRequestPayload = {
    pageNumber: pageNumber || 1,
    pageSize: pageSize || 20,
  };

  const response = await api.get<AgentsFromServer<IndividualAgent>>(
    "/individual-counterpart",
    {
      params,
    },
  );

  return agentsSerialize(response.data);
};

export const getLegalAgents = async () => {
  const response =
    await api.get<AgentsFromServer<LegalAgent>>("/legal-counterpart");
  return response.data;
};
