import { useCallback, useEffect, useState } from "react";
import Button from "@/components/UI/Button";
import Flex from "@/components/UI/Flex";
import IconAdd from "@/components/Icons/IconAdd";
import { If } from "@/components/ConditionalRendering/If";
import { CarBrandEntity, CarEntity, CarFormProps } from "./types";
import { useOnce } from "@/hooks";
import { decodeVin, getCar, searchCarsByBrand } from "@/features/Car/services";
import { Form, Formik, useFormikContext } from "formik";
import { IndividualAgent, IterableEntity } from "@/types";
import styles from "./styles.module.scss";
import { v4 as uuid } from "uuid";
import CarForm from "@/features/Car/components/CarForm";
import Section from "@/components/UI/Section";
import { getAgentFullName } from "@/pages/AgentDetail/utils";
import IconButton from "@/components/UI/IconButton";
import IconArrowUp from "@/components/Icons/IconArrowUp";
import { isArray, isEmpty } from "lodash";
import Grid from "@/components/UI/Grid";
import { CarDetail } from "@/features/Car/components/CarDetail";

const Cars = ({ onSuccess, onRemove }: CarFormProps) => {
  const { values, setValues } = useFormikContext<IndividualAgent>();
  const [cars, setCars] = useState<IterableEntity[]>([]);

  const handleRemoveCar = (_id: string) => {
    setCars((prev) => prev.filter((item) => item._id !== _id));
  };

  const handleAddCar = () => {
    setCars((prev) =>
      prev.concat({
        _id: uuid(),
      }),
    );
  };

  const handleSubmit = (car: CarEntity) => {
    setValues({
      ...values,
      cars: (values?.cars || []).concat(car),
    });
  };

  return (
    <>
      <Flex alignItems="center" justifyContent="space-between">
        <h2 className={styles.formTitle}>Автомобили</h2>
        <Button
          endIcon={IconAdd}
          onClick={handleAddCar}
          variant="empty-primary"
        >
          Автомобиль
        </Button>
      </Flex>

      <If condition={Boolean(values?.cars?.length)}>
        <Flex
          className={styles.representative}
          flexDirection="column"
          rowGap="sm"
        >
          {values?.cars?.map((car) => <CarDetail key={car._id} car={car} />)}
        </Flex>
      </If>

      <If condition={Boolean(cars.length)}>
        <Flex flexDirection="column" rowGap="xs">
          {cars.map((car) => (
            <CarForm
              key={car._id}
              id={car._id}
              onSuccess={handleSubmit}
              onRemove={handleRemoveCar}
            />
          ))}
        </Flex>
      </If>
    </>
  );
};

export default Cars;
