export enum FormFields {
  Avatar = "avatar",
  BirthDate = "birthDate",
  City = "city",
  Discount = "discount",
  FirstName = "firstName",
  MiddleName = "middleName",
  IsEmployee = "isEmployee",
  LastName = "lastName",
  Email = "email",
  Password = "password",
  Phone = "phone",
  Role = "role",
  PostAddress = "postAddress",
  Sex = "sex",
  SocialMedia = "socialMedia",
  SubscribeForNews = "subscribeForNews",
  Website = "website",
}

export const individualAgentFormInitialValues = {
  email: "",
  role: "",
  firstName: "",
  lastName: "",
  birthDate: null,
  sex: "",
  phone: "",
  postAddress: "",
  subscribeForNews: false,
  socialMedia: [],
  discount: 0,
  isEmployee: false,
};
