import cn from "classnames";
import Table from "@/components/UI/Table";
import Header from "@/components/Header";
import Grid from "@/components/UI/Grid";
import GridItem from "@/components/UI/Grid/GridItem";
import Flex from "@/components/UI/Flex";
import IconButton from "@/components/UI/IconButton";
import IconMore from "@/components/Icons/IconMore";
import BalanceAccessModal from "./components/BalanceAccessModal";
import styles from "./styles.module.scss";
import Meta from "@/components/Meta";
import { useCallback, useEffect, useState } from "react";
import {
  BalanceInfoFromServer,
  BalancePerSubdivisionFromServer,
} from "@/pages/Balance/types";
import { getIndividualAgents } from "@/pages/Agents/components/AgentsListTable/service";
import { getBalanceStatsService } from "@/pages/Balance/service";

const Balance = () => {
  const [isFetching, setIsFetching] = useState(true);

  const [balanceInfo, setBalanceInfo] = useState<BalanceInfoFromServer>({
    todayChange: 0,
    totalBalance: 0,
    todayIncome: 0,
    todayPaidOut: 0,
  });

  const [balancePerSubdivision, setBalancePerSubdivision] =
    useState<BalancePerSubdivisionFromServer>();

  const handleBalanceInfo = useCallback(() => {
    getBalanceStatsService
      .getBalanceInfo()
      .then((data) => {
        setBalanceInfo(data);
      })
      .catch(() => {
        console.error("Failed to fetch balance info");
      });
  }, []);

  const handleBalancePerSubdivision = useCallback(() => {
    getBalanceStatsService
      .getBalancePerSubdivision()
      .then((data) => {
        setBalancePerSubdivision(data);
      })
      .catch(() => {
        console.error("Failed to fetch subdivision balance info");
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, []);

  useEffect(() => {
    setIsFetching(true);
    handleBalanceInfo();
    handleBalancePerSubdivision();
  }, []);

  return (
    <>
      <div className={styles.page}>
        <Meta title="Баланс" />
        <Header showSearch={false} title="Баланс" />
        <div className="container container--center">
          <div className={styles.pageContent}>
            <div>
              <h2 className={styles.pageTitle}>
                Всего средств на рублевых балансах
              </h2>
              <Grid>
                <GridItem col={3}>
                  <div className={styles.infoBlock}>
                    <h3 className={styles.infoBlockTitle}>
                      Всего на балансах организации:
                    </h3>
                    <div className={styles.infoBlockPrice}>
                      {balanceInfo.totalBalance} ₽
                    </div>
                  </div>
                </GridItem>
                <GridItem col={3}>
                  <div className={styles.infoBlock}>
                    <h3 className={styles.infoBlockTitle}>
                      Изменение за сегодня:
                    </h3>
                    <div
                      className={cn(
                        styles.infoBlockPrice,
                        styles.infoBlockPriceDanger,
                      )}
                    >
                      {balanceInfo.todayChange} ₽
                    </div>
                  </div>
                </GridItem>
                <GridItem col={3}>
                  <div className={styles.infoBlock}>
                    <h3 className={styles.infoBlockTitle}>
                      Поступило за сегодня:
                    </h3>
                    <div
                      className={cn(
                        styles.infoBlockPrice,
                        styles.infoBlockPriceSuccess,
                      )}
                    >
                      {balanceInfo.todayIncome} ₽
                    </div>
                  </div>
                </GridItem>
                <GridItem col={3}>
                  <div className={styles.infoBlock}>
                    <h3 className={styles.infoBlockTitle}>
                      Выплатили за сегодня:
                    </h3>
                    <div
                      className={cn(
                        styles.infoBlockPrice,
                        styles.infoBlockPriceDanger,
                      )}
                    >
                      {balanceInfo.todayPaidOut} ₽
                    </div>
                  </div>
                </GridItem>
              </Grid>
            </div>

            {balancePerSubdivision?.map((subdivisionBalance) => {
              return (
                <div className={styles.section}>
                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    className={styles.sectionTop}
                  >
                    <h2 className={styles.sectionTitle}>
                      {subdivisionBalance.title}
                    </h2>
                    <IconButton
                      icon={IconMore}
                      label="Скрыть денежный ящик"
                      onClick={() => null}
                      variant="stroke"
                    />
                  </Flex>
                  <Table
                    className={styles.table}
                    header={
                      <Table.Row>
                        <Table.Cell>Наименование</Table.Cell>
                        <Table.Cell>Баланс</Table.Cell>
                        <Table.Cell>За сегодня</Table.Cell>
                        <Table.Cell>Поступило</Table.Cell>
                        <Table.Cell>Выплатили</Table.Cell>
                      </Table.Row>
                    }
                  >
                    {subdivisionBalance.balancePerTerminal.map((i) => (
                      <Table.Row key={i.title}>
                        <Table.Cell>{i.title}</Table.Cell>
                        <Table.Cell>{i.balanceInfo.totalBalance} ₽</Table.Cell>
                        <Table.Cell>{i.balanceInfo.todayChange} ₽</Table.Cell>
                        <Table.Cell>{i.balanceInfo.todayIncome} ₽</Table.Cell>
                        <Table.Cell>{i.balanceInfo.todayPaidOut} ₽</Table.Cell>
                      </Table.Row>
                    ))}
                  </Table>
                </div>
              );
            })}
            {/*<div className={styles.section}>*/}
            {/*    <Flex*/}
            {/*        alignItems="center"*/}
            {/*        justifyContent="space-between"*/}
            {/*        className={styles.sectionTop}*/}
            {/*    >*/}
            {/*        <h2 className={styles.sectionTitle}>*/}
            {/*            ИП Нисифоров Роман Алексеевич*/}
            {/*        </h2>*/}
            {/*        <IconButton*/}
            {/*            icon={IconMore}*/}
            {/*            label="Скрыть денежный ящик"*/}
            {/*            onClick={() => null}*/}
            {/*            variant="stroke"*/}
            {/*        />*/}
            {/*    </Flex>*/}
            {/*    <Table*/}
            {/*        className={styles.table}*/}
            {/*        header={*/}
            {/*            <Table.Row>*/}
            {/*                <Table.Cell>Наименование</Table.Cell>*/}
            {/*                <Table.Cell>Баланс</Table.Cell>*/}
            {/*                <Table.Cell>За сегодня</Table.Cell>*/}
            {/*                <Table.Cell>Поступило</Table.Cell>*/}
            {/*                <Table.Cell>Выплатили</Table.Cell>*/}
            {/*            </Table.Row>*/}
            {/*        }*/}
            {/*    >*/}
            {/*        {[1, 2, 3].map((i) => (*/}
            {/*            <Table.Row key={i}>*/}
            {/*                <Table.Cell>Реквизит (счет)</Table.Cell>*/}
            {/*                <Table.Cell>439 515, 37 ₽</Table.Cell>*/}
            {/*                <Table.Cell>-139 515, 37 ₽</Table.Cell>*/}
            {/*                <Table.Cell>+ 65 000 ₽</Table.Cell>*/}
            {/*                <Table.Cell>223 515, 37 ₽</Table.Cell>*/}
            {/*            </Table.Row>*/}
            {/*        ))}*/}
            {/*    </Table>*/}
            {/*</div>*/}

            {/*<div className={styles.section}>*/}
            {/*    <Flex*/}
            {/*        alignItems="center"*/}
            {/*        justifyContent="space-between"*/}
            {/*        className={styles.sectionTop}*/}
            {/*    >*/}
            {/*        <h2 className={styles.sectionTitle}>ProjectOne</h2>*/}
            {/*    </Flex>*/}
            {/*    <Table*/}
            {/*        className={styles.table}*/}
            {/*        header={*/}
            {/*            <Table.Row>*/}
            {/*                <Table.Cell>Наименование</Table.Cell>*/}
            {/*                <Table.Cell>Баланс</Table.Cell>*/}
            {/*                <Table.Cell>За сегодня</Table.Cell>*/}
            {/*                <Table.Cell>Поступило</Table.Cell>*/}
            {/*                <Table.Cell>Выплатили</Table.Cell>*/}
            {/*            </Table.Row>*/}
            {/*        }*/}
            {/*    >*/}
            {/*        {[1, 2, 3].map((i) => (*/}
            {/*            <Table.Row key={i}>*/}
            {/*                <Table.Cell>Реквизит (счет)</Table.Cell>*/}
            {/*                <Table.Cell>439 515, 37 ₽</Table.Cell>*/}
            {/*                <Table.Cell>-139 515, 37 ₽</Table.Cell>*/}
            {/*                <Table.Cell>+ 65 000 ₽</Table.Cell>*/}
            {/*                <Table.Cell>223 515, 37 ₽</Table.Cell>*/}
            {/*            </Table.Row>*/}
            {/*        ))}*/}
            {/*    </Table>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
      {/*<BalanceAccessModal />*/}
    </>
  );
};

export default Balance;
