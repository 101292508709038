import Header from "@/components/Header";
import SalaryList from "./components/SalaryList";
import Meta from "@/components/Meta";

const WorkSchedule = () => {
  return (
    <div>
      <Meta title="Рабочий график" />
      <Header />
      <SalaryList />
    </div>
  );
};

export default WorkSchedule;
