import { useState } from "react";
import Modal from "@/components/UI/Modal";
import ModalHeader from "@/components/UI/Modal/ModalHeader";
import ModalFooter from "@/components/UI/Modal/ModalFooter";
import Button from "@/components/UI/Button";
import Flex from "@/components/UI/Flex";
import ModalBody from "@/components/UI/Modal/ModalBody";
import Grid from "@/components/UI/Grid";
import Select from "@/components/UI/Select";
import Input from "@/components/UI/Input";
import { WarehouseFormModalProps } from "./types";
import IconAdd from "@/components/Icons/IconAdd";
import { useAppProvider } from "@/providers/AppProvider/hooks";
import Table from "@/components/UI/Table";
import { IterableEntity } from "@/types";
import { Scrollbar } from "react-scrollbars-custom";
import { If } from "@/components/ConditionalRendering/If";
import { v4 as uuid } from "uuid";
import { warehouseFormSchema } from "./schema";
import { FormFields, initialValues } from "./constants";
import { WarehouseEntity } from "@/features/Warehouse/types";
import { Form, Formik } from "formik";
import { createWarehouse } from "@/features/Warehouse/services";
import { isAxiosError } from "axios";
import { toast } from "react-toastify";
import WarehouseAddresses from "../WarehouseAddresses";
import { dispatchEvent } from "@/utils/event";
import { EVENTS } from "@/constants";
import styles from "./styles.module.scss";

const WarehouseFormModal = ({ open, onClose }: WarehouseFormModalProps) => {
  const [warehouse, setWarehouse] = useState<WarehouseEntity>(
    () => initialValues,
  );
  const [isFetching, setIsFetching] = useState(false);
  const { subdivisions } = useAppProvider();

  const handleSubmit = async (values: WarehouseEntity) => {
    if (isFetching) return;

    setIsFetching(true);

    try {
      const warehouseAddresses = values.warehouseAddresses?.map(
        ({ _id, ...rest }) => ({
          ...rest,
        }),
      );

      await createWarehouse({
        ...values,
        warehouseAddresses,
      });
      toast.success("Склад создан");
      dispatchEvent(EVENTS.REFRESH_DATA);
      onClose();
    } catch (err) {
      console.log(err);
      if (isAxiosError(err)) {
        toast.error(err?.response?.data || err?.response?.data?.error);
      }
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <Modal className={styles.modal} isOpen={open}>
      <Formik
        enableReinitialize
        validateOnChange={false}
        initialValues={warehouse}
        onSubmit={handleSubmit}
        validationSchema={warehouseFormSchema}
      >
        {({ values, errors, handleChange, setFieldValue }) => (
          <Form>
            <ModalHeader onClose={onClose}>
              <h2>Создание склада</h2>
            </ModalHeader>
            <ModalBody>
              <Flex flexDirection="column" rowGap="lg">
                <Grid>
                  <Grid.Item col={4}>
                    <Input
                      name={FormFields.Title}
                      error={errors[FormFields.Title]}
                      label="Название склада"
                      placeholder="Введите название"
                      onChange={handleChange}
                      value={values[FormFields.Title]}
                    />
                  </Grid.Item>
                  <Grid.Item col={4}>
                    <Input
                      name={FormFields.Address}
                      error={errors[FormFields.Address]}
                      label="Адрес склада"
                      placeholder="Введите адрес"
                      onChange={handleChange}
                      value={values[FormFields.Address]}
                    />
                  </Grid.Item>
                  <Grid.Item col={4}>
                    <Select
                      name={FormFields.Section}
                      error={errors[FormFields.Section]}
                      label="Подразделение"
                      options={subdivisions.map((subdivision) => ({
                        label: subdivision.title,
                        value: subdivision._id,
                      }))}
                      onChange={(e) =>
                        setFieldValue(FormFields.Section, e?.value)
                      }
                    />
                  </Grid.Item>
                </Grid>

                <WarehouseAddresses />
              </Flex>
            </ModalBody>
            <ModalFooter>
              <Flex>
                <Button
                  disabled={isFetching}
                  isLoading={isFetching}
                  type="submit"
                >
                  Создать
                </Button>
                <Button
                  disabled={isFetching}
                  onClick={onClose}
                  variant="secondary"
                >
                  Отмена
                </Button>
              </Flex>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default WarehouseFormModal;
