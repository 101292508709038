import ReactModal from "react-modal";
import cn from "classnames";
import { PropsWithChildren } from "react";
import type { ModalProps } from "./types";
import styles from "./styles.module.scss";

ReactModal.setAppElement("#modal");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: 0,
    overflow: "visible",
  },
};

const Modal = ({
  bodyClassName,
  children,
  header,
  isOpen,
  footer,
  className,
  onClose,
  headerClassName,
  footerClassName,
}: PropsWithChildren<ModalProps>) => {
  return (
    <ReactModal isOpen={isOpen} style={customStyles}>
      <div className={cn(styles.modal, className)}>{children}</div>
    </ReactModal>
  );
};

export default Modal;
