export enum OrderPaymentFormMethod {
  FullPayment = "fullPayment",
  PrePayment = "prePayment",
}

export const orderPaymentFormMethods = {
  [OrderPaymentFormMethod.FullPayment]: "Полная оплата",
  [OrderPaymentFormMethod.PrePayment]: "Предоплата",
};

export enum FormFields {
  Amount = "amount",
}

export const orderPaymentInitialValues = {
  amount: null,
  accountingDate: new Date(),
  paymentType: "receipt",
};
