import cn from "classnames";
import type { PropsWithChildren } from "react";
import type { TypographyProps } from "./types";
import styles from "./styles.module.scss";

const Typography = ({
  children,
  variant,
}: PropsWithChildren<TypographyProps>) => {
  return (
    <div className={cn(styles.text, styles[`text--${variant}`])}>
      {children}
    </div>
  );
};

export default Typography;
