const IconUser = ({ size = 20 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_3561_216147" fill="currentColor">
        <path d="M5.99805 6.375C4.41305 6.375 3.12305 5.085 3.12305 3.5C3.12305 1.915 4.41305 0.625 5.99805 0.625C7.58305 0.625 8.87305 1.915 8.87305 3.5C8.87305 5.085 7.58305 6.375 5.99805 6.375ZM5.99805 1.375C4.82805 1.375 3.87305 2.33 3.87305 3.5C3.87305 4.67 4.82805 5.625 5.99805 5.625C7.16805 5.625 8.12305 4.67 8.12305 3.5C8.12305 2.33 7.16805 1.375 5.99805 1.375Z" />
        <path d="M10.2931 11.375C10.0881 11.375 9.91812 11.205 9.91812 11C9.91812 9.275 8.15812 7.875 5.99812 7.875C3.83812 7.875 2.07812 9.275 2.07812 11C2.07812 11.205 1.90812 11.375 1.70312 11.375C1.49813 11.375 1.32812 11.205 1.32812 11C1.32812 8.865 3.42312 7.125 5.99812 7.125C8.57312 7.125 10.6681 8.865 10.6681 11C10.6681 11.205 10.4981 11.375 10.2931 11.375Z" />
      </mask>
      <path
        d="M5.99805 6.375C4.41305 6.375 3.12305 5.085 3.12305 3.5C3.12305 1.915 4.41305 0.625 5.99805 0.625C7.58305 0.625 8.87305 1.915 8.87305 3.5C8.87305 5.085 7.58305 6.375 5.99805 6.375ZM5.99805 1.375C4.82805 1.375 3.87305 2.33 3.87305 3.5C3.87305 4.67 4.82805 5.625 5.99805 5.625C7.16805 5.625 8.12305 4.67 8.12305 3.5C8.12305 2.33 7.16805 1.375 5.99805 1.375Z"
        fill="currentColor"
      />
      <path
        d="M10.2931 11.375C10.0881 11.375 9.91812 11.205 9.91812 11C9.91812 9.275 8.15812 7.875 5.99812 7.875C3.83812 7.875 2.07812 9.275 2.07812 11C2.07812 11.205 1.90812 11.375 1.70312 11.375C1.49813 11.375 1.32812 11.205 1.32812 11C1.32812 8.865 3.42312 7.125 5.99812 7.125C8.57312 7.125 10.6681 8.865 10.6681 11C10.6681 11.205 10.4981 11.375 10.2931 11.375Z"
        fill="currentColor"
      />
      <path
        d="M5.99805 5.375C4.96533 5.375 4.12305 4.53272 4.12305 3.5H2.12305C2.12305 5.63728 3.86076 7.375 5.99805 7.375V5.375ZM4.12305 3.5C4.12305 2.46728 4.96533 1.625 5.99805 1.625V-0.375C3.86076 -0.375 2.12305 1.36272 2.12305 3.5H4.12305ZM5.99805 1.625C7.03076 1.625 7.87305 2.46728 7.87305 3.5H9.87305C9.87305 1.36272 8.13533 -0.375 5.99805 -0.375V1.625ZM7.87305 3.5C7.87305 4.53272 7.03076 5.375 5.99805 5.375V7.375C8.13533 7.375 9.87305 5.63728 9.87305 3.5H7.87305ZM5.99805 0.375C4.27576 0.375 2.87305 1.77772 2.87305 3.5H4.87305C4.87305 2.88228 5.38033 2.375 5.99805 2.375V0.375ZM2.87305 3.5C2.87305 5.22228 4.27576 6.625 5.99805 6.625V4.625C5.38033 4.625 4.87305 4.11772 4.87305 3.5H2.87305ZM5.99805 6.625C7.72033 6.625 9.12305 5.22228 9.12305 3.5H7.12305C7.12305 4.11772 6.61576 4.625 5.99805 4.625V6.625ZM9.12305 3.5C9.12305 1.77772 7.72033 0.375 5.99805 0.375V2.375C6.61576 2.375 7.12305 2.88228 7.12305 3.5H9.12305ZM10.2931 10.375C10.6404 10.375 10.9181 10.6527 10.9181 11H8.91812C8.91812 11.7573 9.53584 12.375 10.2931 12.375V10.375ZM10.9181 11C10.9181 8.52262 8.48639 6.875 5.99812 6.875V8.875C7.82986 8.875 8.91812 10.0274 8.91812 11H10.9181ZM5.99812 6.875C3.50986 6.875 1.07812 8.52262 1.07812 11H3.07812C3.07812 10.0274 4.16639 8.875 5.99812 8.875V6.875ZM1.07812 11C1.07812 10.6527 1.35584 10.375 1.70312 10.375V12.375C2.46041 12.375 3.07812 11.7573 3.07812 11H1.07812ZM1.70312 10.375C2.05041 10.375 2.32812 10.6527 2.32812 11H0.328125C0.328125 11.7573 0.94584 12.375 1.70312 12.375V10.375ZM2.32812 11C2.32812 9.58361 3.79292 8.125 5.99812 8.125V6.125C3.05333 6.125 0.328125 8.14639 0.328125 11H2.32812ZM5.99812 8.125C8.20333 8.125 9.66812 9.58361 9.66812 11H11.6681C11.6681 8.14639 8.94292 6.125 5.99812 6.125V8.125ZM9.66812 11C9.66812 10.6527 9.94584 10.375 10.2931 10.375V12.375C11.0504 12.375 11.6681 11.7573 11.6681 11H9.66812Z"
        fill="currentColor"
        mask="url(#path-1-inside-1_3561_216147)"
      />
    </svg>
  );
};

export default IconUser;
