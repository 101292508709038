import { ProductVatEnum } from "@/features/Product/types";

export const initialValues = {
  article: "",
  title: "",
  brand: "",
  pricePerItem: "",
  vat: "",
  manufacturerCountry: "",
  sellProductAsVolume: false,
};

export enum FormFields {
  Article = "article",
  Amount = "amount",
  Barcode = "barcode",
  Title = "title",
  Brand = "brand",
  PricePerItem = "pricePerItem",
  PricePerVolume = "pricePerVolume",
  ManufacturerCountry = "manufacturerCountry",
  SellProductAsVolume = "sellProductAsVolume",
  Vat = "vat",
}

export const countries = [
  {
    label: "Россия",
    value: "russia",
  },
];

export const vats = {
  [ProductVatEnum.NoVat]: "Без НДС",
  [ProductVatEnum.Eighteen]: "18%",
  [ProductVatEnum.Ten]: "10%",
  [ProductVatEnum.Twenty]: "20%",
};
