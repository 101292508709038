const IconClose = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.93992 13.4827L13.7076 4.71496C14.0072 4.41539 14.504 4.41539 14.8036 4.71496C15.1031 5.01452 15.1031 5.51136 14.8036 5.81092L6.03588 14.5786C5.73631 14.8782 5.23948 14.8782 4.93992 14.5786C4.64035 14.2791 4.64035 13.7822 4.93992 13.4827Z"
        fill="currentColor"
      />
      <path
        d="M4.93992 4.71459C5.23948 4.41503 5.73631 4.41503 6.03588 4.71459L14.8036 13.4823C15.1031 13.7819 15.1031 14.2787 14.8036 14.5783C14.504 14.8778 14.0072 14.8778 13.7076 14.5783L4.93992 5.81055C4.64035 5.51099 4.64035 5.01415 4.93992 4.71459Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconClose;
