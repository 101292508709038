import { Form, Formik } from "formik";
import Group from "@/components/UI/Group";
import Input from "@/components/UI/Input";
import Button from "@/components/UI/Button";
import Container from "@/components/Container";
import Flex from "@/components/UI/Flex";
import IconAdd from "@/components/Icons/IconAdd";
import IconButton from "@/components/UI/IconButton";
import IconArrowUp from "@/components/Icons/IconArrowUp";
import styles from "./styles.module.scss";

const AgentBankAccounts = () => {
  const handleCreateBankAccount = () => {};

  return (
    <Container className={styles.container} center>
      <div>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          className={styles.top}
        >
          <h2 className={styles.title}>Банковские счета</h2>
          <Button endIcon={IconAdd} variant="empty-primary">
            Банковский счет
          </Button>
        </Flex>

        <ul className={styles.accounts}>
          <li className={styles.accountsItem}>
            <Flex alignItems="center" justifyContent="space-between">
              <h2 className={styles.accountsItemTitle}>
                ЮГО-ЗАПАДНЫЙ БАНК ПАО СБЕРБАНК <span>40802810652090072134</span>
              </h2>
              <IconButton
                icon={IconArrowUp}
                label="Раскрыть"
                onClick={() => null}
                variant="stroke"
              />
            </Flex>
          </li>
        </ul>

        <fieldset className={styles.fieldset}>
          <Formik initialValues={{}} onSubmit={handleCreateBankAccount}>
            {({ values, errors, handleChange, handleSubmit }) => (
              <Form className={styles.form}>
                <h3 className={styles.formTitle}>Новый банковский счет</h3>
                <Group>
                  <Input
                    label="Расчетный счет"
                    placeholder="Введите расчетный счет"
                  />
                  <Input
                    label="Название банка"
                    placeholder="Введите название банка"
                  />
                  <Input label="БИК" placeholder="Введите БИК" />
                </Group>
                <Group>
                  <Input
                    label="Корреспондентский счет"
                    placeholder="Введите КПП"
                  />
                  <Input label="КПП" placeholder="Введите КПП" />
                  <Input
                    label="Адрес банка"
                    placeholder="Введите адрес банка"
                  />
                </Group>
                <Group>
                  <Input
                    label="Корреспондентский счет"
                    placeholder="Введите КПП"
                  />
                  <Input label="КПП" placeholder="Введите КПП" />
                  <Input
                    label="Адрес банка"
                    placeholder="Введите адрес банка"
                  />
                </Group>
                <Group>
                  <Input
                    label="Корреспондентский счет"
                    placeholder="Введите КПП"
                  />
                  <Input label="КПП" placeholder="Введите КПП" />
                  <Input
                    label="Адрес банка"
                    placeholder="Введите адрес банка"
                  />
                </Group>
                <Button variant="stroke-danger">Удалить</Button>
              </Form>
            )}
          </Formik>
        </fieldset>
      </div>
    </Container>
  );
};

export default AgentBankAccounts;
