import {
  CalendarEntryColorEnum,
  CalendarEntryTypeEnum,
} from "@/features/Calendar/types";

export const calendarTabs = [
  {
    label: "Сделка",
    value: CalendarEntryTypeEnum.Order,
  },
  {
    label: "Резерв",
    value: CalendarEntryTypeEnum.Reserve,
  },
  {
    label: "Блокировка",
    value: CalendarEntryTypeEnum.Blocking,
  },
];

export const FormFields = {
  Comment: "comment",
  Contact: "contact",
  EndAt: "endAt",
  Order: "order",
  Marker: "marker",
  ServiceBay: "serviceBay",
  StartAt: "startAt",
  Type: "type",
};

export const initialValues = {
  [FormFields.EndAt]: "",
  [FormFields.Marker]: CalendarEntryColorEnum.Blue,
  [FormFields.StartAt]: "",
  [FormFields.Type]: CalendarEntryTypeEnum.Order,
};
