const IconCalendar = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66797 4.79166C6.3263 4.79166 6.04297 4.50832 6.04297 4.16666V1.66666C6.04297 1.32499 6.3263 1.04166 6.66797 1.04166C7.00964 1.04166 7.29297 1.32499 7.29297 1.66666V4.16666C7.29297 4.50832 7.00964 4.79166 6.66797 4.79166Z"
        fill="currentColor"
      />
      <path
        d="M13.332 4.79166C12.9904 4.79166 12.707 4.50832 12.707 4.16666V1.66666C12.707 1.32499 12.9904 1.04166 13.332 1.04166C13.6737 1.04166 13.957 1.32499 13.957 1.66666V4.16666C13.957 4.50832 13.6737 4.79166 13.332 4.79166Z"
        fill="currentColor"
      />
      <path
        d="M17.0846 8.19998H2.91797C2.5763 8.19998 2.29297 7.91665 2.29297 7.57498C2.29297 7.23331 2.5763 6.94998 2.91797 6.94998H17.0846C17.4263 6.94998 17.7096 7.23331 17.7096 7.57498C17.7096 7.91665 17.4263 8.19998 17.0846 8.19998Z"
        fill="currentColor"
      />
      <path
        d="M13.3333 18.9583H6.66667C3.625 18.9583 1.875 17.2083 1.875 14.1667V7.08332C1.875 4.04166 3.625 2.29166 6.66667 2.29166H13.3333C16.375 2.29166 18.125 4.04166 18.125 7.08332V14.1667C18.125 17.2083 16.375 18.9583 13.3333 18.9583ZM6.66667 3.54166C4.28333 3.54166 3.125 4.69999 3.125 7.08332V14.1667C3.125 16.55 4.28333 17.7083 6.66667 17.7083H13.3333C15.7167 17.7083 16.875 16.55 16.875 14.1667V7.08332C16.875 4.69999 15.7167 3.54166 13.3333 3.54166H6.66667Z"
        fill="currentColor"
      />
      <path
        d="M7.08333 12.0833C6.975 12.0833 6.86666 12.0583 6.76666 12.0167C6.66666 11.975 6.57501 11.9167 6.49167 11.8417C6.41667 11.7583 6.35832 11.6667 6.31666 11.5667C6.27499 11.4667 6.25 11.3583 6.25 11.25C6.25 11.0333 6.34167 10.8167 6.49167 10.6583C6.57501 10.5833 6.66666 10.525 6.76666 10.4833C6.91666 10.4167 7.08334 10.4 7.25001 10.4333C7.30001 10.4417 7.35 10.4583 7.4 10.4833C7.45 10.5 7.5 10.525 7.55 10.5583C7.59166 10.5917 7.63333 10.625 7.67499 10.6583C7.70833 10.7 7.74999 10.7417 7.77499 10.7833C7.80832 10.8333 7.83334 10.8833 7.85001 10.9333C7.87501 10.9833 7.89168 11.0333 7.90001 11.0833C7.90834 11.1417 7.91667 11.1917 7.91667 11.25C7.91667 11.4667 7.82499 11.6833 7.67499 11.8417C7.51666 11.9917 7.3 12.0833 7.08333 12.0833Z"
        fill="currentColor"
      />
      <path
        d="M10.0013 12.0833C9.78464 12.0833 9.56798 11.9916 9.40964 11.8416C9.37631 11.8 9.34298 11.7583 9.30965 11.7167C9.27631 11.6667 9.25129 11.6167 9.23462 11.5667C9.20962 11.5167 9.19296 11.4667 9.18463 11.4167C9.17629 11.3583 9.16797 11.3083 9.16797 11.25C9.16797 11.1417 9.19296 11.0333 9.23462 10.9333C9.27629 10.8333 9.33464 10.7417 9.40964 10.6583C9.64298 10.425 10.018 10.35 10.318 10.4833C10.4263 10.525 10.5096 10.5833 10.593 10.6583C10.743 10.8167 10.8346 11.0333 10.8346 11.25C10.8346 11.3083 10.8263 11.3583 10.818 11.4167C10.8096 11.4667 10.793 11.5167 10.768 11.5667C10.7513 11.6167 10.7263 11.6667 10.693 11.7167C10.6596 11.7583 10.6263 11.8 10.593 11.8416C10.5096 11.9166 10.4263 11.975 10.318 12.0167C10.218 12.0583 10.1096 12.0833 10.0013 12.0833Z"
        fill="currentColor"
      />
      <path
        d="M7.08333 15C6.975 15 6.86666 14.975 6.76666 14.9334C6.66666 14.8917 6.57501 14.8333 6.49167 14.7583C6.41667 14.675 6.35832 14.5917 6.31666 14.4833C6.27499 14.3833 6.25 14.275 6.25 14.1667C6.25 13.95 6.34167 13.7333 6.49167 13.575C6.57501 13.5 6.66666 13.4417 6.76666 13.4C7.075 13.2667 7.44166 13.3417 7.67499 13.575C7.70833 13.6167 7.74999 13.6583 7.77499 13.7C7.80832 13.75 7.83334 13.8 7.85001 13.85C7.87501 13.9 7.89168 13.95 7.90001 14.0083C7.90834 14.0583 7.91667 14.1167 7.91667 14.1667C7.91667 14.3833 7.82499 14.6 7.67499 14.7583C7.51666 14.9083 7.3 15 7.08333 15Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconCalendar;
