import Flex from "@/components/UI/Flex";
import Button from "@/components/UI/Button";
import IconAdd from "@/components/Icons/IconAdd";
import { If } from "@/components/ConditionalRendering/If";
import AgentRepresentativeModal from "../AgentRepresentativeModal";
import AgentRepresentativeForm from "../AgentRepresentativeForm";
import { getAgentFullName } from "@/pages/AgentDetail/utils";
import { useFormikContext } from "formik";
import { Agent, IndividualAgent, IterableEntity, LegalAgent } from "@/types";
import { useCallback, useState } from "react";
import ErrorMessage from "@/components/ErrorMessage";
import { AgentRepresentativeProps } from "./types";
import Section from "@/components/UI/Section";
import styles from "./styles.module.scss";
import IconArrowUp from "@/components/Icons/IconArrowUp";
import IconButton from "@/components/UI/IconButton";
import { v4 as uuid } from "uuid";
import { individualAgentFormInitialValues } from "@/features/Agent/components/AgentIndividualForm/constants";

const AgentRepresentative = ({ title }: AgentRepresentativeProps) => {
  const [representative, setRepresentative] = useState<IterableEntity[]>([]);
  const { errors, values, setValues } = useFormikContext<IndividualAgent>();
  const [showRepresentativeForm, setShowRepresentativeForm] = useState(false);
  const [showRepresentativeSelectModal, setShowRepresentativeSelectModal] =
    useState(false);

  const handleShowRepresentativeSelectModal = useCallback(() => {
    setShowRepresentativeSelectModal(true);
  }, []);

  const handleCloseRepresentativeSelectModal = useCallback(() => {
    setShowRepresentativeSelectModal(false);
  }, []);

  const handleSetRepresentative = (agents: IndividualAgent[]) => {
    setValues({
      ...values,
      representative: (values.representative || []).concat(...agents),
    });
  };

  const handleAddRepresentative = () => {
    setRepresentative((prev) =>
      prev.concat({
        _id: uuid(),
      }),
    );
  };

  const handleRemoveRepresentative = (_id: string) => {
    setRepresentative((prev) => prev.filter((item) => item._id !== _id));
  };

  return (
    <div>
      <Flex alignItems="center" justifyContent="space-between">
        <h2 className={styles.formTitle}>{title}</h2>
        <Flex alignItems="center" justifyContent="space-between">
          <If condition={!showRepresentativeForm}>
            <Button
              endIcon={IconAdd}
              onClick={handleAddRepresentative}
              variant="empty-primary"
            >
              Новый
            </Button>
            <Button
              endIcon={IconAdd}
              onClick={handleShowRepresentativeSelectModal}
              variant="empty-primary"
            >
              Существующий
            </Button>
          </If>
        </Flex>
      </Flex>

      <If condition={Boolean(errors?.representative?.length)}>
        <ErrorMessage message="Вы не добавили представителя" />
      </If>

      <If condition={Boolean(values.representative?.length)}>
        <Flex
          className={styles.representative}
          flexDirection="column"
          rowGap="sm"
        >
          {values.representative?.map((agent) => (
            <Section>
              <Flex justifyContent="space-between">
                <Flex
                  alignItems="center"
                  columnGap="sm"
                  className={styles.socialActions}
                >
                  <If condition={Boolean(agent?.avatar?.link)}>
                    <img
                      className={styles.representativeAvatar}
                      src={agent?.avatar?.link}
                      width={40}
                      height={40}
                      alt=""
                    />
                  </If>
                  {getAgentFullName(agent)}
                </Flex>
                <IconButton
                  icon={IconArrowUp}
                  onClick={() => null}
                  variant="stroke"
                />
              </Flex>
            </Section>
          ))}
        </Flex>
      </If>

      <If condition={Boolean(representative.length)}>
        {representative.map((agent) => (
          <AgentRepresentativeForm
            key={agent._id}
            id={agent._id}
            onRemove={handleRemoveRepresentative}
          />
        ))}
      </If>

      <If condition={showRepresentativeSelectModal}>
        <AgentRepresentativeModal
          onClose={handleCloseRepresentativeSelectModal}
          onSelect={handleSetRepresentative}
        />
      </If>
    </div>
  );
};

export default AgentRepresentative;
