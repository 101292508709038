const IconTrash = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4991 5.60839C17.4824 5.60839 17.4574 5.60839 17.4324 5.60839C13.0241 5.16673 8.62408 5.00006 4.26574 5.44173L2.56574 5.60839C2.21574 5.64173 1.90741 5.39173 1.87408 5.04173C1.84074 4.69173 2.09074 4.39173 2.43241 4.35839L4.13241 4.19173C8.56574 3.74173 13.0574 3.91673 17.5574 4.35839C17.8991 4.39173 18.1491 4.70006 18.1157 5.04173C18.0907 5.36673 17.8157 5.60839 17.4991 5.60839Z"
        fill="currentColor"
      />
      <path
        d="M7.08265 4.76675C7.04932 4.76675 7.01598 4.76675 6.97432 4.75842C6.64098 4.70008 6.40765 4.37508 6.46598 4.04175L6.64932 2.95008C6.78265 2.15008 6.96598 1.04175 8.90765 1.04175H11.091C13.041 1.04175 13.2243 2.19175 13.3493 2.95841L13.5326 4.04175C13.591 4.38341 13.3576 4.70842 13.0243 4.75842C12.6826 4.81675 12.3576 4.58342 12.3076 4.25008L12.1243 3.16675C12.0076 2.44175 11.9826 2.30008 11.0993 2.30008H8.91598C8.03265 2.30008 8.01598 2.41675 7.89098 3.15841L7.69932 4.24175C7.64932 4.55008 7.38265 4.76675 7.08265 4.76675Z"
        fill="currentColor"
      />
      <path
        d="M12.676 18.9584H7.32597C4.41764 18.9584 4.30097 17.3501 4.20931 16.0501L3.66764 7.6584C3.64264 7.31673 3.90931 7.01673 4.25097 6.99173C4.60097 6.97506 4.89264 7.2334 4.91764 7.57506L5.45931 15.9667C5.55097 17.2334 5.58431 17.7084 7.32597 17.7084H12.676C14.426 17.7084 14.4593 17.2334 14.5426 15.9667L15.0843 7.57506C15.1093 7.2334 15.4093 6.97506 15.751 6.99173C16.0926 7.01673 16.3593 7.3084 16.3343 7.6584L15.7926 16.0501C15.701 17.3501 15.5843 18.9584 12.676 18.9584Z"
        fill="currentColor"
      />
      <path
        d="M11.3824 14.375H8.60742C8.26576 14.375 7.98242 14.0917 7.98242 13.75C7.98242 13.4083 8.26576 13.125 8.60742 13.125H11.3824C11.7241 13.125 12.0074 13.4083 12.0074 13.75C12.0074 14.0917 11.7241 14.375 11.3824 14.375Z"
        fill="currentColor"
      />
      <path
        d="M12.0827 11.0417H7.91602C7.57435 11.0417 7.29102 10.7584 7.29102 10.4167C7.29102 10.0751 7.57435 9.79175 7.91602 9.79175H12.0827C12.4243 9.79175 12.7077 10.0751 12.7077 10.4167C12.7077 10.7584 12.4243 11.0417 12.0827 11.0417Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconTrash;
