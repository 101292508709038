import * as Yup from "yup";
import * as messages from "@/utils/validationMessages";
import { SocialMediaHandlerEntity } from "@/types";

// @ts-ignore
export const agentSocialMediaFormSchema: Yup.ObjectSchema<SocialMediaHandlerEntity> =
  Yup.object().shape({
    platform: Yup.string().required(messages.required),
    nickname: Yup.string().required(messages.required),
  });
