import Meta from "@/components/Meta";
import Header from "@/pages/CreateAgent/components/Header";
import WarehouseArrivalCreateView from "@/features/Warehouse/components/WarehouseArrivalCreateView";
import { PATHS } from "@/constants";
import styles from "./styles.module.scss";

const WarehouseArrivalCreate = () => {
  return (
    <div className={styles.content}>
      <Meta title="Склад - Поступления - Новое поступление" />
      <Header
        breadcrumbs={[
          { label: "Поступления", path: PATHS.ARRIVAL },
          { label: "Новое поступление" },
        ]}
        title="Новое поступление"
      />
      <WarehouseArrivalCreateView />
    </div>
  );
};

export default WarehouseArrivalCreate;
