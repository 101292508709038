import axios, { AxiosRequestHeaders, AxiosError } from "axios";
import { AUTH_TOKEN_COOKIE_NAME, EVENTS, SUBDIVISION } from "@/constants";
import { getCookie, setCookie } from "@/utils/cookies";

export interface ApiErrorResponse<T = unknown> {
  type: "error";
  code: number;
  message: string;
  additional: T | [];
}

export const API_URL = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
  baseURL: API_URL,
});

export type ApiError<T = unknown> = AxiosError<T>;

export const isApiError = <T = ApiErrorResponse>(
  error: unknown,
): error is ApiError<T> => axios.isAxiosError(error);

export const setAuthToken = (value: string) => {
  setCookie(AUTH_TOKEN_COOKIE_NAME, value, 1);
};

const parseErrorCode = (error: AxiosError) => {
  if (error.response) {
    if (error.response.status === 401) {
      const eventError = new CustomEvent(EVENTS.AUTH_ERROR);
      document.dispatchEvent(eventError);
    }
  }

  return Promise.reject(error);
};

axiosInstance.interceptors.request.use((config) => {
  const authToken = getCookie(AUTH_TOKEN_COOKIE_NAME);

  if (authToken) {
    if (!config.headers) {
      config.headers = {} as AxiosRequestHeaders;
    }

    config.headers.Authorization = `Bearer ${authToken}`;
  }

  config.params = config.params || {};

  config.headers["DEV_SUBDOMAIN"] = "test";
  config.headers["Subdomain"] = "test.pro1.one";

  if (Boolean(getCookie(SUBDIVISION))) {
    //config.params["subdivision"] = getCookie(SUBDIVISION);
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => parseErrorCode(error),
);

export default axiosInstance;
