import Breadcrumbs from "@/components/UI/Breadcrumbs";
import type { HeaderProps } from "./types";
import styles from "./styles.module.scss";

const Header = ({ breadcrumbs, title }: HeaderProps) => {
  return (
    <header className={styles.header}>
      <Breadcrumbs items={breadcrumbs} />
      <h1 className={styles.headerTitle}>{title}</h1>
    </header>
  );
};

export default Header;
