import api from "@/api";

export const fetchCreateCalendarEntry = async (body: any) => {
  const response = await api.post("/calendar-entry", body);
  return response.data;
};

export const fetchUpdateCalendarEntry = async (id: string, body: any) => {
  const response = await api.patch(`/calendar-entry/${id}`, body);
  return response.data;
};

export const fetchCalendarEntry = async ({
  from,
  to,
}: {
  from: string;
  to: string;
}) => {
  const response = await api.get(
    `/calendar-entry/grouped?from=${from}&to=${to}`,
  );
  return response.data;
};

export const fetchCalendarEntryByOrder = async (orderId: string) => {
  const response = await api.get(`/calendar-entry/by-order/${orderId}`);
  return response.data;
};

export const fetchCreateCarServiceBay = async (body: any) => {
  const response = await api.post("/car-service-bay", body);
  return response.data;
};

export const fetchUpdateCarServiceBay = async (id: string, body: any) => {
  const response = await api.patch(`/car-service-bay/${id}`, body);
  return response.data;
};
