import IconAvatar from "@/components/Icons/IconAvatar";
import { getAgentFullName } from "@/pages/AgentDetail/utils";
import { useAppSelector } from "@/store";
import { selectUser } from "@/store/slices/userSlice";
import { UserType } from "@/types";
import styles from "./styles.module.scss";

const UserInfo = () => {
  const user = useAppSelector(selectUser) as UserType;

  return (
    <div className={styles.userInfo}>
      {Boolean(user?.avatar?.link) ? (
        <img src={user.avatar?.link} alt="Аватарка" />
      ) : (
        <IconAvatar />
      )}
      <span className={styles.userInfoName}>
        {getAgentFullName(user) || "Без имени"}
      </span>
    </div>
  );
};

export default UserInfo;
