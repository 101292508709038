import { CarServiceSubdivisionEntity } from "@/types";

export enum CarColorEnum {
  Red = "red",
  Blue = "blue",
  Green = "green",
  Black = "black",
  White = "white",
  Silver = "silver",
  Grey = "grey",
  Yellow = "yellow",
  Orange = "orange",
  Purple = "purple",
  Brown = "brown",
  Gold = "gold",
  Pink = "pink",
}

export interface CarServiceBayEntity {
  _id: string;
  title: string;
  subdivision: CarServiceSubdivisionEntity;
}
