import {
  Agent,
  CounterpartRoleEnum,
  IndividualAgent,
  LegalAgent,
} from "@/types";

export const getAgentsByRole = (agents: Agent[], role: CounterpartRoleEnum) => {
  return agents.filter((agent) => agent?.account?.role === role);
};
