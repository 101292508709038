import Meta from "@/components/Meta";
import Header from "@/pages/CreateAgent/components/Header";
import { PATHS } from "@/constants";
import WarehouseArrivalForm from "@/features/Warehouse/components/WarehouseArrivalForm";
import styles from "./styles.module.scss";

const WarehouseArrivalDetail = () => {
  return (
    <div className={styles.content}>
      <Meta title="Склад - Поступления - Редактирование поступления" />
      <Header
        breadcrumbs={[
          { label: "Поступления", path: PATHS.ARRIVAL },
          { label: "Редактирование поступления" },
        ]}
        title="Редактирование поступления"
      />
      <WarehouseArrivalForm />
    </div>
  );
};

export default WarehouseArrivalDetail;
