import { isEmpty } from "lodash";
import { JobEntity } from "@/features/Job/types";
import { ProductEntity } from "@/features/Product/types";
import { OrderJobEntity } from "@/features/Order/types";

export const getJobsTotalPrice = (jobs: JobEntity[]) => {
  return jobs?.reduce((acc, item) => (acc += item.price), 0);
};

export const getProductsTotalPrice = (products: ProductEntity[]) => {
  return products?.reduce((acc, item) => (acc += item.price), 0);
};

export const normalizeJobs = (jobs: OrderJobEntity[], isJob = true) => {
  const data = [] as OrderJobEntity[];

  jobs.forEach((job) => {
    if (isJob) {
      job.job.isJob = true;
    }

    data.push(job);

    if (job.additionalJobs?.length) {
      data.push(
        ...normalizeJobs(job.additionalJobs as OrderJobEntity[], false),
      );
    }

    if (job.materials?.length) {
    }
  });

  return data;
};
