import Header from "@/components/Header";

const Salary = () => {
  return (
    <div>
      <Header />
    </div>
  );
};

export default Salary;
