import type { GridItemProps } from "./types";
import { PropsWithChildren } from "react";
import styles from "./styles.module.scss";
import cn from "classnames";

const GridItem = ({
  children,
  className = "",
  col = 1,
}: PropsWithChildren<GridItemProps>) => {
  return <div className={cn(styles[`grid-${col}`], className)}>{children}</div>;
};

export default GridItem;
