import IconLoading from "@/components/Icons/IconLoading";
import Flex from "@/components/UI/Flex";
import styles from "./styles.module.scss";

const PageLoader = () => {
  return (
    <Flex className={styles.loader} alignItems="center" justifyContent="center">
      <IconLoading />
    </Flex>
  );
};

export default PageLoader;
