const IconAvatarUpload = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_253_177750)">
        <rect width="40" height="40" rx="20" fill="white" />
        <rect width="40" height="40" fill="#E4E9FF" />
        <path
          d="M20 20.625C17.3583 20.625 15.2083 18.475 15.2083 15.8333C15.2083 13.1917 17.3583 11.0417 20 11.0417C22.6417 11.0417 24.7917 13.1917 24.7917 15.8333C24.7917 18.475 22.6417 20.625 20 20.625ZM20 12.2917C18.05 12.2917 16.4583 13.8833 16.4583 15.8333C16.4583 17.7833 18.05 19.375 20 19.375C21.95 19.375 23.5417 17.7833 23.5417 15.8333C23.5417 13.8833 21.95 12.2917 20 12.2917Z"
          fill="#5570F1"
        />
        <path
          d="M27.1585 28.9583C26.8168 28.9583 26.5335 28.675 26.5335 28.3333C26.5335 25.4583 23.6001 23.125 20.0001 23.125C16.4001 23.125 13.4668 25.4583 13.4668 28.3333C13.4668 28.675 13.1835 28.9583 12.8418 28.9583C12.5001 28.9583 12.2168 28.675 12.2168 28.3333C12.2168 24.775 15.7085 21.875 20.0001 21.875C24.2918 21.875 27.7835 24.775 27.7835 28.3333C27.7835 28.675 27.5001 28.9583 27.1585 28.9583Z"
          fill="#5570F1"
        />
      </g>
      <defs>
        <clipPath id="clip0_253_177750">
          <rect width="40" height="40" rx="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconAvatarUpload;
