import { CalendarProvider, CalendarView } from "@/features/Calendar";
import Meta from "@/components/Meta";
import styles from "./styles.module.scss";

const Calendar = () => {
  return (
    <div className={styles.container}>
      <Meta title="Календарь" />
      <CalendarProvider>
        <CalendarView />
      </CalendarProvider>
    </div>
  );
};

export default Calendar;
