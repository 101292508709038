import { ValidationError } from "yup";

export const getYupInnerErrors = (error: ValidationError) =>
  (error?.inner || []).reduce<Record<string, string>>((acc, currentError) => {
    if (currentError.path) {
      acc[currentError.path.split("[").join(".").split("]").join("")] =
        currentError.message;
    }

    return acc;
  }, {});
