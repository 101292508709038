import Header from "@/components/Header";

const ShipmentProfit = () => {
  return (
    <div>
      <Header title="Прибыль по отгрузке" showSearch={false} />
    </div>
  );
};

export default ShipmentProfit;
