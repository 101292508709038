import React, { useState } from "react";
import { map } from "lodash";
import Header from "@/components/Header";
import Tabs from "@/components/UI/Tabs";
import { SettingsServices } from "../SettingsServices";
import { SettingsCoefficient } from "../SettingsCoefficient";
import { SettingsSubdivision } from "../SettingsSubdivision";
import { SettingsIPAddresses } from "../SettingsIPAddresses";
import { SettingsMarkupForMaterial } from "../SettingsMarkupForMaterial";
import { SettingsMarkupForWork } from "../SettingsMarkupForWork";
import { SettingsTab, settingsTabs } from "../../constants";
import styles from "./styles.module.scss";

const SettingsView = () => {
  const [settingsTab, setSettingsTab] = useState<SettingsTab>(
    SettingsTab.Services,
  );

  return (
    <div>
      <Header title="Основные" showSearch={false} />
      <Tabs
        className={styles.tabs}
        value={settingsTab}
        onSelect={setSettingsTab}
      >
        {map(settingsTabs, ({ label, value }) => (
          <Tabs.Item key={value} value={value}>
            {label}
          </Tabs.Item>
        ))}
      </Tabs>

      {settingsTab === SettingsTab.Subdivision && <SettingsSubdivision />}
      {settingsTab === SettingsTab.Services && <SettingsServices />}
      {settingsTab === SettingsTab.IPAddresses && <SettingsIPAddresses />}
      {settingsTab === SettingsTab.Coefficient && <SettingsCoefficient />}
      {settingsTab === SettingsTab.MarkupForWork && <SettingsMarkupForWork />}
      {settingsTab === SettingsTab.MarkupForMaterial && (
        <SettingsMarkupForMaterial />
      )}
    </div>
  );
};

export default SettingsView;
