import dayjs from "@/utils/dayjs";
import { Dayjs } from "dayjs";

export enum CalendarRange {
  Day = "day",
  Month = "month",
  Week = "week",
}

export const calendarRanges = [
  { label: CalendarRange.Day, value: "День" },
  { label: CalendarRange.Week, value: "Неделя" },
  { label: CalendarRange.Month, value: "Месяц" },
];

export const getRangeValues = (
  range: CalendarRange,
  startDate = dayjs(),
): { format: string; label: string; values: Dayjs[] } => {
  switch (range) {
    case CalendarRange.Month: {
      startDate = startDate.startOf("month");
      const daysInMonth = startDate.daysInMonth();

      const values = [];

      for (let i = 0; i < daysInMonth; i++) {
        values.push(startDate.add(i, "day"));
      }

      return {
        format: "DD.MM",
        label: "Дата",
        values,
      };
    }

    case CalendarRange.Week: {
      startDate = startDate.startOf("week");

      const values = [];

      for (let i = 0; i < 7; i++) {
        values.push(startDate.add(i, "day"));
      }

      return {
        format: "DD.MM dddd",
        label: "Дата",
        values,
      };
    }

    case CalendarRange.Day: {
      startDate = startDate.set("hour", 9).set("minutes", 0).set("seconds", 0);

      const values = [];

      for (let i = 0; i < 10; i++) {
        values.push(startDate.add(i, "hour"));
      }

      return {
        format: "HH:mm",
        label: "Время",
        values,
      };
    }
  }
};
