import * as Yup from "yup";
import { FormFields } from "./constants";
import * as messages from "@/utils/validationMessages";
import { CarServiceSubdivisionEntity } from "@/features/Subdivision/types";

// @ts-ignore
export const serviceFormSchema: Yup.ObjectSchema<CarServiceSubdivisionEntity> =
  Yup.object().shape({
    [FormFields.Address]: Yup.string().required(messages.required),
    [FormFields.Title]: Yup.string().required(messages.required),
  });
