import * as Yup from "yup";
import * as messages from "@/utils/validationMessages";
import { IndividualAgent } from "@/types";
import { FormFields } from "./constants";

// @ts-ignore
export const warehouseArrivalFormSchema: Yup.ObjectSchema<IndividualAgent> =
  Yup.object().shape({
    [FormFields.AccountingDate]: Yup.string().required(messages.required),
    [FormFields.DocumentType]: Yup.string().required(messages.required),
    [FormFields.Responsible]: Yup.string().required(messages.required),
    [FormFields.Supplier]: Yup.string().required(messages.required),
  });
