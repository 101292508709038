import Flex from "@/components/UI/Flex";
import Button from "@/components/UI/Button";
import IconAdd from "@/components/Icons/IconAdd";
import styles from "./styles.module.scss";
import { SettingsMarkupForMaterialTableList } from "../SettingsMarkupForMaterialTableList";

export const SettingsMarkupForMaterial = () => {
  return (
    <div className={styles.wrapper}>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        className={styles.wrapperTop}
      >
        <h2 className={styles.title}>Наценки на запчасти и материалы</h2>
        <Button endIcon={IconAdd} onClick={() => {}} variant="secondary">
          Наценка на товар
        </Button>
      </Flex>
      <SettingsMarkupForMaterialTableList />
    </div>
  );
};
