const IconLink = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7505 15.2083H12.4922C12.1505 15.2083 11.8672 14.925 11.8672 14.5833C11.8672 14.2416 12.1505 13.9583 12.4922 13.9583H13.7505C15.9339 13.9583 17.7089 12.1833 17.7089 9.99996C17.7089 7.81663 15.9339 6.04163 13.7505 6.04163H12.5005C12.1589 6.04163 11.8755 5.75829 11.8755 5.41663C11.8755 5.07496 12.1505 4.79163 12.5005 4.79163H13.7505C16.6255 4.79163 18.9589 7.12496 18.9589 9.99996C18.9589 12.875 16.6255 15.2083 13.7505 15.2083Z"
        fill="white"
      />
      <path
        d="M7.49935 15.2083H6.24935C3.37435 15.2083 1.04102 12.875 1.04102 9.99996C1.04102 7.12496 3.37435 4.79163 6.24935 4.79163H7.49935C7.84102 4.79163 8.12435 5.07496 8.12435 5.41663C8.12435 5.75829 7.84102 6.04163 7.49935 6.04163H6.24935C4.06602 6.04163 2.29102 7.81663 2.29102 9.99996C2.29102 12.1833 4.06602 13.9583 6.24935 13.9583H7.49935C7.84102 13.9583 8.12435 14.2416 8.12435 14.5833C8.12435 14.925 7.84102 15.2083 7.49935 15.2083Z"
        fill="white"
      />
      <path
        d="M13.3327 10.625H6.66602C6.32435 10.625 6.04102 10.3417 6.04102 10C6.04102 9.65833 6.32435 9.375 6.66602 9.375H13.3327C13.6743 9.375 13.9577 9.65833 13.9577 10C13.9577 10.3417 13.6743 10.625 13.3327 10.625Z"
        fill="white"
      />
    </svg>
  );
};

export default IconLink;
