import cn from "classnames";
import type { SwitchProps } from "./types";
import type { PropsWithChildren } from "react";
import styles from "./styles.module.scss";

const Switch = ({
  boxAlign = "start",
  className = "",
  children = null,
  value,
  ...props
}: PropsWithChildren<SwitchProps>) => {
  return (
    <label className={cn(styles.switch, className)}>
      <input {...props} type="checkbox" className={styles.switchHandle} />

      {Boolean(children) && <span className={styles.label}>{children}</span>}

      <span
        className={cn(styles.switchBox, styles[`switchBox-align-${boxAlign}`])}
      />

      {Boolean(value) && <span className={styles.switchValue}>{value}</span>}
    </label>
  );
};

export default Switch;
