import Container from "@/components/Container";
import styles from "./styles.module.scss";

const AgentNotes = () => {
  return (
    <Container className={styles.container} center>
      <div className={styles.note}>
        <time className={styles.noteTime}>12:00 28.05.2023</time>
        <p className={styles.noteDescription}>
          Wazzup, исходящее сообщение к Сидельник Максим Геннадьевич:
          Здравствуйте Максим ! Это Project One! Вы записаны к нам 15-04-2024 в
          15:00 Наш адрес - г.Ростов-на-Дону, ул. Текучёва, 207Б Вы можете легко
          построить маршрут к нам прейдя по ссылке:
          https://yandex.ru/navi/org/58629988910
        </p>
      </div>
    </Container>
  );
};

export default AgentNotes;
