import { useCallback, useState } from "react";
import Header from "@/components/Header";
import { Dropdown } from "rsuite";
import PaymentsListTable from "../PaymentsListTable";
import PaymentFormModal from "../PaymentFormModal";
import PaymentDetailModal from "../PaymentDetailModal";
import { PaymentTypeEnum } from "../../constants";
import { Payment } from "@/features/Payment/types";
import Button from "@/components/UI/Button";
import IconAdd from "@/components/Icons/IconAdd";
import styles from "./styles.module.scss";

const renderButton = (props: any, ref: any) => {
  return (
    <Button {...props} ref={ref} endIcon={IconAdd}>
      ДДС
    </Button>
  );
};

const PaymentView = () => {
  const [showModal, setShowModal] = useState(false);
  const [payment, setPayment] = useState<Payment | null>(null);
  const [paymentType, setPaymentType] = useState<PaymentTypeEnum | null>(null);

  const handleShowModal = useCallback((_paymentType: PaymentTypeEnum) => {
    setShowModal(true);
    setPaymentType(_paymentType);
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
    setPaymentType(null);
  }, []);

  const handleClosePaymentDetail = () => {
    setPayment(null);
  };

  return (
    <div className={styles.container}>
      <Header
        title="Движение денежных средств"
        showSearch={false}
        actions={
          <Dropdown
            renderToggle={renderButton}
            title="Dropdown"
            placement="bottomEnd"
          >
            <Dropdown.Item
              onClick={() => handleShowModal(PaymentTypeEnum.Receipt)}
            >
              Создать поступление денежных средств
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => handleShowModal(PaymentTypeEnum.Withdrawal)}
            >
              Создать выдачу денежных средств
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => handleShowModal(PaymentTypeEnum.Transfer)}
            >
              Создать перемещение денежных средств
            </Dropdown.Item>
          </Dropdown>
        }
      ></Header>
      <PaymentsListTable onSelectPayment={setPayment} />
      <PaymentFormModal
        isOpen={showModal}
        onClose={handleCloseModal}
        paymentType={paymentType}
      />
      <PaymentDetailModal
        onClose={handleClosePaymentDetail}
        payment={payment}
      />
    </div>
  );
};

export default PaymentView;
