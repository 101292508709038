import TableBuilder from "@/components/TableBuilder";
import { columns } from "./constants";
import { useEventListener, useRequest } from "@/hooks";
import LoadingIndicatorPage from "@/components/LoadingIndicatorPage";
import { getWarehouseTransfer } from "@/features/Warehouse/services";
import { WarehouseArrivalEntity } from "@/features/Warehouse/types";
import { warehouseArrivalSerialize } from "@/features/Warehouse/serializer";
import { EVENTS } from "@/constants";

const WarehouseTransferTable = () => {
  const {
    data,
    isFetching,
    fetchData,
    totalPages,
    pageNumber,
    pageSize,
    totalItems,
  } = useRequest<WarehouseArrivalEntity>(
    getWarehouseTransfer,
    warehouseArrivalSerialize,
  );

  useEventListener(EVENTS.REFRESH_DATA, fetchData);

  return (
    <TableBuilder
      isFetching={isFetching}
      columns={columns}
      data={data as WarehouseArrivalEntity[]}
      pageNumber={pageNumber}
      pageSize={pageSize}
      handlePageChange={() => null}
      totalItems={totalItems}
      totalPages={totalPages}
    />
  );
};

export default WarehouseTransferTable;
