import * as Yup from "yup";
import * as messages from "@/utils/validationMessages";
import * as patterns from "@/utils/validationPatterns";
import type { AuthFormData } from "../../types";

export const authFormSchema: Yup.ObjectSchema<AuthFormData> =
  Yup.object().shape({
    email: Yup.string()
      .matches(patterns.emailRegex, messages.validEmail)
      .required(messages.required),
    password: Yup.string().required(messages.required),
  });
