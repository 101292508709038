import { WalletsFromServer } from "./types";
import { ownerTypes } from "@/features/Wallet/constants";

export const walletsSerialize = (data: WalletsFromServer) => ({
  items: data.items.filter(
    ({ ownerType, ownerTitle }) =>
      ownerTypes.has(ownerType) && Boolean(ownerTitle)
  ),
  pagination: {
    pageNumber: data.pageNumber,
    pageSize: data.pageSize,
    totalItems: data.totalItems,
    totalPages: data.totalPages,
  },
});
