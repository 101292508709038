import IconAdd from "@/components/Icons/IconAdd";
import Button from "@/components/UI/Button";
import { useMemo, useState } from "react";
import { If } from "@/components/ConditionalRendering/If";
import { OrderCarFormModal } from "./OrderCarFormModal";
import IconButton from "@/components/UI/IconButton";
import IconMore from "@/components/Icons/IconMore";
import Flex from "@/components/UI/Flex";
import IconLoading from "@/components/Icons/IconLoading";
import { AutoComplete, Dropdown } from "rsuite";
import { OrderCarFormProps } from "./types";
import { updateOrder } from "@/features/Order/services";
import { isAxiosError } from "axios";
import { toast } from "react-toastify";
import { OrderCarDetailFormModal } from "./OrderCarDetailFormModal";
import { OrderCarTableModal } from "./OrderCarTableModal";
import styles from "./styles.module.scss";
import { CarEntity } from "@/features/Car/components/Cars/types";
import { triggerEvent } from "@/utils/event";
import { EVENTS } from "@/constants";

export const OrderCarForm = ({ order, onRemoveCar }: OrderCarFormProps) => {
  const [cars, setCars] = useState<CarEntity[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [showCarFormModal, setShowCarFormModal] = useState(false);
  const [showCarTableModal, setShowCarTableModal] = useState(false);
  const [showCarDetailFormModal, setShowCarDetailFormModal] = useState(false);

  const car = useMemo(() => order?.car, [order?.car]);

  const handleSearchCar = (value: string) => {
    const filteredCars = order?.client?.cars?.filter(
      (car) =>
        car.brand?.toLowerCase()?.includes(value.toLowerCase()) ||
        car.model?.toLowerCase()?.includes(value.toLowerCase()),
    );

    setCars(
      // @ts-ignore
      filteredCars?.map((car) => ({
        label: `${car?.brand} ${car?.model}`,
        value: car?._id,
      })),
    );
  };

  const handleShowCarTableModal = () => {
    setShowCarTableModal(true);
  };

  const handleHideCarTableModal = () => {
    setShowCarTableModal(false);
  };

  const handleShowCarDetailFormModal = () => {
    setShowCarDetailFormModal(true);
  };

  const handleHideCarDetailFormModal = () => {
    setShowCarDetailFormModal(false);
  };

  const handleShowCarFormModal = () => {
    setShowCarFormModal(true);
  };

  const handleHideCarFormModal = () => {
    setShowCarFormModal(false);
  };

  const handleRemoveCar = async () => {
    setIsFetching(true);

    try {
      await updateOrder(order?._id as string, {
        car: null,
      });
      toast.success("Автомобиль удален из сделки");
      onRemoveCar();
    } catch (err) {
      if (isAxiosError(err)) {
        toast.error(err?.response?.data?.error || err?.response?.data?.message);
      }
    } finally {
      setIsFetching(false);
    }
  };

  const handleSetOrderCar = async (sourceCar: string) => {
    setIsFetching(true);

    try {
      await updateOrder(order?._id as string, {
        car: {
          sourceCar,
        },
      });

      toast.success("Автомобиль успешно добавлен");
      triggerEvent(EVENTS.REFRESH_DATA);
    } catch (err) {
      if (isAxiosError(err)) {
        toast.error(err?.response?.data?.error || err?.response?.data?.message);
      }
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <>
      {Boolean(car) ? (
        <Flex columnGap="zero">
          {isFetching ? (
            <IconLoading />
          ) : (
            <>
              <Button variant="empty-primary">
                {car?.sourceCar?.brand} {car?.sourceCar?.model}
              </Button>
              <Dropdown
                renderToggle={renderButton}
                title="Dropdown"
                placement="bottomEnd"
              >
                <Dropdown.Item onClick={handleRemoveCar}>
                  Удалить автомобиль из сделки
                </Dropdown.Item>
                <Dropdown.Item onClick={handleShowCarDetailFormModal}>
                  Открыть карточку автомобиля
                </Dropdown.Item>
                <Dropdown.Item onClick={handleShowCarFormModal}>
                  Создать новый автомобиль
                </Dropdown.Item>
                <Dropdown.Item onClick={handleShowCarTableModal}>
                  Открыть список авто контрагента
                </Dropdown.Item>
              </Dropdown>
            </>
          )}
        </Flex>
      ) : (
        <Flex columnGap="xxs">
          <Flex flexDirection="column" rowGap="xxs" className={styles.textRow}>
            {isFetching ? (
              <IconLoading />
            ) : (
              <>
                <AutoComplete
                  data={cars}
                  onChange={handleSearchCar}
                  onSelect={handleSetOrderCar}
                  placeholder="Марка или модель автомобиля"
                />
                <Button
                  className={styles.smallBtn}
                  disabled={!Boolean(order?.client)}
                  onClick={handleShowCarFormModal}
                  endIcon={() => <IconAdd size={16} />}
                  variant="empty-primary"
                >
                  Добавить ТС
                </Button>
              </>
            )}
          </Flex>
        </Flex>
      )}

      <If condition={showCarFormModal}>
        <OrderCarFormModal
          isOpen={showCarFormModal}
          onClose={handleHideCarFormModal}
          order={order}
        />
      </If>

      <If condition={showCarDetailFormModal}>
        <OrderCarDetailFormModal
          isOpen={true}
          car={order?.car?.sourceCar}
          onClose={handleHideCarDetailFormModal}
        />
      </If>

      <If condition={showCarTableModal}>
        <OrderCarTableModal
          isOpen={showCarTableModal}
          onClose={handleHideCarTableModal}
          order={order}
        />
      </If>
    </>
  );
};

const renderButton = (props: any, ref: any) => {
  return (
    <IconButton variant="empty-primary" {...props} ref={ref} icon={IconMore} />
  );
};
