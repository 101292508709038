import { useCallback, useMemo } from "react";

import { parse, stringify } from "qs";
import { useNavigate, useLocation } from "react-router-dom";

const useQueryParams = <TQuery extends object>(initialParams?: TQuery) => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const query = useMemo(() => {
    const searchQuery = search.startsWith("?") ? search.slice(1) : search;

    if (!search && initialParams) {
      return initialParams;
    }

    return { ...initialParams, ...parse(searchQuery) } as TQuery;
  }, [search, initialParams]);

  const setQuery = useCallback(
    (
      nextParams: TQuery,
      method: "push" | "remove" = "push",
      replace = false,
    ) => {
      let nextQuery = { ...query, ...nextParams };

      navigate(
        { search: stringify(nextQuery, { encode: false }) },
        { replace },
      );
    },
    [navigate, query],
  );

  return [{ query, rawQuery: search }, setQuery] as const;
};

export default useQueryParams;
