import { useMemo } from "react";
import cn from "classnames";
import { capitalize } from "lodash";
import Flex from "@/components/UI/Flex";
import IconCalendar from "@/components/Icons/IconCalendar";
import dayjs from "@/utils/dayjs";
import type { CalendarEntryItemProps } from "./types";
import styles from "./styles.module.scss";
import { Dayjs } from "dayjs";
import { getAgentFullName } from "@/pages/AgentDetail/utils";
import { OrderEntity } from "@/features/Order/types";
import { If } from "@/components/ConditionalRendering/If";
import IconUser from "@/components/Icons/IconUser";
import IconCall from "@/components/Icons/IconCall";
import IconEdit from "@/components/Icons/IconEdit";
import { Tooltip, Whisper } from "rsuite";
import { useCalendarContext } from "@/features/Calendar";

export const CalendarEntryItem = ({ index, item }: CalendarEntryItemProps) => {
  const { setShowCalendarEntityModal } = useCalendarContext();

  const dateRange = useMemo(() => {
    if (!item) return null;

    return {
      startAt: dayjs(item.startAt),
      endAt: dayjs(item.endAt),
    };
  }, [item]);

  if (!item) {
    return null;
  }

  const { startAt, endAt } = dateRange as { startAt: Dayjs; endAt: Dayjs };

  return (
    <>
      <div
        className={cn(
          styles.calendarEntry,
          styles[`calendarEntry${capitalize(item.marker)}`],
        )}
        role="button"
        onClick={() => {
          setShowCalendarEntityModal!({ show: true, item });
        }}
        style={{
          zIndex: 111 + index,
          width: `${100 * Number(item?.count)}%`,
        }}
      >
        <Flex
          className={styles.calendarEntryInner}
          flexDirection="column"
          rowGap="xs"
        >
          <DateRange startAt={startAt} endAt={endAt} />
          <If condition={Boolean((item?.order as OrderEntity)?.client)}>
            <Flex alignItems="center" columnGap="xxs">
              <div className={styles.flexHelper}>
                <IconUser size={14} />
                {getAgentFullName(
                  (item?.order as OrderEntity)?.client?.counterpartProfile!,
                )}
              </div>
            </Flex>
            <Flex alignItems="center" columnGap="xxs">
              <div className={styles.flexHelper}>
                <IconCall size={14} />
                {
                  (item?.order as OrderEntity)?.client?.counterpartProfile
                    ?.phone
                }
              </div>
            </Flex>
            <If condition={Boolean(item?.comment?.length)}>
              <Flex alignItems="center" columnGap="xxs">
                <div className={styles.flexHelper}>
                  <IconEdit size={14} />
                  {item?.comment}
                </div>
              </Flex>
            </If>
          </If>
        </Flex>
      </div>

      {/*<If condition={showPostModal}>*/}
      {/*  <CalendarPostModal*/}
      {/*    post={post}*/}
      {/*    isOpen={showPostModal}*/}
      {/*    onClose={() => setShowPostModal(false)}*/}
      {/*  />*/}
      {/*</If>*/}
    </>
  );
};

const DateRange = ({ startAt, endAt }: { startAt: Dayjs; endAt: Dayjs }) => {
  const date = useMemo(
    () =>
      `${startAt.format("DD.MM HH:mm [ - ]")} ${endAt.format("DD.MM HH:mm")}`,
    [startAt, endAt],
  );

  return (
    <Flex alignItems="center" columnGap="xxs">
      <Whisper
        controlId={`${startAt}${endAt}`}
        placement="left"
        trigger="hover"
        speaker={<Tooltip>{date}</Tooltip>}
      >
        <div className={styles.flexHelper}>
          <IconCalendar size={16} />
          {date}
        </div>
      </Whisper>
    </Flex>
  );
};
