import {
  WarehouseArrivalDocumentTypeEnum,
  WarehouseArrivalEntity,
} from "@/features/Warehouse/types";

export const warehouseArrivalDocuments = {
  [WarehouseArrivalDocumentTypeEnum.GoodsReceiptNote]: "Товарная накладная",
  [WarehouseArrivalDocumentTypeEnum.UniversalTransferDocument]: "УПД",
  [WarehouseArrivalDocumentTypeEnum.Invoice]: "Счет фактура",
  [WarehouseArrivalDocumentTypeEnum.InventoryCheck]: "Инвентаризация",
  [WarehouseArrivalDocumentTypeEnum.Receipt]: "Чек",
};

export enum FormFields {
  AccountingDate = "accountingDate",
  DocumentType = "documentType",
  Supplier = "supplier",
  Responsible = "responsible",
  Products = "products",
  Warehouse = "warehouse",
}

export const initialValues = {
  [FormFields.AccountingDate]: null,
  [FormFields.DocumentType]: "",
  [FormFields.Supplier]: "",
  [FormFields.Warehouse]: "undefined",
  [FormFields.Products]: [],
  [FormFields.Responsible]: "",
};
