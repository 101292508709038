import { IconProps } from "./types";

const IconAvatar = ({ width = 28, height = 28 }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_206_25151)">
        <rect width="28" height="28" rx="14" fill="white" />
        <rect width="28" height="28" fill="#E4E9FF" />
        <path
          d="M14 14.4375C12.1508 14.4375 10.6458 12.9325 10.6458 11.0833C10.6458 9.23413 12.1508 7.72913 14 7.72913C15.8492 7.72913 17.3542 9.23413 17.3542 11.0833C17.3542 12.9325 15.8492 14.4375 14 14.4375ZM14 8.60413C12.635 8.60413 11.5208 9.71829 11.5208 11.0833C11.5208 12.4483 12.635 13.5625 14 13.5625C15.365 13.5625 16.4792 12.4483 16.4792 11.0833C16.4792 9.71829 15.365 8.60413 14 8.60413Z"
          fill="#5570F1"
        />
        <path
          d="M19.0109 20.2708C18.7718 20.2708 18.5734 20.0725 18.5734 19.8333C18.5734 17.8208 16.5201 16.1875 14.0001 16.1875C11.4801 16.1875 9.42676 17.8208 9.42676 19.8333C9.42676 20.0725 9.22842 20.2708 8.98926 20.2708C8.75009 20.2708 8.55176 20.0725 8.55176 19.8333C8.55176 17.3425 10.9959 15.3125 14.0001 15.3125C17.0043 15.3125 19.4484 17.3425 19.4484 19.8333C19.4484 20.0725 19.2501 20.2708 19.0109 20.2708Z"
          fill="#5570F1"
        />
      </g>
      <defs>
        <clipPath id="clip0_206_25151">
          <rect width="28" height="28" rx="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconAvatar;
