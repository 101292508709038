const IconCar = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6661 9.79173H3.33281C3.14114 9.79173 2.96614 9.7084 2.84947 9.5584C2.73281 9.41673 2.68281 9.21673 2.72447 9.0334L3.66614 4.5334C3.97447 3.07507 4.59947 1.7334 7.07447 1.7334H12.9328C15.4078 1.7334 16.0328 3.0834 16.3411 4.5334L17.2828 9.04173C17.3245 9.22507 17.2745 9.41673 17.1578 9.56673C17.0328 9.7084 16.8578 9.79173 16.6661 9.79173ZM4.09947 8.54173H15.8911L15.1078 4.79173C14.8745 3.70007 14.5995 2.9834 12.9245 2.9834H7.07447C5.39947 2.9834 5.12447 3.70007 4.89114 4.79173L4.09947 8.54173Z"
        fill="#D4D4DE"
      />
      <path
        d="M16.633 18.9584H15.0663C13.7163 18.9584 13.458 18.1834 13.2913 17.6751L13.1247 17.1751C12.908 16.5417 12.883 16.4584 12.133 16.4584H7.86633C7.11633 16.4584 7.06633 16.6001 6.87466 17.1751L6.708 17.6751C6.533 18.1917 6.283 18.9584 4.933 18.9584H3.36633C2.708 18.9584 2.07466 18.6834 1.633 18.2001C1.19966 17.7251 0.991331 17.0917 1.04966 16.4584L1.51633 11.3834C1.64133 10.0084 2.008 8.54172 4.683 8.54172H15.3163C17.9913 8.54172 18.3497 10.0084 18.483 11.3834L18.9497 16.4584C19.008 17.0917 18.7997 17.7251 18.3663 18.2001C17.9247 18.6834 17.2913 18.9584 16.633 18.9584ZM7.86633 15.2084H12.133C13.6497 15.2084 14.008 15.8834 14.308 16.7667L14.483 17.2834C14.6247 17.7084 14.6247 17.7167 15.0747 17.7167H16.6413C16.9497 17.7167 17.2413 17.5917 17.4497 17.3667C17.6497 17.1501 17.7413 16.8751 17.7163 16.5834L17.2497 11.5084C17.1413 10.3834 17.008 9.80005 15.333 9.80005H4.683C2.99966 9.80005 2.86633 10.3834 2.76633 11.5084L2.29966 16.5834C2.27466 16.8751 2.36633 17.1501 2.56633 17.3667C2.76633 17.5917 3.06633 17.7167 3.37466 17.7167H4.94133C5.39133 17.7167 5.39133 17.7084 5.533 17.2917L5.69966 16.7834C5.908 16.1167 6.21633 15.2084 7.86633 15.2084Z"
        fill="#D4D4DE"
      />
      <path
        d="M3.33333 7.29172H2.5C2.15833 7.29172 1.875 7.00838 1.875 6.66672C1.875 6.32505 2.15833 6.04172 2.5 6.04172H3.33333C3.675 6.04172 3.95833 6.32505 3.95833 6.66672C3.95833 7.00838 3.675 7.29172 3.33333 7.29172Z"
        fill="#D4D4DE"
      />
      <path
        d="M17.4993 7.29172H16.666C16.3243 7.29172 16.041 7.00838 16.041 6.66672C16.041 6.32505 16.3243 6.04172 16.666 6.04172H17.4993C17.841 6.04172 18.1243 6.32505 18.1243 6.66672C18.1243 7.00838 17.841 7.29172 17.4993 7.29172Z"
        fill="#D4D4DE"
      />
      <path
        d="M10 4.79173C9.65833 4.79173 9.375 4.50839 9.375 4.16673V2.50006C9.375 2.15839 9.65833 1.87506 10 1.87506C10.3417 1.87506 10.625 2.15839 10.625 2.50006V4.16673C10.625 4.50839 10.3417 4.79173 10 4.79173Z"
        fill="#D4D4DE"
      />
      <path
        d="M11.25 4.79172H8.75C8.40833 4.79172 8.125 4.50838 8.125 4.16672C8.125 3.82505 8.40833 3.54172 8.75 3.54172H11.25C11.5917 3.54172 11.875 3.82505 11.875 4.16672C11.875 4.50838 11.5917 4.79172 11.25 4.79172Z"
        fill="#D4D4DE"
      />
      <path
        d="M7.5 13.1251H5C4.65833 13.1251 4.375 12.8417 4.375 12.5001C4.375 12.1584 4.65833 11.8751 5 11.8751H7.5C7.84167 11.8751 8.125 12.1584 8.125 12.5001C8.125 12.8417 7.84167 13.1251 7.5 13.1251Z"
        fill="#D4D4DE"
      />
      <path
        d="M15 13.1251H12.5C12.1583 13.1251 11.875 12.8417 11.875 12.5001C11.875 12.1584 12.1583 11.8751 12.5 11.8751H15C15.3417 11.8751 15.625 12.1584 15.625 12.5001C15.625 12.8417 15.3417 13.1251 15 13.1251Z"
        fill="#D4D4DE"
      />
    </svg>
  );
};

export default IconCar;
