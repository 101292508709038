import { PaymentsFromServer } from "./types";

export const paymentsSerialize = (data: PaymentsFromServer) => ({
  items: data.items,
  pagination: {
    pageNumber: data.pageNumber,
    pageSize: data.pageSize,
    totalItems: data.totalItems,
    totalPages: data.totalPages,
  },
});
