import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useAppDispatch } from "@/store";
import { useNavigate } from "react-router";
import { EVENTS, PATHS } from "@/constants";
import { resetUser } from "@/store/slices/userSlice";

const useCheckAuth = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handler = () => {
      dispatch(resetUser());
      navigate(PATHS.AUTH);
    };

    document.addEventListener(EVENTS.AUTH_ERROR, handler);

    return () => document.removeEventListener(EVENTS.AUTH_ERROR, handler);
  }, [location.pathname]);
};

export default useCheckAuth;
