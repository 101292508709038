import Button from "@/components/UI/Button";
import IconAdd from "@/components/Icons/IconAdd";
import { CalendarFormModal } from "@/features/Calendar";
import { useEffect, useState } from "react";
import { fetchCalendarEntryByOrder } from "@/features/Calendar/services";
import IconLoading from "@/components/Icons/IconLoading";
import { OrderCalendarFormProps } from "./types";
import { CalendarEntity } from "@/features/Calendar/types";
import { getBaseDate } from "@/utils/date";

export const OrderCalendarForm = ({ order }: OrderCalendarFormProps) => {
  const [calendarEntity, setCalendarEntity] = useState<CalendarEntity>();
  const [showModal, setShowModal] = useState(false);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    if (order?._id) {
      fetchCalendarEntryByOrder(order?._id)
        .then((res) => {
          setCalendarEntity(res);
        })
        .catch(() => {})
        .finally(() => {
          setIsFetching(false);
        });
    }
  }, [order?._id]);

  if (isFetching) {
    return <IconLoading />;
  }

  return (
    <>
      {Boolean(calendarEntity) ? (
        <span>{getBaseDate(calendarEntity?.startAt)}</span>
      ) : (
        <>
          <Button
            disabled={!Boolean(order?.client)}
            onClick={() => setShowModal(true)}
            endIcon={IconAdd}
            variant="outline-primary"
          >
            Добавить запись в календарь
          </Button>
          {showModal && (
            <CalendarFormModal
              order={order}
              onClose={() => setShowModal(false)}
              isOpen={showModal}
            />
          )}
        </>
      )}
    </>
  );
};
