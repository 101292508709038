import Section from "@/components/UI/Section";
import Grid from "@/components/UI/Grid";
import Input from "@/components/UI/Input";
import AgentEmail from "@/features/Agent/components/AgentEmail";
import Button from "@/components/UI/Button";
import DatePicker from "@/components/UI/DatePicker";
import InputPhone from "@/components/UI/InputPhone";
import RadioGroup from "@/components/UI/RadioGroup";
import Radio from "@/components/UI/Radio";
import {
  FormFields,
  individualAgentFormInitialValues,
} from "@/features/Agent/components/AgentIndividualForm/constants";
import { useFormikContext } from "formik";
import { IndividualAgent, SexEnum, SocialMediaHandlerEntity } from "@/types";
import { AgentRepresentativeFormProps } from "./types";
import Flex from "@/components/UI/Flex";
import styles from "./styles.module.scss";
import AvatarUpload from "@/features/Agent/components/AvatarUpload";
import IconButton from "@/components/UI/IconButton";
import IconArrowUp from "@/components/Icons/IconArrowUp";
import { useState } from "react";
import { If } from "@/components/ConditionalRendering/If";
import { agentSocialMediaFormSchema } from "@/features/Agent/components/AgentSocialMediaForm/schema";
import { ValidationError } from "yup";
import { getYupInnerErrors } from "@/utils/getYupInnerErrors";
import {
  agentFormSchema,
  representativeFormSchema,
} from "@/features/Agent/components/AgentIndividualForm/schema";
import { agentRepresentativeInitialValues } from "@/features/Agent/components/AgentRepresentativeForm/constants";
import { values } from "lodash";
import { createIndividualAgent } from "@/features/Agent/service";

const AgentRepresentativeForm = ({
  id,
  onRemove,
}: AgentRepresentativeFormProps) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const {
    errors: formikErrors,
    setValues,
    values: formikValues,
    setErrors: setFormikErrors,
  } = useFormikContext<IndividualAgent>();
  const [errors, setErrors] = useState<IndividualAgent>( // @ts-ignore
    () => agentRepresentativeInitialValues,
  );
  const [representativeItem, setRepresentativeItem] = useState<IndividualAgent>(
    // @ts-ignore
    () => agentRepresentativeInitialValues,
  );

  const handleSubmit = async () => {
    try {
      await representativeFormSchema.validate(representativeItem, {
        abortEarly: false,
      });

      const item = await createIndividualAgent(representativeItem);

      setValues({
        ...formikValues,
        representative: (formikValues?.representative || []).concat({
          ...representativeItem,
          _id: item?._id,
        }),
      });

      setFormikErrors({
        ...formikErrors,
        representative: [],
      });

      onRemove(id);
    } catch (err) {
      if (err instanceof ValidationError) {
        // @ts-ignore
        setErrors(getYupInnerErrors(err));
      }
    }
  };

  const handleChange = (field: FormFields, value: unknown) => {
    setRepresentativeItem((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <Section className={styles.container}>
      <Flex
        className={styles.sectionTop}
        alignItems="center"
        justifyContent="space-between"
      >
        <h2 className={styles.title}>Новый представитель</h2>
        <IconButton
          onClick={() => setIsExpanded((prev) => !prev)}
          variant="stroke"
          icon={IconArrowUp}
        />
      </Flex>
      <If condition={isExpanded}>
        <>
          <Grid>
            <Grid.Item col={12}>
              <AvatarUpload
                url={representativeItem.avatar?.link}
                onUpload={(file) => {
                  handleChange(FormFields.Avatar, file._id);
                }}
              />
            </Grid.Item>
            <Grid.Item col={4}>
              <Input
                error={errors[FormFields.FirstName]}
                label="Имя"
                maxLength={60}
                onChange={(e) => {
                  handleChange(FormFields.FirstName, e.currentTarget.value);
                }}
                name={FormFields.FirstName}
                placeholder="Введите имя"
              />
            </Grid.Item>
            <Grid.Item col={4}>
              <Input
                error={errors[FormFields.MiddleName]}
                label="Отчество"
                maxLength={60}
                name={FormFields.MiddleName}
                onChange={(e) => {
                  handleChange(FormFields.MiddleName, e.currentTarget.value);
                }}
                placeholder="Введите отчество"
              />
            </Grid.Item>
            <Grid.Item col={4}>
              <Input
                error={errors[FormFields.LastName]}
                label="Фамилия"
                maxLength={60}
                name={FormFields.LastName}
                onChange={(e) => {
                  handleChange(FormFields.LastName, e.currentTarget.value);
                }}
                placeholder="Введите фамилию"
              />
            </Grid.Item>
            <Grid.Item col={6}>
              <DatePicker
                name={FormFields.BirthDate}
                // @ts-ignore
                error={errors[FormFields.BirthDate]}
                maxDate={new Date()}
                label="Дата рождения"
                placeholder="Выберите дату рождения"
                onChange={(value: Date | null) => {
                  handleChange(
                    FormFields.BirthDate,
                    value ? new Date(value) : null,
                  );
                }}
                date={representativeItem[FormFields.BirthDate]}
              />
            </Grid.Item>
            <Grid.Item col={6}>
              <RadioGroup
                error={errors[FormFields.Sex]}
                name={FormFields.Sex}
                label="Пол"
                onChange={(e) => handleChange(FormFields.Sex, e.target.value)}
                value={representativeItem?.[FormFields.Sex]}
              >
                <Radio label="Мужской" value={SexEnum.Male} />
                <Radio label="Женский" value={SexEnum.Female} />
              </RadioGroup>
            </Grid.Item>
          </Grid>
          <Grid gap="sm">
            <Grid.Item col={3}>
              <InputPhone
                error={errors[FormFields.Phone]}
                label="Телефон"
                name={FormFields.Phone}
                onChange={(e) => {
                  handleChange(FormFields.Phone, e.currentTarget.value);
                }}
                placeholder="Введите телефон"
              />
            </Grid.Item>
            <Grid.Item col={3}>
              <Input
                error={errors[FormFields.Email]}
                label="E-mail"
                name={FormFields.Email}
                onChange={(e) => {
                  handleChange(FormFields.Email, e.currentTarget.value);
                }}
                placeholder="Введите e-mail"
              />
            </Grid.Item>
            <Grid.Item col={3}>
              <Input
                error={errors[FormFields.PostAddress]}
                label="Почтовый адрес"
                name={FormFields.PostAddress}
                onChange={(e) => {
                  handleChange(FormFields.PostAddress, e.currentTarget.value);
                }}
                placeholder="Введите почтовый адрес"
              />
            </Grid.Item>
            <Grid.Item col={3}>
              <Input
                error={errors[FormFields.Website]}
                label="Сайт"
                maxLength={80}
                name={FormFields.Website}
                onChange={(e) => {
                  handleChange(FormFields.Website, e.currentTarget.value);
                }}
                placeholder="https://example.com"
              />
            </Grid.Item>
          </Grid>
          <Flex className={styles.footer}>
            <Button onClick={handleSubmit}>Сохранить</Button>
            <Button onClick={() => onRemove(id)} variant="stroke-danger">
              Удалить
            </Button>
          </Flex>
        </>
      </If>
    </Section>
  );
};

export default AgentRepresentativeForm;
