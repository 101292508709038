import Modal from "@/components/UI/Modal";
import { Form, Formik } from "formik";
import ModalHeader from "@/components/UI/Modal/ModalHeader";
import ModalBody from "@/components/UI/Modal/ModalBody";
import ModalFooter from "@/components/UI/Modal/ModalFooter";
import Button from "@/components/UI/Button";
import Flex from "@/components/UI/Flex";
import { AutoComplete } from "rsuite";
import { useState } from "react";
import {
  CarEntity,
  CarModelEntity,
} from "@/features/Car/components/Cars/types";
import { isEmpty, isString } from "lodash";
import {
  addCar,
  searchCarsByBrand,
  searchCarsByModel,
} from "@/features/Car/services";
import { OrderCarFormModalProps } from "./types";
import styles from "./styles.module.scss";
import { updateOrder } from "@/features/Order/services";
import { useParams } from "react-router";
import { isAxiosError } from "axios";
import { toast } from "react-toastify";
import { carFormSchema } from "@/features/Order/components/OrderForm/schema";
import { triggerEvent } from "@/utils/event";
import { EVENTS } from "@/constants";
import Grid from "@/components/UI/Grid";
import Input from "@/components/UI/Input";

export const OrderCarFormModal = ({
  isOpen,
  onClose,
  order,
}: OrderCarFormModalProps) => {
  const { id } = useParams();
  const [brand, setBrand] = useState<Partial<CarEntity> | null>();
  const [model, setModel] = useState<Partial<CarModelEntity> | null>(null);
  const [carBrands, setCarBrands] = useState<CarEntity[]>([]);
  const [carModels, setCarModels] = useState<CarModelEntity[]>([]);
  const [vin, setVin] = useState<string>("");
  const [licensePlate, setLicensePlate] = useState<string>("");
  const [isFetching, setIsFetching] = useState(false);

  const canSubmitForm = Boolean(brand) && Boolean(model);

  const handleSearchCarsByBrand = async (brand: string) => {
    if (!isString(brand)) return;

    try {
      const data = await searchCarsByBrand(brand);

      setCarBrands(
        data?.map((item: CarEntity) => ({
          value: item,
          label: item?.name,
        })),
      );
    } catch (err) {
      if (isAxiosError(err)) {
        toast.error(err?.response?.data?.error || err?.response?.data);
      }
    }
  };

  const handleSearchCarsByModel = async (model: string) => {
    if (!isString(model)) return;

    try {
      const data = await searchCarsByModel(brand?._id as string);

      setCarModels(
        data?.map((item: CarModelEntity) => ({
          value: item,
          label: item.name,
        })),
      );
    } catch (err) {
      if (isAxiosError(err)) {
        toast.error(err?.response?.data?.error || err?.response?.data);
      }
    }
  };

  const handleSubmit = async () => {
    setIsFetching(true);

    try {
      const car = await addCar({
        brand: brand?.name,
        model: model?.name,
        owner: order?.client?._id,
        vin,
        licensePlate,
      });

      await updateOrder(id as string, {
        car: {
          sourceCar: car?._id,
        },
      });

      triggerEvent(EVENTS.REFRESH_DATA);
      toast.success("Автомобиль успешно добавлен");
      onClose?.();
    } catch (err) {
      // @ts-ignore
      if (isAxiosError(err)) {
        toast.error(err?.response?.data?.error || err?.response?.data?.message);
      }
    } finally {
      setIsFetching(false);
    }
  };

  const handeSetBrand = (value: CarEntity) => {
    setBrand(value);
  };

  const handeSetModel = (value: CarModelEntity) => {
    setModel(value);
  };

  return (
    <Modal className={styles.smallFormModal} isOpen={isOpen}>
      <Formik
        enableReinitialize
        validateOnChange={false}
        initialValues={carFormSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <ModalHeader onClose={onClose}>
              <h2>Новое транспортное средство</h2>
            </ModalHeader>
            <ModalBody>
              <Grid>
                <Grid.Item col={6}>
                  <AutoComplete
                    data={carBrands}
                    onChange={handleSearchCarsByBrand}
                    onSelect={handeSetBrand}
                    value={brand?.name}
                    placeholder="Марка"
                  />
                </Grid.Item>
                <Grid.Item col={6}>
                  <AutoComplete
                    data={carModels}
                    disabled={isEmpty(brand)}
                    onChange={handleSearchCarsByModel}
                    onSelect={handeSetModel}
                    value={model?.name}
                    placeholder="Модель"
                  />
                </Grid.Item>
                <Grid.Item col={6}>
                  <Input
                    name="licensePlate"
                    onChange={(e) => setLicensePlate(e.target.value)}
                    placeholder="Гос. номер"
                    variant="small"
                  />
                </Grid.Item>
                <Grid.Item col={6}>
                  <Input
                    name="vin"
                    onChange={(e) => setVin(e.target.value)}
                    placeholder="Vin-код"
                    variant="small"
                  />
                </Grid.Item>
              </Grid>
            </ModalBody>
            <ModalFooter>
              <Flex alignItems="center">
                <Button
                  isLoading={isFetching}
                  disabled={!canSubmitForm || isFetching}
                  type="submit"
                >
                  Добавить
                </Button>
                <Button
                  disabled={isFetching}
                  onClick={onClose}
                  variant="secondary"
                >
                  Отмена
                </Button>
              </Flex>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
