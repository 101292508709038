import cx from "classnames";
import IconCheck from "@/components/Icons/IconCheck";
import { CheckboxProps } from "./types";
import styles from "./styles.module.scss";

const Checkbox = ({
  boxAlign = "start",
  className = "",
  children,
  value,
  ...props
}: CheckboxProps) => {
  return (
    <label className={cx(styles.checkbox, className)}>
      <input {...props} type="checkbox" className={styles.checkboxHandle} />

      <span
        className={cx(
          styles.checkboxBox,
          styles[`checkboxBox-align-${boxAlign}`]
        )}
      >
        <IconCheck />
      </span>

      {Boolean(value) && <span className={styles.checkboxLabel}>{value}</span>}
    </label>
  );
};

export default Checkbox;
