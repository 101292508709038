import * as Yup from "yup";
import { IndividualAgent } from "@/types";
import { FormFields } from "@/features/Agent/components/AgentForm/constants";
import * as messages from "@/utils/validationMessages";
import * as expect from "@/utils/validationExpect";

// @ts-ignore
export const individualAgentFormSchema: Yup.ObjectSchema<IndividualAgent> =
  Yup.object().shape({
    [FormFields.FirstName]: Yup.string().trim().required(messages.required),
    [FormFields.Phone]: Yup.string()
      .test("phone", messages.validNumber, expect.phoneNumber)
      .required(messages.required),
  });

// @ts-ignore
export const carFormSchema: Yup.ObjectSchema<IndividualAgent> =
  Yup.object().shape({
    [FormFields.Brand]: Yup.string().trim().required(messages.required),
    [FormFields.MiddleName]: Yup.string().trim().required(messages.required),
  });
