import PaymentView from "@/features/Payment/components/PaymentView";
import Meta from "@/components/Meta";

const Payments = () => {
  return (
    <>
      <Meta title="Движение денежных средств" />
      <PaymentView />
    </>
  );
};

export default Payments;
