import { useState } from "react";
import Modal from "@/components/UI/Modal";
import ModalFooter from "@/components/UI/Modal/ModalFooter";
import ModalHeader from "@/components/UI/Modal/ModalHeader";
import Button from "@/components/UI/Button";
import Flex from "@/components/UI/Flex";
import { Form, Formik } from "formik";
import ModalBody from "@/components/UI/Modal/ModalBody";
import Grid from "@/components/UI/Grid";
import Input from "@/components/UI/Input";
import { OrderJobFormProps } from "./types";
import Switch from "@/components/UI/Switch";
import { useOnce } from "@/hooks";
import { createJob, getJobCategories } from "@/features/Job/services";
import { JobCategoryEntity, JobEntity } from "@/features/Job/types";
import { initialValues, FormFields } from "./constants";
import { jobFormSchema } from "./schema";
import Select from "@/components/UI/Select";
import styles from "./styles.module.scss";
import { toast } from "react-toastify";
import { updateOrder } from "@/features/Order/services";
import { isAxiosError } from "axios";
import { useParams } from "react-router";
import { triggerEvent } from "@/utils/event";
import { EVENTS } from "@/constants";
import InputNumber from "@/components/UI/InputNumber";

const OrderJobForm = ({ isOpen, onClose, order }: OrderJobFormProps) => {
  const { id } = useParams();
  const [isFetching, setIsFetching] = useState(false);
  const [categories, setCategories] = useState<JobCategoryEntity[]>([]);

  useOnce(() => {
    getJobCategories().then((data) => {
      setCategories(data?.items);
    });
  });

  const handeSubmit = async (values: JobEntity) => {
    try {
      const job = await createJob(values);
      await updateOrder(id as string, {
        jobs: order?.jobs
          ?.map((job) => ({ job: job?.job?._id }))
          .concat({ job: job?._id }),
      });
      triggerEvent(EVENTS.REFRESH_DATA);
      toast.success("Работа добавлена");
      onClose();
    } catch (err) {
      if (isAxiosError(err)) {
        toast.error(err?.response?.data?.error);
      }
    }
  };

  return (
    <Modal isOpen={isOpen} className={styles.modal}>
      <Formik
        enableReinitialize
        // @ts-ignore
        initialValues={initialValues}
        validationSchema={jobFormSchema}
        onSubmit={handeSubmit}
        validateOnChange={false}
      >
        {({ values, errors, handleChange, setFieldValue }) => (
          <Form>
            <ModalHeader onClose={onClose}>
              <h2>Добавить работу</h2>
            </ModalHeader>
            <ModalBody className={styles.modalBody}>
              <Flex flexDirection="column" rowGap="lg">
                <Grid>
                  <Grid.Item col={6}>
                    <Input
                      error={errors[FormFields.Title]}
                      name={FormFields.Title}
                      label="Наименование работы"
                      onChange={handleChange}
                      placeholder="Введите наименование работы"
                      value={values[FormFields.Title]}
                    />
                  </Grid.Item>
                  <Grid.Item col={6}>
                    <Select
                      // @ts-ignore
                      error={errors[FormFields.Category]}
                      label="Раздел"
                      placeholder="Выберите раздел"
                      options={categories.map(({ _id, title }) => ({
                        label: title,
                        value: _id,
                      }))}
                      onChange={(e) => {
                        setFieldValue(FormFields.Category, e?.value);
                      }}
                    />
                  </Grid.Item>
                </Grid>
                <Grid>
                  <Grid.Item col={4}>
                    <InputNumber
                      error={errors[FormFields.Price]}
                      name={FormFields.Price}
                      label="Цена работы"
                      onChange={handleChange}
                      placeholder="Введите цену работы"
                      value={values[FormFields.Price]}
                    />
                  </Grid.Item>
                  <Grid.Item col={4}>
                    <InputNumber
                      error={errors[FormFields.ManHours]}
                      name={FormFields.ManHours}
                      label="Количество НЧ"
                      onChange={handleChange}
                      placeholder="Введите количество НЧ"
                      value={values[FormFields.ManHours]}
                    />
                  </Grid.Item>
                  <Grid.Item col={4}>
                    <InputNumber
                      error={errors[FormFields.ManHourPrice]}
                      name={FormFields.ManHourPrice}
                      label="Цена НЧ"
                      onChange={handleChange}
                      placeholder="Введите цена НЧ"
                      value={values[FormFields.ManHourPrice]}
                    />
                  </Grid.Item>
                </Grid>
                <Switch
                  checked={values.isFixedPayment}
                  onChange={() =>
                    setFieldValue(
                      FormFields.IsFixedPayment,
                      !values.isFixedPayment,
                    )
                  }
                  value="Фиксированная ЗП за услугу"
                />
                <InputNumber
                  label="Фиксированная ЗП за услугу"
                  placeholder="Введите фиксированную ЗП за услугу"
                />
              </Flex>
            </ModalBody>
            <ModalFooter>
              <Flex>
                <Button
                  disabled={isFetching}
                  isLoading={isFetching}
                  type="submit"
                >
                  Добавить
                </Button>
                <Button
                  disabled={isFetching}
                  onClick={onClose}
                  variant="secondary"
                >
                  Отмена
                </Button>
              </Flex>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default OrderJobForm;
