import Flex from "@/components/UI/Flex";
import Button from "@/components/UI/Button";
import IconAdd from "@/components/Icons/IconAdd";
import SettingsServicesTableList from "../SettingsServicesTableList";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router";
import { PATHS } from "@/constants";

export const SettingsServices = () => {
  const navigate = useNavigate();

  const goToServiceCreatePage = () => {
    navigate(PATHS.SETTINGS_SERVICE);
  };

  return (
    <div className={styles.wrapper}>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        className={styles.wrapperTop}
      >
        <h2 className={styles.title}>Автосервисы организации</h2>
        <Button
          endIcon={IconAdd}
          onClick={goToServiceCreatePage}
          variant="secondary"
        >
          Автосервис
        </Button>
      </Flex>
      <SettingsServicesTableList />
    </div>
  );
};
