import api from "@/api";
import { jobCategoriesSerialize, jobsSerialize } from "./serializer";
import { GetJobsInput, GetJobsService, JobEntity } from "./types";
import { ResponseFromServer, ResponseFromService } from "@/types";

export const createJob = async (body: any) => {
  const response = await api.post(`/job`, body);
  return response.data;
};

export const createJobCategory = async (body: any) => {
  const response = await api.post(`/job-category`, body);
  return response.data;
};

export const getJob = async (id: string) => {
  const response = await api.get(`/job/${id}`);
  return response.data;
};

export const getJobsByCategory = async (categoryId: string) => {
  const response = await api.get(`/job/by-category/${categoryId}`);
  return jobsSerialize(response.data);
};

export const getJobs: GetJobsService = async ({
  category,
  pageNumber,
  pageSize,
}) => {
  const params: GetJobsInput = {
    pageNumber,
    pageSize,
  };

  const ENDPOINT = Boolean(category) ? `/job/by-category/${category}` : "/job";

  const response = await api.get<ResponseFromServer<JobEntity>>(ENDPOINT, {
    params,
  });

  return jobsSerialize(response.data);
};

export const getJobCategories = async () => {
  const response = await api.get(`/job-category`);
  return jobCategoriesSerialize(response.data);
};
