import { OrderCarTableModalProps } from "./types";
import Modal from "@/components/UI/Modal";
import ModalHeader from "@/components/UI/Modal/ModalHeader";
import Flex from "@/components/UI/Flex";
import Button from "@/components/UI/Button";
import ModalFooter from "@/components/UI/Modal/ModalFooter";
import ModalBody from "@/components/UI/Modal/ModalBody";
import Radio from "@/components/UI/Radio";
import styles from "./styles.module.scss";
import { useState } from "react";
import { isEmpty } from "lodash";
import { updateOrder } from "@/features/Order/services";
import { isAxiosError } from "axios";
import { toast } from "react-toastify";
import { triggerEvent } from "@/utils/event";
import { EVENTS } from "@/constants";

export const OrderCarTableModal = ({
  isOpen,
  onClose,
  order,
}: OrderCarTableModalProps) => {
  const [selectedCar, setSelectedCar] = useState<string>();
  const [isFetching, setIsFetching] = useState(false);

  const handleSubmit = async () => {
    setIsFetching(true);

    try {
      await updateOrder(order?._id as string, {
        car: {
          sourceCar: selectedCar,
        },
      });

      triggerEvent(EVENTS.REFRESH_DATA);
      toast.success("Автомобиль успешно добавлен");
      onClose?.();
    } catch (err) {
      if (isAxiosError(err)) {
        toast.error(err?.response?.data?.error || err?.response?.data?.message);
      }
    } finally {
      setIsFetching(false);
    }
  };

  const renderCars = () => {
    return order?.client?.cars.map((car) => (
      <li className={styles.carListItem} key={car._id}>
        <Radio
          onChange={(e) => {
            setSelectedCar(e.target.value);
          }}
          value={car._id}
          label={`${car.brand} ${car.model}`}
        />
      </li>
    ));
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader onClose={onClose}>
        <h2>Список авто клиента</h2>
      </ModalHeader>
      <ModalBody>
        <ul className={styles.carList}>{renderCars()}</ul>
      </ModalBody>
      <ModalFooter>
        <Flex alignItems="center">
          <Button
            disabled={isEmpty(selectedCar) || isFetching}
            isLoading={isFetching}
            onClick={handleSubmit}
          >
            Добавить
          </Button>
          <Button disabled={isFetching} onClick={onClose} variant="secondary">
            Отмена
          </Button>
        </Flex>
      </ModalFooter>
    </Modal>
  );
};
