import cn from "classnames";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import Input from "@/components/UI/Input";
import type { InputNumberProps } from "./types";
import { forwardRef } from "react";
import styles from "./styles.module.scss";

// @ts-ignore
const InputNumber = forwardRef<NumericFormat, InputNumberProps>(
  (
    {
      className,
      error,
      fullWidth,
      label,
      light,
      min,
      max,
      name,
      onChange,
      placeholder,
      value,
      ...props
    },
    ref,
  ) => {
    return (
      <div
        className={cn(
          styles.container,
          fullWidth && styles.containerFullWidth,
          className,
        )}
      >
        {Boolean(label) && label}
        <NumericFormat
          {...props}
          name={name}
          // @ts-ignore
          ref={ref}
          className={cn(
            styles.input,
            error && styles.invalid,
            light && styles.inputLight,
          )}
          isAllowed={({ floatValue }) => {
            const val = Number(floatValue);

            if (max) {
              return val <= Number(max);
            }

            return true;
          }}
          placeholder={placeholder}
          onChange={onChange}
          customInput={Input}
          inputMode="tel"
          value={value}
        />
        {Boolean(error) && <p className={styles.error}>{error}</p>}
      </div>
    );
  },
);

export default InputNumber;
