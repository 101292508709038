import api from "@/api";
import { CreatePaymentService } from "@/features/Payment/types";
import { ENDPOINT } from "./constants";

export const createPayment: CreatePaymentService = async (body) => {
  const response = await api.post(ENDPOINT, body);

  return response.data;
};

export const getPayment = async (id: string) => {
  const response = await api.get(`${ENDPOINT}/${id}`);
  return response.data;
};

export const editPayment = () => {};
