import type { IndividualAgent } from "@/types";

export enum AgentDetailTabs {
  BankAccounts = "bankAccounts",
  Calls = "calls",
  Calendar = "calendar",
  Main = "main",
  Deals = "deals",
  Organizations = "organizations",
  Settlements = "settlements",
  Notes = "notes",
}

export type AgentsFromServer = {
  items: IndividualAgent[];
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
};

export type GetLegalAgentsResponse = {
  items: IndividualAgent[];
  pagination: {
    pageNumber: number;
    pageSize: number;
    totalItems: number;
    totalPages: number;
  };
};

export type GetIndividualAgentsResponse = {
  items: IndividualAgent[];
  pagination: {
    pageNumber: number;
    pageSize: number;
    totalItems: number;
    totalPages: number;
  };
};

export interface GetIndividualAgentsService {
  (): Promise<GetIndividualAgentsResponse>;
}
