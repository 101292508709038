import { NavLink } from "react-router-dom";
import IconArrowRight from "@/components/Icons/IconArrowRight";
import { BreadcrumbProps } from "./types";
import styles from "./styles.module.scss";

const Breadcrumbs = ({ items }: BreadcrumbProps) => {
  const renderBreadcrumbs = () => {
    return items.map(({ label, path }, index) => {
      return (
        <li className={styles.breadcrumbsItem} key={index}>
          {path ? (
            <NavLink className={styles.breadcrumbsLink} to={path}>
              {label}
              <IconArrowRight />
            </NavLink>
          ) : (
            <span className={styles.breadcrumbsText}>{label}</span>
          )}
        </li>
      );
    });
  };

  return (
    <nav aria-label="Breadcrumb" className={styles.breadcrumbs}>
      <ol className={styles.breadcrumbsList}>{renderBreadcrumbs()}</ol>
    </nav>
  );
};

export default Breadcrumbs;
