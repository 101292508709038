import AuthForm from "./components/AuthForm";
import Meta from "@/components/Meta";

const Auth = () => {
  return (
    <>
      <Meta title="Авторизация" />
      <AuthForm />
    </>
  );
};

export default Auth;
