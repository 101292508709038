import { useState } from "react";
import Input from "@/components/UI/Input";
import { Form, Formik } from "formik";
import Grid from "@/components/UI/Grid";
import Select from "@/components/UI/Select";
import { balanceTypes } from "@/features/Balance/constants";
import { balanceFormInitialValues } from "./constants";
import { WalletBalanceTypeEnum } from "@/features/Wallet/types";
import Flex from "@/components/UI/Flex";
import Button from "@/components/UI/Button";
import ModalBody from "@/components/UI/Modal/ModalBody";
import ModalFooter from "@/components/UI/Modal/ModalFooter";
import { useAppSelector } from "@/store";
import { useAppProvider } from "@/providers/AppProvider/hooks";
import { createPaymentTerminal } from "@/features/PaymentTerminal/service";
import { toast } from "react-toastify";
import { FormFields } from "@/features/Agent/components/AgentForm/constants";
import { dispatchEvent } from "@/utils/event";
import { EVENTS } from "@/constants";
import { isAxiosError } from "axios";

interface BalanceFormProps {
  onClose: () => void;
}

const BalanceForm = ({ onClose }: BalanceFormProps) => {
  const { subdivisions } = useAppProvider();
  const [isFetching, setIsFetching] = useState(false);

  const handleSubmit = async (values: any) => {
    if (isFetching) return;

    setIsFetching(true);

    try {
      await createPaymentTerminal(values);
      toast.success("Баланс добавлен");
      dispatchEvent(EVENTS.REFRESH_DATA);
      onClose();
    } catch (err) {
      if (isAxiosError(err)) {
        toast.error(err?.response?.data?.error);
      }
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <Formik initialValues={balanceFormInitialValues} onSubmit={handleSubmit}>
      {({ values, setFieldValue, handleChange, handleSubmit }) => (
        <Form>
          <ModalBody>
            <Grid>
              <Grid.Item col={12}>
                <Select
                  name="subdivision"
                  label="Подразделение"
                  options={subdivisions.map((subdivision) => ({
                    label: subdivision.title,
                    value: subdivision._id,
                  }))}
                  onChange={(e) => {
                    setFieldValue("subdivision", e?.value);
                  }}
                />
              </Grid.Item>
              <Grid.Item col={6}>
                <Select
                  label="Тип баланса"
                  name="balanceType"
                  options={Object.keys(balanceTypes).map((type) => ({
                    label: balanceTypes[type as WalletBalanceTypeEnum],
                    value: type,
                  }))}
                  onChange={(e) => {
                    setFieldValue("balanceType", e?.value);
                  }}
                />
              </Grid.Item>
              <Grid.Item col={6}>
                <Input
                  name="title"
                  label="Название"
                  onChange={handleChange}
                  placeholder="Введите название баланса"
                  value={values.title}
                />
              </Grid.Item>
              <Grid.Item col={12}>
                <Input
                  name="legalEntityName"
                  placeholder="Юр. лицо"
                  onChange={handleChange}
                  value={values.legalEntityName}
                />
              </Grid.Item>
            </Grid>
          </ModalBody>
          <ModalFooter>
            <Flex>
              <Button
                disabled={isFetching}
                isLoading={isFetching}
                onChange={handleChange}
                type="submit"
              >
                Сохранить
              </Button>
              <Button
                disabled={isFetching}
                onClick={onClose}
                variant="secondary"
              >
                Отмена
              </Button>
            </Flex>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
};

export default BalanceForm;
