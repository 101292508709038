import Modal from "@/components/UI/Modal";
import ModalHeader from "@/components/UI/Modal/ModalHeader";
import styles from "./styles.module.scss";
import ModalBody from "@/components/UI/Modal/ModalBody";
import { useOnce } from "@/hooks";
import { useState } from "react";
import IconLoading from "@/components/Icons/IconLoading";
import Table from "@/components/UI/Table";
import { ProductStockEntity } from "@/features/Product/types";
import { isEmpty, map } from "lodash";
import Flex from "@/components/UI/Flex";
import Button from "@/components/UI/Button";
import ModalFooter from "@/components/UI/Modal/ModalFooter";
import { getWarehouseProductStock } from "@/features/Warehouse/services";
import { WarehouseProductStockModalProps } from "./types";
import Checkbox from "@/components/UI/Checkbox";
import { triggerEvent } from "@/utils/event";
import { EVENTS } from "@/constants";
import { toast } from "react-toastify";
import { isAxiosError } from "axios";

const WarehouseProductStockModal = ({
  isOpen,
  onClose,
  onSuccess,
}: WarehouseProductStockModalProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [products, setProducts] = useState<ProductStockEntity[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<
    ProductStockEntity[]
  >([]);

  useOnce(() => {
    getWarehouseProductStock({
      pageSize: 100,
      pageNumber: 1,
    })
      .then((res) => {
        setProducts(res.items);
      })
      .finally(() => {
        setIsLoading(false);
      });
  });

  const handleSubmit = async () => {
    onSuccess(selectedProducts.map((item) => item.product));
    onClose?.();
  };

  const handleToggleProduct = (product: ProductStockEntity) => {
    const hasProduct = selectedProducts.find(
      (productItem) => productItem._id === product._id,
    );

    if (hasProduct) {
      setSelectedProducts((prev) =>
        prev.filter((productItem) => productItem._id !== product._id),
      );
    } else {
      setSelectedProducts((prev) => prev.concat(product));
    }
  };

  return (
    <Modal isOpen={isOpen} className={styles.modal}>
      <ModalHeader onClose={onClose}>
        <h2>Из остатков</h2>
      </ModalHeader>
      <ModalBody>
        {isLoading ? (
          <IconLoading />
        ) : (
          <Table
            header={
              <Table.Row>
                <Table.Cell>Наименование</Table.Cell>
                <Table.Cell>Бренд</Table.Cell>
                <Table.Cell>Артикул</Table.Cell>
                <Table.Cell>Объем/Мин.шаг реализаций</Table.Cell>
                <Table.Cell>Зарезервированно</Table.Cell>
                <Table.Cell>Цена продажи</Table.Cell>
                <Table.Cell>Мин.остаток</Table.Cell>
                <Table.Cell>Склад</Table.Cell>
                <Table.Cell>Адрес cклада</Table.Cell>
                <Table.Cell>Цена закупки</Table.Cell>
              </Table.Row>
            }
            isEmpty={!products.length}
            isFetching={isLoading}
          >
            {map(products, (product) => (
              <Table.Row>
                <Table.Cell>
                  <Flex alignItems="center">
                    <Checkbox onClick={() => handleToggleProduct(product)} />
                    {product?.product?.title}
                  </Flex>
                </Table.Cell>
                <Table.Cell>{product?.product?.brand}</Table.Cell>
                <Table.Cell>{product?.product?.article}</Table.Cell>
                <Table.Cell>{product?.stock}</Table.Cell>
                <Table.Cell>{product?.reserved}</Table.Cell>
                <Table.Cell>{product?.address?.warehouse?.title}</Table.Cell>
                <Table.Cell>{product?.address?.title}</Table.Cell>
              </Table.Row>
            ))}
          </Table>
        )}
      </ModalBody>
      <ModalFooter>
        <Flex alignItems="center">
          <Button
            disabled={
              isEmpty(products) || isEmpty(selectedProducts) || isFetching
            }
            isLoading={isFetching}
            onClick={handleSubmit}
          >
            Добавить
          </Button>
          <Button onClick={onClose} variant="secondary">
            Отмена
          </Button>
        </Flex>
      </ModalFooter>
    </Modal>
  );
};

export default WarehouseProductStockModal;
