import Modal from "@/components/UI/Modal";
import ModalHeader from "@/components/UI/Modal/ModalHeader";
import { OrderCreateIndividualAgentModalProps } from "./types";
import styles from "./styles.module.scss";
import ModalBody from "@/components/UI/Modal/ModalBody";
import Flex from "@/components/UI/Flex";
import Button from "@/components/UI/Button";
import ModalFooter from "@/components/UI/Modal/ModalFooter";
import Grid from "@/components/UI/Grid";
import Input from "@/components/UI/Input";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { IndividualAgent } from "@/types";
import { FormFields } from "@/features/Agent/components/AgentIndividualForm/constants";
import InputPhone from "@/components/UI/InputPhone";
import { individualAgentFormSchema } from "./schema";
import {
  createIndividualAgent,
  updateIndividualAgent,
} from "@/features/Agent/service";
import { toast } from "react-toastify";
import { individualAgentFormInitialValues } from "./constants";
import { updateOrder } from "@/features/Order/services";
import { useParams } from "react-router";
import { triggerEvent } from "@/utils/event";
import { EVENTS } from "@/constants";
import { getAgentFullName } from "@/pages/AgentDetail/utils";

export const OrderCreateIndividualAgentModal = ({
  isOpen,
  onClose,
  ...props
}: OrderCreateIndividualAgentModalProps) => {
  const { id } = useParams();
  const [isFetching, setIsFetching] = useState(false);
  const [agent, setAgent] = useState<IndividualAgent>(
    // @ts-ignore
    individualAgentFormInitialValues,
  );

  const isEdit = Boolean(agent);

  useEffect(() => {
    if (props?.agent) {
      setAgent(props?.agent);
    }
  }, [props?.agent]);

  const handleSubmit = async (data: IndividualAgent) => {
    setIsFetching(true);

    try {
      if (isEdit) {
        const { _id, ...rest } = data;
        await updateIndividualAgent(_id as string, rest as IndividualAgent);
        await updateOrder(id as string, {
          historyComments: [
            `${getAgentFullName(agent)} изменил(а) личные данные`,
          ],
        });
      } else {
        const client = await createIndividualAgent(data);
        await updateOrder(id as string, {
          client: client?.account?._id,
        });
      }

      triggerEvent(EVENTS.REFRESH_DATA);
      toast.success(
        isEdit ? "Клиент успешно изменен" : "Клиент успешно добавлен",
      );
      onClose?.();
    } catch (err) {
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <Modal className={styles.agentFormModal} isOpen={isOpen}>
      <Formik
        // @ts-ignore
        initialValues={agent}
        onSubmit={handleSubmit}
        enableReinitialize
        validateOnChange={false}
        validationSchema={individualAgentFormSchema}
      >
        {({ errors, handleChange, values }) => (
          <Form>
            <ModalHeader onClose={onClose}>
              <h2>
                {isEdit ? "Редактирование контрагента" : "Новый контрагент"}
              </h2>
            </ModalHeader>
            <ModalBody>
              <Grid>
                <Grid.Item col={4}>
                  <Input
                    error={errors[FormFields.LastName]}
                    name={FormFields.LastName}
                    onChange={handleChange}
                    value={values[FormFields.LastName]}
                    placeholder="Фамилия"
                  />
                </Grid.Item>
                <Grid.Item col={4}>
                  <Input
                    error={errors[FormFields.FirstName]}
                    maxLength={60}
                    onChange={handleChange}
                    name={FormFields.FirstName}
                    placeholder="Имя"
                    value={values[FormFields.FirstName]}
                  />
                </Grid.Item>
                <Grid.Item col={4}>
                  <Input
                    error={errors[FormFields.MiddleName]}
                    name={FormFields.MiddleName}
                    onChange={handleChange}
                    value={values[FormFields.MiddleName]}
                    placeholder="Отчество"
                  />
                </Grid.Item>
                <Grid.Item col={12}>
                  <InputPhone
                    error={errors[FormFields.Phone]}
                    name={FormFields.Phone}
                    onChange={handleChange}
                    placeholder="Телефон"
                    value={values[FormFields.Phone]}
                  />
                </Grid.Item>
              </Grid>
            </ModalBody>
            <ModalFooter>
              <Flex alignItems="center">
                <Button
                  isLoading={isFetching}
                  disabled={isFetching}
                  type="submit"
                >
                  Добавить
                </Button>
                <Button
                  disabled={isFetching}
                  onClick={onClose}
                  variant="secondary"
                >
                  Отмена
                </Button>
              </Flex>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
