import { SettingsDirectoryView } from "@/features/Settings/components/SettingsDirectoryView";
import Meta from "@/components/Meta";

const SettingsDirectory = () => {
  return (
    <>
      <Meta title="Конфигуратор" />
      <SettingsDirectoryView />
    </>
  );
};

export default SettingsDirectory;
