import * as Yup from "yup";
import { FormFields } from "./constants";
import * as messages from "@/utils/validationMessages";
import { CarEntity } from "@/features/Car/components/Cars/types";

// @ts-ignore
export const carFormSchema: Yup.ObjectSchema<CarEntity> = Yup.object().shape({
  [FormFields.Vin]: Yup.string().trim(),
  [FormFields.CarType]: Yup.string().trim().required(messages.required),
  [FormFields.LicensePlate]: Yup.string().required(messages.required),
  [FormFields.Brand]: Yup.string().trim().required(messages.required),
  [FormFields.Model]: Yup.string().trim().required(messages.required),
  [FormFields.Owner]: Yup.string().trim(),
  [FormFields.Generation]: Yup.string().trim().required(messages.required),
  [FormFields.Modification]: Yup.string().trim().required(messages.required),
  [FormFields.Year]: Yup.string().trim().required(messages.required),
  [FormFields.STS]: Yup.string().trim(),
  [FormFields.PTS]: Yup.string().trim(),
  [FormFields.OwnerAddress]: Yup.string().trim(),
  [FormFields.OwnerFIO]: Yup.string().trim(),
  [FormFields.Mileage]: Yup.string().trim(),
  [FormFields.Color]: Yup.string().trim(),
  [FormFields.EngineType]: Yup.string().trim(),
  [FormFields.MaintenanceDate]: Yup.string().trim(),
});
