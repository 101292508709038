import { PropsWithChildren } from "react";
import { ModalHeaderProps } from "./types";
import { isFunction } from "lodash";
import { If } from "@/components/ConditionalRendering/If";
import IconButton from "@/components/UI/IconButton";
import IconClose from "@/components/Icons/IconClose";
import Flex from "@/components/UI/Flex";
import styles from "./styles.module.scss";

const ModalHeader = ({
  children,
  onClose,
}: PropsWithChildren<ModalHeaderProps>) => {
  return (
    <div className={styles.modalHeader}>
      <Flex>
        {children}
        <If condition={isFunction(onClose)}>
          <IconButton
            icon={IconClose}
            label="Закрыть модальное окно"
            onClick={onClose!}
            size="sm"
            className={styles.closeBtn}
            variant="secondary"
          />
        </If>
      </Flex>
    </div>
  );
};

export default ModalHeader;
