import Table from "@/components/UI/Table";
import { useAppProvider } from "@/providers/AppProvider/hooks";
import useEventListener from "@/hooks/useEventListener";
import { EVENTS } from "@/constants";

const SettingsServicesTableList = () => {
  const { getSubdivisions, subdivisions } = useAppProvider();

  useEventListener(EVENTS.REFRESH_DATA, getSubdivisions);

  return (
    <Table
      header={
        <Table.Row>
          <Table.Cell>Номер</Table.Cell>
          <Table.Cell>Название автосервиса</Table.Cell>
          <Table.Cell>Подразделения</Table.Cell>
          <Table.Cell>Сайт</Table.Cell>
          <Table.Cell>Адрес</Table.Cell>
          <Table.Cell>Время работы</Table.Cell>
        </Table.Row>
      }
    >
      {subdivisions?.map((subdivision) => (
        <Table.Row key={subdivision._id}>
          <Table.Cell>{subdivision.increment}</Table.Cell>
          <Table.Cell>{subdivision.title}</Table.Cell>
          <Table.Cell></Table.Cell>
          <Table.Cell></Table.Cell>
          <Table.Cell>{subdivision.address}</Table.Cell>
          <Table.Cell></Table.Cell>
        </Table.Row>
      ))}
    </Table>
  );
};

export default SettingsServicesTableList;
