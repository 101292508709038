const IconSearch = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.58329 17.625C5.1511 17.625 1.54163 14.0155 1.54163 9.58332C1.54163 5.15113 5.1511 1.54166 9.58329 1.54166C14.0155 1.54166 17.625 5.15113 17.625 9.58332C17.625 14.0155 14.0155 17.625 9.58329 17.625ZM9.58329 1.79166C5.28178 1.79166 1.79163 5.29089 1.79163 9.58332C1.79163 13.8758 5.28178 17.375 9.58329 17.375C13.8848 17.375 17.375 13.8758 17.375 9.58332C17.375 5.29089 13.8848 1.79166 9.58329 1.79166Z"
        fill="#545260"
        stroke="#545260"
      />
      <path
        d="M18.3333 18.9583C18.175 18.9583 18.0166 18.9 17.8916 18.775L16.225 17.1083C15.9833 16.8667 15.9833 16.4667 16.225 16.225C16.4666 15.9833 16.8666 15.9833 17.1083 16.225L18.775 17.8917C19.0166 18.1333 19.0166 18.5333 18.775 18.775C18.65 18.9 18.4916 18.9583 18.3333 18.9583Z"
        fill="#545260"
      />
    </svg>
  );
};

export default IconSearch;
