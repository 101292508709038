import Table from "@/components/UI/Table";

export const SettingsMarkupForMaterialTableList = () => {
  return (
    <Table
      header={
        <Table.Row>
          <Table.Cell>Тип наценки</Table.Cell>
          <Table.Cell>Артикул запчастей</Table.Cell>
          <Table.Cell>Бренд запчастей</Table.Cell>
          <Table.Cell>Поставщик</Table.Cell>
          <Table.Cell>Вариант наценки</Table.Cell>
          <Table.Cell>Значение наценки</Table.Cell>
          <Table.Cell>Категория</Table.Cell>
          <Table.Cell>Комментарий</Table.Cell>
          <Table.Cell>Цена</Table.Cell>
          <Table.Cell>Статус правила</Table.Cell>
        </Table.Row>
      }
    ></Table>
  );
};
