import Header from "../Header";
import { breadcrumbs } from "./constants";
import SettingsServicesForm from "../SettingsServicesForm";
import styles from "./styles.module.scss";

const SettingsServiceView = () => {
  return (
    <div className={styles.content}>
      <Header breadcrumbs={breadcrumbs} title="Новый автосервис" />
      <SettingsServicesForm />
    </div>
  );
};

export default SettingsServiceView;
