const IconWhatsapp = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12Z"
        fill="#25D366"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.2 7.725C15.075 6.6 13.575 6 12 6C8.7 6 6 8.7 6 12C6 13.05 6.3 14.1 6.825 15L6 18L9.15001 17.175C10.05 17.625 11.025 17.925 12 17.925C15.3 17.925 18 15.225 18 11.925C18 10.35 17.325 8.85 16.2 7.725ZM12 16.95C11.1 16.95 10.2 16.725 9.45 16.275L9.3 16.2L7.425 16.725L7.95 14.925L7.8 14.7C7.275 13.875 7.05 12.975 7.05 12.075C7.05 9.375 9.3 7.125 12 7.125C13.35 7.125 14.55 7.65 15.525 8.55C16.5 9.525 16.95 10.725 16.95 12.075C16.95 14.7 14.775 16.95 12 16.95ZM14.7 13.2C14.55 13.125 13.8 12.75 13.65 12.75C13.5 12.675 13.425 12.675 13.35 12.825C13.275 12.975 12.975 13.275 12.9 13.425C12.825 13.5 12.75 13.5 12.6 13.5C12.45 13.425 12 13.275 11.4 12.75C10.95 12.375 10.65 11.85 10.575 11.7C10.5 11.55 10.575 11.475 10.65 11.4C10.725 11.325 10.8 11.25 10.875 11.175C10.95 11.1 10.95 11.025 11.025 10.95C11.1 10.875 11.025 10.8 11.025 10.725C11.025 10.65 10.725 9.9 10.575 9.6C10.5 9.375 10.35 9.375 10.275 9.375C10.2 9.375 10.125 9.375 9.97499 9.375C9.89999 9.375 9.74999 9.375 9.59999 9.525C9.44999 9.675 9.075 10.05 9.075 10.8C9.075 11.55 9.6 12.225 9.675 12.375C9.75 12.45 10.725 14.025 12.225 14.625C13.5 15.15 13.725 15 14.025 15C14.325 15 14.925 14.625 15 14.325C15.15 13.95 15.15 13.65 15.075 13.65C15 13.275 14.85 13.275 14.7 13.2Z"
        fill="white"
      />
    </svg>
  );
};

export default IconWhatsapp;
