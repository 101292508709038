import { CounterpartRoleEnum } from "@/types";

export const agentsType = [
  {
    label: "Клиент",
    value: CounterpartRoleEnum.Client,
  },
  {
    label: "Офис",
    value: CounterpartRoleEnum.Office,
  },
  {
    label: "Предприятие",
    value: CounterpartRoleEnum.Enterprise,
  },
  {
    label: "Сотрудник",
    value: CounterpartRoleEnum.Employee,
  },
  {
    label: "Холдинг",
    value: CounterpartRoleEnum.Holding,
  },
  {
    label: "Поставщик",
    value: CounterpartRoleEnum.Supplier,
  },
  {
    label: "Бывший сотрудник",
    value: CounterpartRoleEnum.FormerEmployee,
  },
  {
    label: "Бывший поставщик",
    value: CounterpartRoleEnum.FormerSupplier,
  },
  {
    label: "Представитель",
    value: CounterpartRoleEnum.Representative,
  },
  {
    label: "Перевозчик",
    value: CounterpartRoleEnum.Carrier,
  },
  {
    label: "Retail",
    value: CounterpartRoleEnum.Representative,
  },
];

export const createAgentFormInitialValues = {
  email: "",
  role: "",
  firstName: "",
  lastName: "",
  birthDate: null,
  sex: "",
  phone: "",
  postCode: "123232",
  subscribeForNews: false,
  socialMedia: [],
  discount: "",
  isEmployee: false,
};

export enum FormFields {
  Address = "address",
  Avatar = "avatar",
  BirthDate = "birthDate",
  City = "city",
  Discount = "discount",
  CompanyLogo = "companyLogo",
  CompanyName = "companyName",
  FirstName = "firstName",
  MiddleName = "middleName",
  IsEmployee = "isEmployee",
  OwnerTitle = "ownerTitle",
  SubdivisionAddress = "subdivisionAddress",
  SubdivisionTitle = "subdivisionTitle",
  LastName = "lastName",
  Logo = "logo",
  Email = "email",
  Password = "password",
  Phone = "phone",
  Role = "role",
  Sex = "sex",
  SocialMedia = "socialMedia",
  SubscribeForNews = "subscribeForNews",
  Website = "website",
}

export const ownerFormInitialValues = {
  ownerTitle: "",
};
