import Input from "@/components/UI/Input";
import IconSearch from "@/components/Icons/IconSearch";
import styles from "./styles.module.scss";

const SearchForm = () => {
  return (
    <div className={styles.search}>
      <IconSearch />
      <Input placeholder="Поиск" variant="small" />
    </div>
  );
};

export default SearchForm;
