import { WarehouseView } from "@/features/Warehouse";
import Meta from "@/components/Meta";

const Warehouse = () => {
  return (
    <>
      <Meta title="Склад" />
      <WarehouseView />
    </>
  );
};

export default Warehouse;
