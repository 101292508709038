import { ResetPasswordForm } from "@/features/Auth";
import Meta from "@/components/Meta";

const ResetPassword = () => {
  return (
    <>
      <Meta title="Восстановить пароль" />
      <ResetPasswordForm />
    </>
  );
};

export default ResetPassword;
