import * as Yup from "yup";
import * as messages from "@/utils/validationMessages";
import { FormFields } from "./constants";
import { JobEntity } from "@/features/Job/types";

// @ts-ignore
export const jobFormSchema: Yup.ObjectSchema<JobEntity> = Yup.object().shape({
  [FormFields.Title]: Yup.string().trim().required(messages.required),
  [FormFields.Price]: Yup.string().trim().required(messages.required),
  [FormFields.Category]: Yup.string().trim().required(messages.required),
  [FormFields.ManHours]: Yup.string().trim().required(messages.required),
  [FormFields.ManHourPrice]: Yup.string().trim().required(messages.required),
  [FormFields.Title]: Yup.string().trim().required(messages.required),
});
