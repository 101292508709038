import React, { useState } from "react";
import Flex from "@/components/UI/Flex";
import { map } from "lodash";
import { DirectoryTabs, tabs } from "./constants";
import Tabs from "@/components/UI/Tabs";
import Header from "@/components/Header";
import { BrandTableList } from "@/features/Brand/components/BrandTableList";
import { CountryTableList } from "@/features/Country/components/CountryTableList";
import styles from "./styles.module.scss";

export const SettingsDirectoryView = () => {
  const [tab, setTab] = useState<DirectoryTabs>(DirectoryTabs.Brands);

  return (
    <div className={styles.wrapper}>
      <Header title="Конфигуратор" showSearch={false} />
      <Flex
        alignItems="center"
        justifyContent="space-between"
        className={styles.wrapperInner}
      >
        <Tabs className={styles.tabs} value={tab} onSelect={setTab}>
          {map(tabs, ({ label, value }) => (
            <Tabs.Item key={value} value={value}>
              {label}
            </Tabs.Item>
          ))}
        </Tabs>
      </Flex>

      <div className={styles.wrapperInner}>
        {tab === DirectoryTabs.Brands && <BrandTableList />}
        {tab === DirectoryTabs.Countries && <CountryTableList />}
      </div>
    </div>
  );
};
