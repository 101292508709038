import Header from "@/components/Header";

const Stock = () => {
  return (
    <div>
      <Header />
    </div>
  );
};

export default Stock;
