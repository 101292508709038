import Table from "@/components/UI/Table";

export const SettingsMarkupForWorkTableList = () => {
  return (
    <Table
      header={
        <Table.Row>
          <Table.Cell>Объект применения работ </Table.Cell>
          <Table.Cell>Действие в работе</Table.Cell>
          <Table.Cell>Цена</Table.Cell>
          <Table.Cell>Название работы для клиента</Table.Cell>
        </Table.Row>
      }
    ></Table>
  );
};
