import Flex from "@/components/UI/Flex";
import SettingsServicesScheduleForm from "../SettingsServicesScheduleForm";
import { sheduler, ShedulerType } from "../SettingsServicesForm/constants";
import styles from "../SettingsServicesForm/styles.module.scss";

const SettingsServicesSchedule = () => {
  return (
    <Flex flexDirection="column" rowGap="sm">
      <h2 className={styles.title}>Часы работы автосервиса</h2>
      <Flex flexDirection="column" rowGap="sm" className={styles.scheduler}>
        {sheduler.map((schedule, index) => (
          <SettingsServicesScheduleForm
            key={index}
            index={index}
            scheduler={schedule}
          />
        ))}
      </Flex>
    </Flex>
  );
};

export default SettingsServicesSchedule;
