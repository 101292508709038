import Header from "@/components/Header";
import CallsListTable from "./components/CallsListTable";
import Meta from "@/components/Meta";

const Calls = () => {
  return (
    <div>
      <Meta title="Звонки" />
      <Header title="Звонки" showSearch={false} />
      <CallsListTable />
    </div>
  );
};

export default Calls;
