import * as Yup from "yup";
import * as messages from "@/utils/validationMessages";
import { FormFields } from "./constants";
import { JobEntity } from "@/features/Job/types";
import { ProductEntity } from "@/features/Product/types";

// @ts-ignore
export const productFormSchema: Yup.ObjectSchema<ProductEntity> =
  Yup.object().shape({
    [FormFields.Article]: Yup.string().trim().required(messages.required),
    [FormFields.Title]: Yup.string().trim().required(messages.required),
    [FormFields.Brand]: Yup.string().trim().required(messages.required),
    [FormFields.ManufacturerCountry]: Yup.string()
      .trim()
      .required(messages.required),
    [FormFields.Title]: Yup.string().trim().required(messages.required),
  });
