import cn from "classnames";
import type { PropsWithChildren } from "react";
import type { SectionProps } from "./types";
import styles from "./styles.module.scss";

const Section = ({
  children,
  className,
  padding = "lg",
}: PropsWithChildren<SectionProps>) => {
  return (
    <section
      className={cn(styles.section, styles[`padding-${padding}`], className)}
    >
      {children}
    </section>
  );
};

export default Section;
