import ModalHeader from "@/components/UI/Modal/ModalHeader";
import ModalBody from "@/components/UI/Modal/ModalBody";
import ModalFooter from "@/components/UI/Modal/ModalFooter";
import Flex from "@/components/UI/Flex";
import Button from "@/components/UI/Button";
import Modal from "@/components/UI/Modal";
import type { OrderPaymentFormModalProps } from "./types";
import { Form, Formik } from "formik";
import Grid from "@/components/UI/Grid";
import { useOnce } from "@/hooks";
import { getPaymentTerminals } from "@/features/PaymentTerminal/service";
import { useState } from "react";
import { PaymentTerminalEntity } from "@/features/PaymentTerminal/types";
import { map } from "lodash";
import {
  FormFields,
  OrderPaymentFormMethod,
  orderPaymentFormMethods,
  orderPaymentInitialValues,
} from "@/features/Order/components/OrderPaymentFormModal/constants";
import Tabs from "@/components/UI/Tabs";
import Select from "@/components/UI/Select";
import IconArrowRightLong from "@/components/Icons/IconArrowRightLong";
import { getBaseDate } from "@/utils/date";
import { PaymentMethod, paymentMethods } from "@/features/Payment/constants";
import styles from "./styles.module.scss";
import { orderPaymentFormSchema } from "@/features/Order/components/OrderPaymentFormModal/schema";
import InputNumber from "@/components/UI/InputNumber";
import { Payment } from "@/features/Payment/types";
import { createPayment } from "@/features/Payment/service";
import { toast } from "react-toastify";
import { isAxiosError } from "axios";

const OrderPaymentFormModal = ({
  open,
  onClose,
  order,
}: OrderPaymentFormModalProps) => {
  const [isFetching, setIsFetching] = useState(false);
  const [orderPaymentFormMethod, setOrderPaymentFormMethod] =
    useState<OrderPaymentFormMethod>(OrderPaymentFormMethod.FullPayment);
  const [paymentTerminal, setPaymentTerminal] = useState("");
  const [paymentTerminals, setPaymentTerminals] =
    useState<PaymentTerminalEntity[]>();

  const handleSubmit = async (data: Payment) => {
    setIsFetching(true);

    try {
      await createPayment({
        ...data,
        // @ts-ignore
        from: order?.client?.wallet,
        // @ts-ignore
        to: paymentTerminal,
      });

      toast.success("Платеж успешно совершен");
      onClose?.();
    } catch (err) {
      if (isAxiosError(err)) {
        toast.error(err?.response?.data?.error || err?.response?.data?.message);
      }
    } finally {
      setIsFetching(true);
    }
  };

  useOnce(() => {
    getPaymentTerminals({
      pageNumber: 1,
      pageSize: 100,
    }).then((res) => {
      setPaymentTerminals(res?.items);
    });
  });

  return (
    <Modal className={styles.modal} isOpen={open}>
      <Formik
        enableReinitialize
        validateOnChange={false}
        validationSchema={orderPaymentFormSchema}
        // @ts-ignore
        initialValues={orderPaymentInitialValues}
        onSubmit={handleSubmit}
      >
        {({ values, errors, handleChange, handleSubmit }) => (
          <Form className={styles.form}>
            <ModalHeader onClose={onClose}>
              <h2>Платеж</h2>
            </ModalHeader>
            <ModalBody className={styles.jobs}>
              <Grid>
                <Grid.Item col={7}>
                  <Tabs
                    value={orderPaymentFormMethod}
                    onSelect={setOrderPaymentFormMethod}
                  >
                    {map(
                      Object.entries(orderPaymentFormMethods),
                      ([key, value]) => (
                        <Tabs.Item key={key} value={key}>
                          {value}
                        </Tabs.Item>
                      ),
                    )}
                  </Tabs>
                  {orderPaymentFormMethod ===
                    OrderPaymentFormMethod.FullPayment && (
                    <Grid className={styles.formWrapper}>
                      <Grid.Item col={12}>
                        <Flex rowGap="xxs" flexDirection="column">
                          <span>К оплате</span>
                          <span className={styles.price}>
                            {order.totalPriceWithDiscount || 0} ₽
                          </span>
                        </Flex>
                      </Grid.Item>
                      <Grid.Item col={12}>
                        <Select
                          onChange={(e) => {
                            setPaymentTerminal(e!.value);
                          }}
                          options={paymentTerminals?.map((paymentTerminal) => ({
                            label: paymentTerminal.title,
                            value: paymentTerminal.wallet?._id,
                          }))}
                          label="Касса"
                        />
                      </Grid.Item>

                      <Grid.Item col={12}>
                        <InputNumber
                          error={errors[FormFields.Amount]}
                          name={FormFields.Amount}
                          onChange={handleChange}
                          value={values[FormFields.Amount]}
                          label="Принято наличными"
                          placeholder="0 ₽"
                        />
                      </Grid.Item>
                    </Grid>
                  )}
                </Grid.Item>
                <Grid.Item col={5}>
                  <span>Оплаты:</span>
                  {map(order?.payments, (payment) => (
                    <Flex
                      className={styles.payment}
                      flexDirection="column"
                      rowGap="xxs"
                    >
                      <Flex alignItems="center" justifyContent="space-between">
                        <span className={styles.paymentMethod}>
                          {
                            paymentMethods[
                              payment?.paymentMethod as PaymentMethod
                            ]
                          }
                        </span>
                        <span className={styles.paymentPrice}>
                          {payment?.amount} ₽
                        </span>
                      </Flex>
                      <Flex
                        className={styles.paymentUsers}
                        alignItems="center"
                        columnGap="xxs"
                      >
                        <span>{payment?.from?.owner?.title}</span>
                        <IconArrowRightLong />
                        <span>{payment?.to?.owner?.title}</span>
                      </Flex>
                      <Flex alignItems="center" justifyContent="space-between">
                        <span className={styles.paymentDate}>
                          {getBaseDate(payment?.accountingDate)}
                        </span>
                      </Flex>
                    </Flex>
                  ))}
                </Grid.Item>
              </Grid>
            </ModalBody>
            <ModalFooter>
              <Flex>
                <Button
                  disabled={isFetching}
                  isLoading={isFetching}
                  type="submit"
                >
                  Принять оплату
                </Button>
                <Button
                  disabled={isFetching}
                  onClick={onClose}
                  variant="secondary"
                >
                  Отмена
                </Button>
              </Flex>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default OrderPaymentFormModal;
