const IconCheck = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2052 14.3134L10.5776 14.7291L10.95 14.3134L16.09 8.57641C16.1812 8.47453 16.3139 8.47453 16.4052 8.57641C16.525 8.71019 16.525 8.95845 16.4052 9.09223L10.7352 15.4208C10.6825 15.4796 10.6245 15.5 10.5776 15.5C10.5306 15.5 10.4726 15.4796 10.42 15.4208L7.58996 12.2621C7.47009 12.1283 7.47009 11.8801 7.58996 11.7463C7.68124 11.6444 7.81389 11.6444 7.90516 11.7463L10.2052 14.3134Z"
        stroke="white"
      />
    </svg>
  );
};

export default IconCheck;
