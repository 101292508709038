import { useState } from "react";
import { v4 as uuid } from "uuid";
import Button from "@/components/UI/Button";
import IconAdd from "@/components/Icons/IconAdd";
import Flex from "@/components/UI/Flex";
import { AgentSocialMediaProps } from "./types";
import { AgentSocialMediaForm } from "@/features/Agent";
import { useFormikContext } from "formik";
import { IndividualAgent, IterableEntity } from "@/types";
import { If } from "@/components/ConditionalRendering/If";
import AgentSocialMediaList from "@/features/Agent/components/AgentSocialMediaList";
import ErrorMessage from "@/components/ErrorMessage";
import styles from "./styles.module.scss";
import { isEmpty } from "lodash";

const AgentSocialMedia = () => {
  const [socialMediaItems, setSocialMediaItems] = useState<IterableEntity[]>(
    [],
  );
  const { errors, values, setErrors } = useFormikContext<IndividualAgent>();

  const handlePushItem = () => {
    setErrors({
      ...errors,
      socialMedia: [],
    });

    setSocialMediaItems((prev) =>
      prev.concat({
        _id: uuid(),
      }),
    );
  };

  const handleRemoveItem = (_id: string) => {
    setSocialMediaItems((prev) => prev.filter((item) => item._id !== _id));
  };

  return (
    <div>
      <Flex alignItems="center" justifyContent="space-between">
        <h2 className={styles.title}>Добавить социальную сеть</h2>
        <Button
          endIcon={IconAdd}
          onClick={handlePushItem}
          variant="empty-primary"
        >
          Соц. сеть
        </Button>
      </Flex>

      <If condition={Boolean(errors?.socialMedia?.length)}>
        <ErrorMessage message="Вы не добавили ни одной социальной сети" />
      </If>

      <If condition={Boolean(socialMediaItems.length)}>
        <div className={styles.socialMediaItems}>
          {socialMediaItems.map((item, index) => (
            <AgentSocialMediaForm
              key={item._id}
              _id={item._id}
              index={values.socialMedia.length}
              onRemove={handleRemoveItem}
            />
          ))}
        </div>
      </If>
      <If condition={!isEmpty(values.socialMedia)}>
        <div className={styles.socialMediaItems}>
          <AgentSocialMediaList />
        </div>
      </If>
    </div>
  );
};

export default AgentSocialMedia;
