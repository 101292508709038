import Flex from "@/components/UI/Flex";
import IconButton from "@/components/UI/IconButton";
import IconArrowUp from "@/components/Icons/IconArrowUp";
import { If } from "@/components/ConditionalRendering/If";
import Grid from "@/components/UI/Grid";
import Section from "@/components/UI/Section";
import { CarDetailProps } from "./types";
import { useState } from "react";
import styles from "./styles.module.scss";

export const CarDetail = ({ car }: CarDetailProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Section>
      <Flex rowGap="xs" justifyContent="space-between">
        <Flex alignItems="center" columnGap="xs">
          <Flex columnGap="xs">
            <b>{car?.brand}</b>
            <b>{car?.model}</b>
            <span>{car?.licensePlate}</span>
          </Flex>
        </Flex>
        <IconButton
          icon={IconArrowUp}
          onClick={() => setIsExpanded((prev) => !prev)}
          variant="stroke"
        />
      </Flex>
      <If condition={isExpanded}>
        <Grid className={styles.row}>
          <Grid.Item col={3}>
            <Flex rowGap="xs" flexDirection="column">
              <b>Тип транспортного средства</b>
              <span>Легковой</span>
            </Flex>
          </Grid.Item>
          <Grid.Item col={3}>
            <Flex rowGap="xs" flexDirection="column">
              <b>Гос. номер</b>
              <span>Легковой</span>
            </Flex>
          </Grid.Item>
          <Grid.Item col={3}>
            <Flex rowGap="xs" flexDirection="column">
              <b>Бренд</b>
              <span>{car?.brand}</span>
            </Flex>
          </Grid.Item>
          <Grid.Item col={3}>
            <Flex rowGap="xs" flexDirection="column">
              <b>Модель</b>
              <span>{car?.model}</span>
            </Flex>
          </Grid.Item>
          <Grid.Item col={3}>
            <Flex rowGap="xs" flexDirection="column">
              <b>Кузов</b>
              <span>{car?.bodyType}</span>
            </Flex>
          </Grid.Item>
          <Grid.Item col={3}>
            <Flex rowGap="xs" flexDirection="column">
              <b>Модификация</b>
              <span>{car?.modification}</span>
            </Flex>
          </Grid.Item>
          <Grid.Item col={3}>
            <Flex rowGap="xs" flexDirection="column">
              <b>Поколение </b>
              <span>{car?.generation}</span>
            </Flex>
          </Grid.Item>
          <Grid.Item col={3}>
            <Flex rowGap="xs" flexDirection="column">
              <b>Год производства</b>
              <span>{car?.year}</span>
            </Flex>
          </Grid.Item>
        </Grid>
      </If>
    </Section>
  );
};
