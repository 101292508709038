import IconLoading from "@/components/Icons/IconLoading";
import Container from "@/components/Container";
import styles from "./styles.module.scss";

const LoadingIndicatorPage = () => {
  return (
    <Container className={styles.container} center>
      <IconLoading width={24} height={24} />
    </Container>
  );
};

export default LoadingIndicatorPage;
