import type { PropsWithChildren } from "react";
import cn from "classnames";
import { GroupProps, GroupGap } from "./types";
import styles from "./styles.module.scss";

const Group = ({
  children,
  gap = GroupGap.SM,
}: PropsWithChildren<GroupProps>) => {
  return <div className={cn(styles.group, styles[gap])}>{children}</div>;
};

export default Group;
