import IconLoading from "@/components/Icons/IconLoading";
import Flex from "@/components/UI/Flex";
import { getAgentFullName } from "@/pages/AgentDetail/utils";
import { Agent, IndividualAgent, UserType } from "@/types";
import IconButton from "@/components/UI/IconButton";
import IconEdit from "@/components/Icons/IconEdit";
import { AutoComplete } from "rsuite";
import Grid from "@/components/UI/Grid";
import { useState } from "react";
import { OrderIndividualAgentFormProps } from "./types";
import Button from "@/components/UI/Button";
import IconAdd from "@/components/Icons/IconAdd";
import styles from "@/features/Order/components/OrderForm/styles.module.scss";
import { OrderCreateIndividualAgentModal } from "./OrderCreateIndividualAgentModal";
import { If } from "@/components/ConditionalRendering/If";
import { fetchSearchAgents } from "@/features/Agent/service";
import { updateOrder } from "@/features/Order/services";
import { triggerEvent } from "@/utils/event";
import { EVENTS } from "@/constants";
import { toast } from "react-toastify";

export const OrderIndividualAgentForm = ({
  client,
  order,
}: OrderIndividualAgentFormProps) => {
  const [agents, setAgents] = useState<Agent[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [showAgentFormModal, setShowAgentFormModal] = useState(false);

  const handleSetAgent = async (client: string) => {
    setIsFetching(true);

    try {
      await updateOrder(order?._id as string, {
        client,
      });
      triggerEvent(EVENTS.REFRESH_DATA);
      toast.success("Клиент добавлен");
    } catch (err) {
    } finally {
      setIsFetching(false);
    }
  };

  const handleSearchAgents = async (query: string) => {
    try {
      const data = await fetchSearchAgents(query);

      setAgents(
        data?.map((item: UserType) => ({
          value: item._id,
          label: getAgentFullName(item.counterpartProfile),
        })),
      );
    } catch (err) {}
  };

  return (
    <>
      <Grid.Item className={styles.bottomRow} col={3}>
        {isFetching ? (
          <IconLoading />
        ) : Boolean(client) ? (
          <Flex
            flexDirection="column"
            rowGap="zero"
            justifyContent="center"
            className={styles.textRow}
          >
            <Flex alignItems="center" className="text-primary" columnGap="zero">
              {getAgentFullName(
                order?.client?.counterpartProfile as IndividualAgent,
              )}
              <IconButton
                icon={IconEdit}
                onClick={() => setShowAgentFormModal(true)}
              />
            </Flex>
            <span>{order?.client?.counterpartProfile?.phone}</span>
          </Flex>
        ) : (
          <Flex columnGap="xxs">
            <Flex
              flexDirection="column"
              rowGap="xxs"
              className={styles.textRow}
            >
              <AutoComplete
                data={agents}
                onSelect={handleSetAgent}
                onChange={handleSearchAgents}
                placeholder="ФИО клиента"
              />
              <Button
                className={styles.smallBtn}
                endIcon={() => <IconAdd size={16} />}
                onClick={() => setShowAgentFormModal(true)}
                variant="empty-primary"
              >
                Новый контрагент
              </Button>
            </Flex>
          </Flex>
        )}
      </Grid.Item>

      <If condition={showAgentFormModal}>
        <OrderCreateIndividualAgentModal
          agent={order?.client?.counterpartProfile!}
          isOpen={true}
          onClose={() => setShowAgentFormModal(false)}
        />
      </If>
    </>
  );
};
