import Header from "@/components/Header";
import JobCategories from "../JobCategories";
import JobsListTable from "../JobTableList";
import styles from "./styles.module.scss";
import Button from "@/components/UI/Button";
import IconAdd from "@/components/Icons/IconAdd";
import JobCategoryForm from "@/features/Job/components/JobCategoryForm";
import { useState } from "react";
import JobForm from "@/features/Job/components/JobForm";
import { If } from "@/components/ConditionalRendering/If";

const JobView = () => {
  const [showJobForm, setShowJobForm] = useState(false);
  const [showJobCategoryForm, setShowJobCategoryForm] = useState(false);

  return (
    <div className={styles.container}>
      <Header
        actions={
          <>
            <Button
              endIcon={IconAdd}
              onClick={() => setShowJobCategoryForm(true)}
            >
              Раздел
            </Button>
            <Button
              endIcon={IconAdd}
              variant="secondary"
              onClick={() => setShowJobForm(true)}
            >
              Работа
            </Button>
          </>
        }
        showSearch={false}
        title="Каталог работ"
      />
      <div className={styles.content}>
        <div className={styles.contentCategories}>
          <JobCategories height="95%" />
        </div>
        <JobsListTable />
      </div>
      <JobCategoryForm
        isOpen={showJobCategoryForm}
        onClose={() => setShowJobCategoryForm(false)}
      />
      <If condition={showJobForm}>
        <JobForm isOpen={true} onClose={() => setShowJobForm(false)} />
      </If>
    </div>
  );
};

export default JobView;
