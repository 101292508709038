import Routes from "src/components/Routes";
import { useCheckAuth } from "@/hooks";
import { ToastContainer } from "react-toastify";
import { Providers } from "@/components/Providers";

const App = () => {
  useCheckAuth();

  return (
    <Providers>
      <Routes />
      <ToastContainer />
    </Providers>
  );
};

export default App;
