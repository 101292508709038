import * as Yup from "yup";
import {
  WarehouseAddressEntity,
  WarehouseEntity,
} from "@/features/Warehouse/types";
import { FormFields } from "./constants";
import * as messages from "@/utils/validationMessages";
import { agentSocialMediaFormSchema } from "@/features/Agent/components/AgentSocialMediaForm/schema";

// @ts-ignore
export const warehouseAddressFormSchema: Yup.ObjectSchema<WarehouseAddressEntity> =
  Yup.object().shape({
    [FormFields.CellsPerShelf]: Yup.number().required(messages.required),
    [FormFields.Floors]: Yup.number().required(messages.required),
    [FormFields.RowsPerFloor]: Yup.number().required(messages.required),
    [FormFields.RacksPerRow]: Yup.number().required(messages.required),
    [FormFields.ShelvesPerRack]: Yup.number().required(messages.required),
    [FormFields.Type]: Yup.string().required(messages.required),
    [FormFields.Title]: Yup.string().required(messages.required),
  });

// @ts-ignore
export const warehouseFormSchema: Yup.ObjectSchema<WarehouseEntity> =
  Yup.object().shape({
    [FormFields.Address]: Yup.string().required(messages.required),
    [FormFields.Title]: Yup.string().required(messages.required),
    [FormFields.Section]: Yup.string().required(messages.required),
    [FormFields.WarehouseAddresses]: Yup.array().of(warehouseAddressFormSchema),
  });
