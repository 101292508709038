import WarehouseTransferView from "@/features/Warehouse/components/WarehouseTransferView";
import Meta from "@/components/Meta";

const WarehouseTransfer = () => {
  return (
    <>
      <Meta title="Склад - Перемещения" />
      <WarehouseTransferView />
    </>
  );
};

export default WarehouseTransfer;
