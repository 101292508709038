import PageTitle from "@/components/PageTitle";
import SearchForm from "@/components/SearchForm";
import type { HeaderProps } from "./types";
import { isValidElement, PropsWithChildren } from "react";
import { If } from "@/components/ConditionalRendering/If";
import styles from "./styles.module.scss";

const Header = ({
  actions,
  children,
  filters,
  showSearch = true,
  title,
}: PropsWithChildren<HeaderProps>) => {
  return (
    <header className={styles.header}>
      <div className="px-4 md:px-6 border-b border-bright-secondary py-3 flex md:grid items-center h-[64px] gap-3 md:grid-cols-[minmax(200px,_auto)_1fr_auto]">
        <PageTitle title={title} />
        {children}
        {showSearch && <SearchForm />}
        <div className={styles.headerRight}>
          <If condition={isValidElement(actions)}>
            <div className={styles.actions}>{actions}</div>
          </If>
          <If condition={isValidElement(filters)}>
            <div className={styles.filters}>{filters}</div>
          </If>
        </div>
      </div>
    </header>
  );
};

export default Header;
