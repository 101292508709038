import { Navigate } from "react-router-dom";
import AppLayout from "@/layouts/AppLayout";
import { PATHS } from "@/constants";
import { useAppSelector } from "@/store";
import { selectIsAuthenticated } from "@/store/slices/userSlice";
import type { PrivateRouteProps } from "./types";
import type { PropsWithChildren } from "react";

const PrivateRoute = ({ children }: PropsWithChildren<PrivateRouteProps>) => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  if (isAuthenticated) {
    return <AppLayout>{children}</AppLayout>;
  }

  return <Navigate to={PATHS.AUTH} />;
};

export default PrivateRoute;
