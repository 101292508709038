const IconArrowDown = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.64658 11.3535L10.0001 11.7071L10.3537 11.3535L12.8537 8.85353C12.8752 8.832 12.9059 8.81873 12.9418 8.81873C12.9776 8.81873 13.0084 8.832 13.0299 8.85353L13.3736 8.50989L13.0299 8.85353C13.0514 8.87506 13.0647 8.90579 13.0647 8.94164C13.0647 8.97749 13.0514 9.00823 13.0299 9.02976L10.0882 11.9714C10.0736 11.9861 10.0595 11.9945 10.0467 11.9997C10.0333 12.0051 10.0178 12.0083 10.0001 12.0083C9.98245 12.0083 9.96693 12.0051 9.9536 11.9997C9.94075 11.9945 9.9267 11.9861 9.91202 11.9714L6.97035 9.02976C6.94882 9.00823 6.93555 8.97749 6.93555 8.94164C6.93555 8.90579 6.94882 8.87506 6.97035 8.85353C6.99188 8.832 7.02261 8.81873 7.05846 8.81873C7.09431 8.81873 7.12505 8.832 7.14658 8.85353L9.64658 11.3535Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  );
};

export default IconArrowDown;
