import { UnauthorizedLayoutProps } from "./types";
import type { PropsWithChildren } from "react";
import IconLogo from "@/components/Icons/IconLogo";
import { NavLink } from "react-router-dom";

const UnauthorizedLayout = ({
  children,
}: PropsWithChildren<UnauthorizedLayoutProps>) => {
  return (
    <div className="grid grid-rows-[48px_1fr] md:grid-rows-[64px_1fr] gap-6 lg:gap-[48px]">
      <header className="flex items-center lg:px-6 px-4 border-b border-bright-secondary">
        <NavLink to="/">
          <IconLogo />
        </NavLink>
      </header>
      <main className="h-full px-4 lg:flex lg:items-center lg:justify-center">
        {children}
      </main>
    </div>
  );
};

export default UnauthorizedLayout;
