import Table from "@/components/UI/Table";
import Button from "@/components/UI/Button";
import IconAdd from "@/components/Icons/IconAdd";
import styles from "./styles.module.scss";

const AgentsOrganizationsListTable = () => {
  return (
    <Table
      className={styles.table}
      header={
        <Table.Row>
          <Table.Cell>Юр.лицо</Table.Cell>
          <Table.Cell>ИНН</Table.Cell>
          <Table.Cell>Адрес</Table.Cell>
          <Table.Cell>Тип налога</Table.Cell>
          <Table.Cell>Ген.директор</Table.Cell>
          <Table.Cell>Количество сделок</Table.Cell>
          <Table.Cell>Сумма по сделкам</Table.Cell>
          <Table.Cell>Количество авто</Table.Cell>
        </Table.Row>
      }
      footer={
        <div className={styles.tableFooter}>
          <Button endIcon={IconAdd} variant="secondary">
            Организация
          </Button>
        </div>
      }
    >
      {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => (
        <Table.Row key={i}>
          <Table.Cell>ООО КРУТОЙ ЯР</Table.Cell>
          <Table.Cell>2340016851</Table.Cell>
          <Table.Cell>0,00 ₽</Table.Cell>
          <Table.Cell>Основная СНО (ОСНО)</Table.Cell>
          <Table.Cell>Розницадиректор</Table.Cell>
          <Table.Cell>Ажиненко Д.</Table.Cell>
          <Table.Cell>0,00 ₽</Table.Cell>
          <Table.Cell>0,00 ₽</Table.Cell>
        </Table.Row>
      ))}
    </Table>
  );
};

export default AgentsOrganizationsListTable;
