import { useMemo, useState } from "react";
import Header from "@/components/Header";
import Button from "@/components/UI/Button";
import IconAdd from "@/components/Icons/IconAdd";
import LoadingIndicatorPage from "@/components/LoadingIndicatorPage";
import { CalendarRange, getRangeValues } from "../CalendarRangeCells/constants";
import { If } from "@/components/ConditionalRendering/If";
import {
  CalendarFormModal,
  CalendarPostModal,
  useCalendarContext,
} from "@/features/Calendar";
import { CalendarRanges } from "../CalendarRanges";
import { useEventListener } from "@/hooks";
import { EVENTS } from "@/constants";
import { CalendarTable } from "@/features/Calendar/components/CalendarTable";
import dayjs from "@/utils/dayjs";
import { QUnitType } from "dayjs";

export const CalendarView = () => {
  const [showPostModal, setShowPostModal] = useState(false);

  const {
    data = [],
    from,
    isFetching,
    refetch,
    range,
    setRange,
    showCalendarEntityModal,
    setShowCalendarEntityModal,
  } = useCalendarContext();

  const ranges = getRangeValues(range!, dayjs(from));

  useEventListener(EVENTS.REFRESH_DATA, () => refetch?.());

  const calendar = useMemo(
    () =>
      // @ts-ignore
      data.map((calendarItem) => {
        const entries = Array.from(ranges.values).map((rangeValue) => {
          const item = calendarItem.entries.find(
            // @ts-ignore
            (entryItem) => {
              let targetDate = dayjs(entryItem.startAt);

              if (range === CalendarRange.Day) {
                targetDate = targetDate.startOf("hour");
              } else {
                targetDate = targetDate.startOf("day");
              }

              return rangeValue.isSame(targetDate);
            },
          );

          const date = rangeValue;

          if (item) {
            let unit = "day";

            if (range === CalendarRange.Day) {
              unit = "hour";
            }

            return {
              ...item,
              date,
              hasItem: true,
              count: Math.ceil(
                dayjs(item.endAt).diff(rangeValue, unit as QUnitType, true),
              ),
            };
          }

          return {
            date,
            hasItem: false,
          };
        });

        return {
          ...calendarItem,
          entries,
        };
      }),
    [data],
  );

  if (isFetching) {
    return <LoadingIndicatorPage />;
  }

  return (
    <>
      <Header
        actions={
          <>
            <Button
              endIcon={IconAdd}
              onClick={() => setShowCalendarEntityModal!({ show: true })}
            >
              Запись
            </Button>
            <Button
              endIcon={IconAdd}
              onClick={() => setShowPostModal(true)}
              variant="secondary"
            >
              Пост
            </Button>
          </>
        }
        title="Календарь"
        showSearch={false}
      >
        <CalendarRanges onSelect={setRange!} range={range!} />
      </Header>

      <CalendarTable data={calendar} range={range!} />

      <If condition={showCalendarEntityModal!.show}>
        <CalendarFormModal
          item={showCalendarEntityModal!.item}
          isOpen={showCalendarEntityModal!.show}
          date={showCalendarEntityModal!.date}
          serviceBay={showCalendarEntityModal!.serviceBay}
          onClose={() => setShowCalendarEntityModal!({ show: false })}
          calendarGroup={data}
        />
      </If>

      <If condition={showPostModal}>
        <CalendarPostModal
          isOpen={showPostModal}
          onClose={() => setShowPostModal(false)}
        />
      </If>
    </>
  );
};
