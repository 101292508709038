import { CalendarEntryTypeEnum } from "@/features/Calendar/types";

export const calendarTabs = [
  {
    label: "Сделка",
    value: CalendarEntryTypeEnum.Order,
  },
  {
    label: "Резерв",
    value: CalendarEntryTypeEnum.Reserve,
  },
  {
    label: "Блокировка",
    value: CalendarEntryTypeEnum.Blocking,
  },
];

export const FormFields = {
  Comment: "comment",
  EndAt: "endAt",
  Marker: "marker",
  StartAt: "startAt",
  Title: "title",
  Type: "type",
};

export const initialValues = {
  [FormFields.EndAt]: "",
  [FormFields.Marker]: "",
  [FormFields.StartAt]: "",
  [FormFields.Type]: CalendarEntryTypeEnum.Order,
};
