import Header from "@/components/Header";
import WarehouseArrivalTable from "../WarehouseArrivalTable";
import Button from "@/components/UI/Button";
import IconAdd from "@/components/Icons/IconAdd";
import { useNavigate } from "react-router";
import { PATHS } from "@/constants";
import styles from "./styles.module.scss";
import {
  createWarehouseArrival,
  getWarehouses,
} from "@/features/Warehouse/services";
import { isAxiosError } from "axios";
import { toast } from "react-toastify";
import { useState } from "react";
import { first } from "lodash";
import { WarehouseEntity } from "@/features/Warehouse/types";

const WarehouseArrivalForm = () => {
  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(false);

  const handleCreateArrival = async () => {
    setIsFetching(true);

    try {
      const warehouses = await getWarehouses();
      const warehouse = (first(warehouses.items) as WarehouseEntity)._id;
      const arrival = await createWarehouseArrival({ warehouse });
      navigate(`/arrival/${arrival._id}`);
    } catch (err) {
      if (isAxiosError(err)) {
        toast.error(err?.response?.data?.error || err?.response?.data?.message);
      }
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div className={styles.container}>
      <Header
        actions={
          <>
            <Button
              disabled={isFetching}
              onClick={handleCreateArrival}
              endIcon={IconAdd}
            >
              Поступление
            </Button>
            {/*<Button endIcon={IconAdd} variant="secondary">*/}
            {/*  Создать коррекцию*/}
            {/*</Button>*/}
          </>
        }
        showSearch={false}
        title="Поступления"
      />
      <WarehouseArrivalTable />
    </div>
  );
};

export default WarehouseArrivalForm;
