import { OrderCarDetailFormModalProps, OrderCarFormModalProps } from "./types";
import Modal from "@/components/UI/Modal";
import CarForm from "@/features/Car/components/CarForm";
import ModalHeader from "@/components/UI/Modal/ModalHeader";
import styles from "./styles.module.scss";

export const OrderCarDetailFormModal = ({
  car,
  isOpen,
  onClose,
}: OrderCarDetailFormModalProps) => {
  return (
    <Modal className={styles.modal} isOpen={isOpen}>
      <ModalHeader onClose={onClose}>
        <h2>Toyota</h2>
      </ModalHeader>
      <CarForm
        id="1"
        car={car}
        onRemove={() => null}
        onSuccess={() => null}
        showTitle={false}
      />
    </Modal>
  );
};
