const IconKanban = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.27 22.75H4.23C2.22 22.75 1.25 21.82 1.25 19.9V4.1C1.25 2.18 2.23 1.25 4.23 1.25H8.27C10.28 1.25 11.25 2.18 11.25 4.1V19.9C11.25 21.82 10.27 22.75 8.27 22.75ZM4.23 2.75C2.96 2.75 2.75 3.09 2.75 4.1V19.9C2.75 20.91 2.96 21.25 4.23 21.25H8.27C9.54 21.25 9.75 20.91 9.75 19.9V4.1C9.75 3.09 9.54 2.75 8.27 2.75H4.23Z"
        fill="#5570F1"
      />
      <path
        d="M19.77 15.75H15.73C13.72 15.75 12.75 14.82 12.75 12.9V4.1C12.75 2.18 13.73 1.25 15.73 1.25H19.77C21.78 1.25 22.75 2.18 22.75 4.1V12.9C22.75 14.82 21.77 15.75 19.77 15.75ZM15.73 2.75C14.46 2.75 14.25 3.09 14.25 4.1V12.9C14.25 13.91 14.46 14.25 15.73 14.25H19.77C21.04 14.25 21.25 13.91 21.25 12.9V4.1C21.25 3.09 21.04 2.75 19.77 2.75H15.73Z"
        fill="#5570F1"
      />
    </svg>
  );
};

export default IconKanban;
