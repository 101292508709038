import cn from "classnames";
import { CalendarRangeCells } from "@/features/Calendar/components/CalendarRangeCells";
import { map } from "lodash";
import Flex from "@/components/UI/Flex";
import IconButton from "@/components/UI/IconButton";
import IconEdit from "@/components/Icons/IconEdit";
import { CalendarPostModal, useCalendarContext } from "@/features/Calendar";
import { If } from "@/components/ConditionalRendering/If";
import { useState } from "react";
import { ServiceBay } from "@/features/Order/types";
import { CalendarEntryItem } from "../CalendarEntryItem";
import type { CalendarTableProps } from "./types";
import styles from "./styles.module.scss";
import { CalendarRange } from "@/features/Calendar/components/CalendarRangeCells/constants";

export const CalendarTable = ({ data, range }: CalendarTableProps) => {
  const [post, setPost] = useState<ServiceBay | null>(null);
  const [showPostModal, setShowPostModal] = useState(false);
  const { setShowCalendarEntityModal } = useCalendarContext();

  const handleEditPost = (value: ServiceBay) => {
    setPost(value);
    setShowPostModal(true);
  };

  return (
    <>
      <Flex className={styles.container} rowGap="zero" flexDirection="column">
        <CalendarRangeCells range={range} />
        {map(data, (item, index) => (
          <Flex className={styles.row} columnGap="zero" rowGap="zero">
            <Flex
              alignItems="center"
              rowGap="zero"
              justifyContent="space-between"
              className={cn(
                styles.td,
                range === CalendarRange.Month ? styles.tdMonth : styles.tdFirst,
              )}
            >
              <span>{item?.serviceBay?.title}</span>
              <If condition={range !== CalendarRange.Month}>
                <IconButton
                  icon={IconEdit}
                  onClick={() => handleEditPost(item?.serviceBay)}
                  variant="empty-dark"
                  size="sm"
                />
              </If>
            </Flex>
            {map(item?.entries, (entry, index) => (
              <div className={styles.td}>
                <If condition={entry.hasItem}>
                  <CalendarEntryItem index={index} key={index} item={entry} />
                </If>

                <button
                  className={styles.tdCreateBtn}
                  onClick={() =>
                    setShowCalendarEntityModal!({
                      date: entry.date,
                      show: true,
                      serviceBay: item?.serviceBay,
                    })
                  }
                />
              </div>
            ))}
          </Flex>
        ))}
      </Flex>

      <If condition={showPostModal}>
        <CalendarPostModal
          post={post}
          isOpen={showPostModal}
          onClose={() => setShowPostModal(false)}
        />
      </If>
    </>
  );
};
