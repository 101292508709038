import Header from "@/components/Header";
import AgentsListTable from "./components/AgentsListTable";
import { useNavigate } from "react-router";
import Meta from "@/components/Meta";
import Button from "@/components/UI/Button";
import IconAdd from "@/components/Icons/IconAdd";
import { AgentType } from "@/features/Agent/constants";
import styles from "./styles.module.scss";

const Agents = () => {
  const navigate = useNavigate();

  const handleCreateAgent = () => {
    navigate(`/agents/create/${AgentType.Individual}`);
  };

  return (
    <div className={styles.container}>
      <Meta title="Контрагенты" />
      <Header
        actions={
          <Button onClick={handleCreateAgent} endIcon={IconAdd}>
            Контрагент
          </Button>
        }
        showSearch={false}
        title="Контрагенты"
      />
      <AgentsListTable />
    </div>
  );
};

export default Agents;
