import balance from "@/pages/Balance";

export enum WalletBalanceTypeEnum {
  Fiscal = "fiscal",
  NonFiscal = "non-fiscal",
  BankCardTerminal = "bank-card-terminal",
}

export enum WalletOwnerEnum {
  CounterpartAccount = "CounterpartAccount",
  PaymentTerminal = "PaymentTerminal",
}

export interface WalletEntity {
  _id: string;
  balance: number;
  ownerType: WalletOwnerEnum;
  balanceType: WalletBalanceTypeEnum;
  organizationName: string;
  isActive: boolean;
  owner: any;
  ownerTitle: string;
  todayChange: number;
  turnover: number;
}

export interface GetWalletsInput {
  pageNumber?: number;
  pageSize?: number;
}

export interface GetWalletsInputResponse {
  items: WalletEntity[];
  pagination: {
    pageNumber: number;
    pageSize: number;
    totalItems: number;
    totalPages: number;
  };
}

export enum WalletOwnerType {
  CounterpartAccount = "CounterpartAccount",
  PaymentTerminal = "PaymentTerminal",
}

export interface GetWalletsRequestPayload {
  pageNumber?: number;
  pageSize?: number;
}

export interface WalletsFromServer {
  items: WalletEntity[];
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}

export interface GetWalletsService {
  (input: GetWalletsInput): Promise<GetWalletsInputResponse>;
}
