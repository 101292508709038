import cx from "classnames";
import type { RadioProps } from "./types";
import styles from "./styles.module.scss";

const Radio = ({
  boxAlign = "start",
  className = "",
  label,
  ...props
}: RadioProps) => {
  return (
    <label className={cx(styles.radio, className)}>
      <input {...props} type="radio" className={styles.radioHandle} />
      <span
        className={cx(styles.radioBox, styles[`radioBox-align-${boxAlign}`])}
      />
      {label}
    </label>
  );
};

export default Radio;
