import Flex from "@/components/UI/Flex";
import styles from "./styles.module.scss";
import IconArrowDown from "@/components/Icons/IconArrowDown";

const DealDetail = () => {
  return (
    <div className={styles.container}>
      <Flex alignItems="center" justifyContent="space-between">
        <h1 className={styles.title}>Сделка #3143</h1>
        <ul className={styles.infoList}>
          <li className={styles.infoListItem}>
            Слесарный
            <IconArrowDown />
          </li>
          <li className={styles.infoListItem}>
            ИП Нисифоров Роман Алексеевич <IconArrowDown />
          </li>
        </ul>
      </Flex>
    </div>
  );
};

export default DealDetail;
