import Container from "@/components/Container";
import Grid from "@/components/UI/Grid";
import styles from "./styles.module.scss";

const AgentCalendar = () => {
  return (
    <Container className={styles.calendar} center>
      <Grid>
        <Grid.Item col={4}>
          <div className={styles.calendarItem}>
            <Grid>
              <Grid.Item col={6}>1</Grid.Item>
              <Grid.Item col={6}>1</Grid.Item>
            </Grid>
          </div>
        </Grid.Item>
        <Grid.Item col={4}>
          <div className={styles.calendarItem}>
            <Grid>
              <Grid.Item col={6}>1</Grid.Item>
              <Grid.Item col={6}>1</Grid.Item>
            </Grid>
          </div>
        </Grid.Item>
        <Grid.Item col={4}>
          <div className={styles.calendarItem}>
            <Grid>
              <Grid.Item col={6}>1</Grid.Item>
              <Grid.Item col={6}>1</Grid.Item>
            </Grid>
          </div>
        </Grid.Item>
      </Grid>
    </Container>
  );
};

export default AgentCalendar;
