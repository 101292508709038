import Header from "@/components/Header";
import WarehouseTable from "../WarehouseTable";
import Button from "@/components/UI/Button";
import IconAdd from "@/components/Icons/IconAdd";
import WarehouseFormModal from "../WarehouseFormModal";
import { useState } from "react";
import { If } from "@/components/ConditionalRendering/If";
import styles from "./styles.module.scss";

const WarehouseView = () => {
  const [showForm, setShowForm] = useState(false);

  const handleShowForm = () => {
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  return (
    <div className={styles.container}>
      <Header
        actions={
          <>
            <Button onClick={handleShowForm} endIcon={IconAdd}>
              Склад
            </Button>
          </>
        }
        showSearch={false}
        title="Склад"
      />
      <WarehouseTable />
      <If condition={showForm}>
        <WarehouseFormModal open={showForm} onClose={handleCloseForm} />
      </If>
    </div>
  );
};

export default WarehouseView;
