import api from "@/api";
import {
  CheckEmailIsAvailableResponse,
  CheckEmailIsAvailableService,
  CreateIndividualAgentService,
  CreateLegalAgentService,
  UpdateCarServiceOwnerService,
  UpdateIndividualAgentService,
} from "./components/AgentForm/types";
import agentSocialMedia from "@/features/Agent/components/AgentSocialMedia";

export const createIndividualAgent: CreateIndividualAgentService = async (
  body,
) => {
  const response = await api.post("/individual-counterpart", body);
  return response.data;
};

export const createLegalAgent: CreateLegalAgentService = async (body) => {
  const response = await api.post("/legal-counterpart", body);
  return response.data;
};

export const updateIndividualAgent: UpdateIndividualAgentService = async (
  id,
  body,
) => {
  const response = await api.patch(`/individual-counterpart/${id}`, body);
  return response.data;
};

export const checkEmailIsAvailable: CheckEmailIsAvailableService = async (
  email: string,
) => {
  const response = await api.post<CheckEmailIsAvailableResponse>(
    "/counterpart/check-email",
    {
      email,
    },
  );

  return response.data;
};

export const updateCarServiceOwner = async (id: string, input: any) => {
  const response = await api.patch(`/car-service-owner/${id}`, input);
  return response.data;
};

export const fetchSearchAgents = async (query: string) => {
  const response = await api.get(`/counterpart/search?query=${query}`);
  return response.data;
};

export const searchIndividualAgents = async (query: string) => {
  const response = await api.get(
    `/individual-counterpart/search-by-fio?query=${query}`,
  );
  return response.data;
};

export const searchLegalAgents = async (query: string) => {
  const response = await api.get(
    `/legal-counterpart/search-by-title?query=${query}`,
  );
  return response.data;
};
