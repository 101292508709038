import api from "@/api";

export const fetchGetBrands = async () => {
  const response = await api.get(`/brand`);
  return response.data;
};

export const fetchCreateBrand = async (body: unknown) => {
  const response = await api.post(`/brand`, body);
  return response.data;
};

export const fetchUpdateBrand = async (id: string, body: unknown) => {
  const response = await api.patch(`/brand/${id}`, body);
  return response.data;
};

export const fetchRemoveBrand = async (brandId: string) => {
  const response = await api.delete(`/brand/${brandId}`);
  return response.data;
};
