import cn from "classnames";
import type { ContainerProps } from "./types";
import type { PropsWithChildren } from "react";
import styles from "./styles.module.scss";

const Container = ({
  center,
  className,
  children,
}: PropsWithChildren<ContainerProps>) => {
  return (
    <div
      className={cn(
        styles.container,
        center && styles.containerCenter,
        className
      )}
    >
      {children}
    </div>
  );
};

export default Container;
