import Navigation from "@/components/Navigation";
import Logo from "@/components/Logo";
import UserInfo from "@/components/UserInfo";
import SubdivisionsList from "@/components/SubdivisionsList";
import { Scrollbar } from "react-scrollbars-custom";

const Sidebar = () => (
  <aside className="grid grid-rows-[62px_44px_1fr_50px] p-4 pt-0 bg-dark-primary">
    <Logo />
    <SubdivisionsList />
    <Scrollbar>
      <Navigation />
    </Scrollbar>
    <UserInfo />
  </aside>
);

export default Sidebar;
