const IconPlay = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" fill="#0A0B10" />
      <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="#16171D" />
      <path
        d="M8.27852 13.8667C7.94935 13.8667 7.63685 13.7875 7.36185 13.6292C6.71185 13.2542 6.35352 12.4917 6.35352 11.4875V8.51665C6.35352 7.50832 6.71185 6.74999 7.36185 6.37499C8.01185 5.99999 8.84935 6.07082 9.72435 6.57499L12.2952 8.05832C13.166 8.56249 13.6493 9.25415 13.6493 10.0042C13.6493 10.7542 13.1702 11.4458 12.2952 11.95L9.72435 13.4333C9.22018 13.7208 8.72852 13.8667 8.27852 13.8667ZM8.27852 6.75832C8.05352 6.75832 7.85352 6.80832 7.67435 6.91249C7.22435 7.17082 6.97852 7.74165 6.97852 8.51665V11.4833C6.97852 12.2583 7.22435 12.825 7.67435 13.0875C8.12435 13.35 8.74102 13.275 9.41185 12.8875L11.9827 11.4042C12.6535 11.0167 13.0243 10.5208 13.0243 9.99999C13.0243 9.47915 12.6535 8.98332 11.9827 8.59582L9.41185 7.11249C9.00352 6.87915 8.62018 6.75832 8.27852 6.75832Z"
        fill="white"
      />
    </svg>
  );
};

export default IconPlay;
