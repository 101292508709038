import ModalHeader from "@/components/UI/Modal/ModalHeader";
import ModalFooter from "@/components/UI/Modal/ModalFooter";
import React, { useEffect, useState } from "react";
import type { BrandFormProps } from "./types";
import styles from "./styles.module.scss";
import Modal from "@/components/UI/Modal";
import Button from "@/components/UI/Button";
import Flex from "@/components/UI/Flex";
import ModalBody from "@/components/UI/Modal/ModalBody";
import Input from "@/components/UI/Input";
import { Form, Formik } from "formik";
import { BrandEntity } from "@/features/Brand/types";
import { fetchCreateBrand, fetchUpdateBrand } from "@/features/Brand/services";
import { triggerEvent } from "@/utils/event";
import { EVENTS } from "@/constants";
import { FormFields } from "./constants";
import { brandFormSchema } from "./schema";
import { toast } from "react-toastify";

export const BrandForm = ({ brand, isOpen, onClose }: BrandFormProps) => {
  const [item, setItem] = useState<BrandEntity>({
    title: "",
  });
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (brand?._id) {
      setItem(brand);
    }
  }, [brand?._id]);

  const isEdit = Boolean(brand?._id);

  const handleSubmit = async ({ title }: BrandEntity) => {
    setIsFetching(true);

    try {
      isEdit
        ? await fetchUpdateBrand(brand?._id as string, { title })
        : await fetchCreateBrand({ title });

      toast.success(
        isEdit ? "Бренд успешно сохранен" : "Бренд успешно добавлен",
      );
      triggerEvent(EVENTS.REFRESH_DATA);
      onClose?.();
    } catch (err) {
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <Modal className={styles.modal} isOpen={isOpen}>
      <Formik
        enableReinitialize
        initialValues={item}
        onSubmit={handleSubmit}
        validateOnChange={false}
        validationSchema={brandFormSchema}
      >
        {({ errors, handleChange, dirty, isValid, values }) => (
          <Form>
            <ModalHeader onClose={onClose}>
              <h2>{isEdit ? "Редактирование бренда" : "Новый бренд"}</h2>
            </ModalHeader>
            <ModalBody>
              <Input
                error={errors[FormFields.Title]}
                name={FormFields.Title}
                onChange={handleChange}
                placeholder="Название бренда"
                value={values.title}
              />
            </ModalBody>
            <ModalFooter>
              <Flex>
                <Button
                  disabled={isFetching || !dirty}
                  isLoading={isFetching}
                  type="submit"
                >
                  Сохранить
                </Button>
                <Button
                  disabled={isFetching}
                  onClick={onClose}
                  variant="secondary"
                >
                  Отмена
                </Button>
              </Flex>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
