export const menu = [
  {
    label: "Все работы",
  },
  {
    label: "Оклейка пленкой",
  },
  {
    label: "Сигнализация",
  },
  {
    label: "Автоэлектроника",
  },
  {
    label: "Тонировка",
  },
  {
    label: "Оклейка салона",
  },
  {
    label: "Окраска",
  },
  {
    label: "Шумоизоляция",
  },
  {
    label: "Керамика",
  },
  {
    label: "Разборка",
  },
];

export enum CategoryType {
  Button = "button",
  Link = "link",
}
