export const initialValues = {
  title: "",
  category: "",
  price: "",
  manHours: "",
  manHourPrice: "",
  isFixedPayment: false,
};

export enum FormFields {
  Title = "title",
  Category = "category",
  Price = "price",
  ManHours = "manHours",
  ManHourPrice = "manHourPrice",
  IsFixedPayment = "isFixedPayment",
  Responsible = "responsible",
}
