import * as Yup from "yup";
import * as messages from "@/utils/validationMessages";
import { JobCategoryEntity } from "@/features/Job/types";
import { FormFields } from "./constants";

// @ts-ignore
export const jobCategoryFormSchema: Yup.ObjectSchema<JobCategoryEntity> =
  Yup.object().shape({
    [FormFields.Title]: Yup.string().required(messages.required),
  });
