import type { TableBuilderProps } from "./types";
import Table from "@/components/UI/Table";
import type { ReactNode } from "react";
import { If } from "@/components/ConditionalRendering/If";
import Pagination from "@/components/UI/Pagination";
import { Link } from "react-router-dom";
import { Scrollbar } from "react-scrollbars-custom";

function TableBuilder<T extends { _id: string; [key: string]: any }>({
  columns,
  data,
  handlePageChange,
  pageNumber,
  pageSize,
  isFetching,
  totalPages,
  totalItems,
}: TableBuilderProps<T>) {
  const renderColumns = () => {
    return columns.map((column, i) => (
      <Table.Cell width={column?.params?.width || "auto"} key={i}>
        {column.title}
      </Table.Cell>
    ));
  };

  const renderData = () => {
    return data?.map((item, i) => (
      <Table.Row key={item._id}>
        {columns.map((column) => (
          <Table.Cell>
            {Boolean(column?.link) ? (
              <Link to={`${column.link}${item._id}`}>{item[column.name]}</Link>
            ) : (
              item[column.name]
            )}
          </Table.Cell>
        ))}
      </Table.Row>
    ));
  };

  return (
    <>
      <Scrollbar>
        <Table
          isFetching={isFetching}
          header={<Table.Row>{renderColumns()}</Table.Row>}
        >
          {renderData()}
        </Table>
      </Scrollbar>
      <If condition={Boolean(data?.length)}>
        <Pagination
          activePage={pageNumber}
          pageSize={pageSize}
          totalPages={totalPages}
          totalItems={totalItems}
          onPageChange={handlePageChange}
        />
      </If>
    </>
  );
}

export default TableBuilder;
