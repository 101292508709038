import * as Yup from "yup";
import * as messages from "@/utils/validationMessages";
import { FormFields } from "./constants";
import { BrandEntity } from "@/features/Brand/types";

// @ts-ignore
export const brandFormSchema: Yup.ObjectSchema<BrandEntity> =
  Yup.object().shape({
    [FormFields.Title]: Yup.string().trim().required(messages.required),
  });
