import Header from "@/components/Header";

const SalaryProduction = () => {
  return (
    <div>
      <Header title="Выработка" />
    </div>
  );
};

export default SalaryProduction;
