import { useQuery } from "react-query";
import { fetchCalendarEntry } from "@/features/Calendar/services";
import { serializeCalendarData } from "@/features/Calendar/serializer";
import { CalendarRange } from "@/features/Calendar/components/CalendarRangeCells/constants";
import { useState } from "react";
import dayjs from "@/utils/dayjs";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { Dayjs } from "dayjs";

const DATE_FORMAT = "YYYY-MM-DD";

type Props = {
  from: string;
  to: string;
  range: CalendarRange;
};

export const useCalendarEntryQuery = ({ from, to, range }: Props) => {
  return useQuery({
    enabled: Boolean(from) && Boolean(to),
    queryKey: ["calendarEntry", from, to, range],
    queryFn: async () => {
      const { items } = await fetchCalendarEntry({
        from,
        to,
      });
      return items;
    },
  });
};
