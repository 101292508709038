import Modal from "@/components/UI/Modal";
import ModalHeader from "@/components/UI/Modal/ModalHeader";
import { useCallback, useState } from "react";
import ModalFooter from "@/components/UI/Modal/ModalFooter";
import Flex from "@/components/UI/Flex";
import Button from "@/components/UI/Button";
import ModalBody from "@/components/UI/Modal/ModalBody";
import Textarea from "@/components/UI/Textarea";
import { orderStatuses, statusChangeReasons } from "@/features/Order/constants";
import { OrderStatusChangeReasonEnum } from "@/features/Order/types";
import Checkbox from "@/components/UI/Checkbox";
import { OrderStatusChangeModalProps } from "./types";
import { Form, Formik } from "formik";
import { useParams } from "react-router";
import { initialValues, FormFields } from "./constants";
import { toast } from "react-toastify";
import { changeStatus } from "@/features/Order/services";
import styles from "./styles.module.scss";
import { isAxiosError } from "axios";

const OrderStatusChangeModal = ({
  isOpen,
  currentStatus,
  newStatus,
  onClose,
}: OrderStatusChangeModalProps) => {
  const { id } = useParams();
  const [isFetching, setIsFetching] = useState(false);
  const [status] = useState<{
    newStatus: string;
    reason?: string[];
    comment?: string;
  }>(initialValues);

  const handleClose = useCallback(() => {
    onClose();
  }, []);

  const handleSubmit = async (values: any) => {
    if (isFetching) return;

    setIsFetching(true);

    try {
      await changeStatus(id as string, {
        ...values,
        newStatus,
      });
      toast.success("Статус сделки изменен");
      onClose(newStatus);
    } catch (err) {
      if (isAxiosError(err)) {
        toast.error(err?.response?.data?.error);
      }
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <Modal className={styles.modal} isOpen={isOpen}>
      <Formik initialValues={status} onSubmit={handleSubmit}>
        {({ values, setFieldValue, handleChange, handleSubmit }) => (
          <Form>
            <ModalHeader onClose={handleClose}>
              <h2>Изменение статуса сделки</h2>
            </ModalHeader>
            <ModalBody>
              <p className={styles.description}>
                Укажите причину перевода в статус:
              </p>
              <div className={styles.status}>
                <span className={styles.statusItem}>
                  {orderStatuses[currentStatus]}
                </span>
                {` в `}
                <span className={styles.statusItem}>
                  {orderStatuses[newStatus]}
                </span>
              </div>
              <Flex
                className={styles.reasons}
                flexDirection="column"
                rowGap="xs"
              >
                {Object.keys(statusChangeReasons).map((reason) => (
                  <Checkbox
                    key={reason}
                    name={FormFields.Reason}
                    onChange={(e) =>
                      setFieldValue(
                        FormFields.Reason,
                        (values[FormFields.Reason] || []).concat(reason),
                      )
                    }
                    className={styles.reasonsItem}
                    value={
                      statusChangeReasons[reason as OrderStatusChangeReasonEnum]
                    }
                  />
                ))}
              </Flex>
              <Textarea
                name={FormFields.Comment}
                maxLength={300}
                placeholder="Комментарий"
                onChange={handleChange}
                value={values.comment}
              />
            </ModalBody>
            <ModalFooter>
              <Flex>
                <Button
                  disabled={isFetching}
                  isLoading={isFetching}
                  type="submit"
                >
                  Изменить
                </Button>
                <Button
                  disabled={isFetching}
                  onClick={handleClose}
                  variant="secondary"
                >
                  Отмена
                </Button>
              </Flex>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default OrderStatusChangeModal;
