import Table from "@/components/UI/Table";
import Input from "@/components/UI/Input";
import Select from "@/components/UI/Select";
import { warehouseAddresses } from "@/features/Warehouse/components/WarehouseFormModal/constants";
import {
  WarehouseAddressEntity,
  WarehouseAddressTypeEnum,
  WarehouseEntity,
} from "@/features/Warehouse/types";
import InputNumber from "@/components/UI/InputNumber";
import Flex from "@/components/UI/Flex";
import IconButton from "@/components/UI/IconButton";
import IconTrash from "@/components/Icons/IconTrash";
import { FormFields } from "./constants";
import { WarehouseAddressProps } from "./types";
import { ChangeEvent } from "react";
import { FormikErrors, useFormikContext } from "formik";
import styles from "@/features/Warehouse/components/WarehouseFormModal/styles.module.scss";

const WarehouseAddressForm = ({
  id,
  index,
  onRemove,
}: WarehouseAddressProps) => {
  const { errors, setFieldValue, values } = useFormikContext<WarehouseEntity>();

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFieldValue(`warehouseAddresses.${index}.${name}`, value);
  };

  const formikErrors = errors.warehouseAddresses?.[
    index
  ] as FormikErrors<WarehouseAddressEntity>;

  const formikValues = values.warehouseAddresses?.[
    index
  ] as WarehouseAddressEntity;

  return (
    <Table.Row className={styles.row}>
      <Table.Cell className={styles.cell}>
        <Input
          light
          error={formikErrors?.[FormFields.Title]}
          name={FormFields.Title}
          variant="empty"
          onChange={handleOnChange}
          placeholder="Название адреса на складе"
          value={formikValues[FormFields.Title]}
        />
      </Table.Cell>
      <Table.Cell className={styles.cell}>
        <Select
          className={styles.cellSelect}
          name={FormFields.Type}
          isEmpty
          options={Object.keys(warehouseAddresses).map((address) => ({
            label: warehouseAddresses[address as WarehouseAddressTypeEnum],
            value: address,
          }))}
          onChange={(e) => {
            setFieldValue(
              `warehouseAddresses.${index}.${FormFields.Type}`,
              e?.value,
            );
          }}
          placeholder="Вид адреса"
        />
      </Table.Cell>
      <Table.Cell className={styles.cell}>
        <InputNumber
          light
          error={formikErrors?.[FormFields.Floors]}
          name={FormFields.Floors}
          onChange={handleOnChange}
          placeholder="Этажей склада"
          value={formikValues[FormFields.Floors]}
        />
      </Table.Cell>
      <Table.Cell className={styles.cell}>
        <InputNumber
          light
          error={formikErrors?.[FormFields.RowsPerFloor]}
          name={FormFields.RowsPerFloor}
          onChange={handleOnChange}
          placeholder="Рядов на этаже"
          value={formikValues[FormFields.RowsPerFloor]}
        />
      </Table.Cell>
      <Table.Cell className={styles.cell}>
        <InputNumber
          light
          error={formikErrors?.[FormFields.RacksPerRow]}
          name={FormFields.RacksPerRow}
          onChange={handleOnChange}
          placeholder="Стеллажей в ряду"
          value={formikValues[FormFields.RacksPerRow]}
        />
      </Table.Cell>
      <Table.Cell className={styles.cell}>
        <InputNumber
          light
          error={formikErrors?.[FormFields.ShelvesPerRack]}
          name={FormFields.ShelvesPerRack}
          onChange={handleOnChange}
          placeholder="Полок в стеллаже"
          value={formikValues[FormFields.ShelvesPerRack]}
        />
      </Table.Cell>
      <Table.Cell className={styles.cell}>
        <Flex alignItems="center" justifyContent="space-between">
          <InputNumber
            light
            error={formikErrors?.[FormFields.CellsPerShelf]}
            name={FormFields.CellsPerShelf}
            onChange={handleOnChange}
            placeholder="Ячеек на полке"
            value={formikValues[FormFields.CellsPerShelf]}
          />
          <IconButton icon={IconTrash} onClick={() => onRemove(id)} />
        </Flex>
      </Table.Cell>
    </Table.Row>
  );
};

export default WarehouseAddressForm;
