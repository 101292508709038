import Input from "@/components/UI/Input";
import Table from "@/components/UI/Table";
import Flex from "@/components/UI/Flex";
import Button from "@/components/UI/Button";
import Modal from "@/components/UI/Modal";
import { useCallback, useState } from "react";
import { Agent, IndividualAgent } from "@/types";
import { useOnce } from "@/hooks";
import { getIndividualAgents } from "@/pages/Agents/components/AgentsListTable/service";
import { getAgentFullName } from "@/pages/AgentDetail/utils";
import { getBaseDate } from "@/utils/date";
import ModalHeader from "@/components/UI/Modal/ModalHeader";
import ModalBody from "@/components/UI/Modal/ModalBody";
import ModalFooter from "@/components/UI/Modal/ModalFooter";
import IconLoading from "@/components/Icons/IconLoading";
import { AgentRepresentativeModalProps } from "./types";
import styles from "@/features/Agent/components/AgentRepresentative/styles.module.scss";
import { Scrollbar } from "react-scrollbars-custom";
import Checkbox from "@/components/UI/Checkbox";
import { isEmpty } from "lodash";

const AgentRepresentativeModal = ({
  onClose,
  onSelect,
}: AgentRepresentativeModalProps) => {
  const [agents, setAgents] = useState<IndividualAgent[]>([]);
  const [isFetching, setIsFetching] = useState(true);
  const [selectedAgents, setSelectedAgents] = useState<IndividualAgent[]>([]);

  const handleGetIndividualAgents = useCallback(
    () =>
      getIndividualAgents({
        pageNumber: 1,
        pageSize: 100,
      })
        .then((data) => {
          setAgents(data.items);
        })
        .finally(() => {
          setIsFetching(false);
        }),
    [],
  );

  const handleOnSelect = () => {
    onSelect(selectedAgents);
    onClose();
  };

  const handleToggleAgent = (agent: IndividualAgent) => {
    const hasAgent = selectedAgents.find(
      (agentItem) => agentItem._id === agent._id,
    );

    if (hasAgent) {
      setSelectedAgents((agents) =>
        agents.filter((agentItem) => agentItem._id !== agent._id),
      );
    } else {
      setSelectedAgents((agents) => agents.concat(agent));
    }
  };

  useOnce(() => {
    handleGetIndividualAgents();
  });

  return (
    <Modal className={styles.modal} isOpen={true}>
      <ModalHeader onClose={onClose}>
        <h2 className={styles.modalTitle}>Выбрать представителя</h2>
        <Input
          hidden
          disabled={isFetching}
          placeholder="Введите имя"
          variant="small"
        />
      </ModalHeader>
      <ModalBody>
        <Scrollbar style={{ height: "500px" }}>
          {isFetching ? (
            <IconLoading />
          ) : (
            <Table
              header={
                <Table.Row>
                  <Table.Cell>Полное имя</Table.Cell>
                  <Table.Cell>Номер телефона</Table.Cell>
                  <Table.Cell>Дата рождения</Table.Cell>
                  <Table.Cell>Размер скидки</Table.Cell>
                  <Table.Cell>Баланс</Table.Cell>
                  <Table.Cell>Оборот</Table.Cell>
                </Table.Row>
              }
              isEmpty={!agents.length}
              isFetching={isFetching}
            >
              {agents.map((agent) => (
                <Table.Row key={agent._id}>
                  <Table.Cell>
                    <Flex alignItems="center">
                      <Checkbox onChange={() => handleToggleAgent(agent)} />
                      {getAgentFullName(agent)}
                    </Flex>
                  </Table.Cell>
                  <Table.Cell>{agent.phone}</Table.Cell>
                  <Table.Cell>{getBaseDate(agent.birthDate)}</Table.Cell>
                  <Table.Cell>{agent.discount} %</Table.Cell>
                  <Table.Cell>
                    {agent.account?.wallet?.balance || 0} ₽
                  </Table.Cell>
                  <Table.Cell>10 600.00 ₽</Table.Cell>
                </Table.Row>
              ))}
            </Table>
          )}
        </Scrollbar>
      </ModalBody>
      <ModalFooter>
        <Flex alignItems="center">
          <Button
            onClick={handleOnSelect}
            disabled={isFetching || isEmpty(selectedAgents)}
          >
            Выбрать
          </Button>
          <Button onClick={onClose} variant="secondary">
            Отмена
          </Button>
        </Flex>
      </ModalFooter>
    </Modal>
  );
};

export default AgentRepresentativeModal;
