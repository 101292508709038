import { NavLink } from "react-router-dom";
import cn from "classnames";
import { menu } from "./constants";
import { If } from "@/components/ConditionalRendering/If";
import { Menu } from "@/components/Navigation/types";
import NavigationToggle from "./NavigationToggle";
import IconArrowDown from "@/components/Icons/IconArrowDown";
import type { NavigationProps } from "./types";
import styles from "./styles.module.scss";

const Navigation = ({ onClick }: NavigationProps) => {
  const renderMenu = (items: Menu[]) =>
    items?.map(({ icon: Icon, path, submenu, title }, index) => {
      const hasSubmenu = Boolean(submenu?.length);

      if (hasSubmenu) {
        return <NavigationToggle item={{ icon: Icon, title, path, submenu }} />;
      }

      return (
        <li key={path}>
          <NavLink
            state={{
              title,
            }}
            onClick={onClick}
            className={({ isActive }) =>
              cn(styles.navLink, isActive && styles.navLinkActive)
            }
            to={path}
          >
            <div className={styles.navRow}>
              <div className={styles.navRowCol}>
                {Icon && <Icon />}
                {title}
              </div>
              <div className={styles.navRowCol}>
                <If condition={hasSubmenu}>
                  <IconArrowDown />
                </If>
              </div>
            </div>
          </NavLink>
        </li>
      );
    });

  return (
    <nav className="mb-4">
      <ul className={styles.navList}>{renderMenu(menu)}</ul>
    </nav>
  );
};

export default Navigation;
