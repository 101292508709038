import * as Yup from "yup";
import * as messages from "@/utils/validationMessages";
import * as patterns from "@/utils/validationPatterns";
import * as expect from "@/utils/validationExpect";
import { IndividualAgent } from "@/types";
import { CounterpartRoleEnum } from "@/types";
import { FormFields } from "./constants";
import { agentSocialMediaFormSchema } from "@/features/Agent/components/AgentSocialMediaForm/schema";
import { checkEmailIsAvailable } from "@/features/Agent/service";
import { maxValueOneHundred, minValueZero } from "@/utils/validationMessages";

// @ts-ignore
export const ownerFormSchema: Yup.ObjectSchema = Yup.object().shape({});
