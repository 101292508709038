import Header from "@/components/Header";
import styles from "./styles.module.scss";
import { useOnce } from "@/hooks";
import { fetchWarehouse } from "@/features/Warehouse/services";
import { useParams } from "react-router";

const WarehouseDetail = () => {
  const { id } = useParams();

  useOnce(() => {
    fetchWarehouse(id as string).then((res) => {});
  });

  return (
    <div className={styles.container}>
      <Header showSearch={false} title="Склад" />
    </div>
  );
};

export default WarehouseDetail;
