import Header from "@/components/Header";

const Debt = () => {
  return (
    <div>
      <Header />
    </div>
  );
};

export default Debt;
