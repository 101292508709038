import { JobCategoryEntity, JobEntity } from "./types";
import { ResponseFromServer } from "@/types";

export const jobCategoriesSerialize = (
  data: ResponseFromServer<JobCategoryEntity>,
) => ({
  items: data.items,
  pagination: {
    pageNumber: data.pageNumber,
    pageSize: data.pageSize,
    totalItems: data.totalItems,
    totalPages: data.totalPages,
  },
});

export const jobsSerialize = (data: ResponseFromServer<JobEntity>) => ({
  items: data.items,
  pagination: {
    pageNumber: data.pageNumber,
    pageSize: data.pageSize,
    totalItems: data.totalItems,
    totalPages: data.totalPages,
  },
});
