import { PropsWithChildren } from "react";
import cn from "classnames";
import { ModalBodyProps } from "./types";
import styles from "./styles.module.scss";

const ModalBody = ({
  children,
  className,
}: PropsWithChildren<ModalBodyProps>) => {
  return <div className={cn(styles.modalBody, className)}>{children}</div>;
};

export default ModalBody;
