import cn from "classnames";
import { ModalFooterProps } from "./types";
import styles from "./styles.module.scss";
import { PropsWithChildren } from "react";

const ModalFooter = ({ children }: PropsWithChildren<ModalFooterProps>) => {
  return <div className={cn(styles.modalFooter)}>{children}</div>;
};

export default ModalFooter;
