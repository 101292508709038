import { NavLink } from "react-router-dom";
import { menu } from "./constants";
import styles from "./styles.module.scss";

const FinanceStatementNavigation = () => {
  const renderMenu = () => {
    return menu.map(({ label }, index) => (
      <li className={styles.navItem} key={index}>
        <NavLink className={styles.navLink} to="/">
          {label}
        </NavLink>
      </li>
    ));
  };

  return (
    <nav className={styles.nav}>
      <ul className={styles.navList}>{renderMenu()}</ul>
    </nav>
  );
};

export default FinanceStatementNavigation;
