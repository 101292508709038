import { useState } from "react";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router";
import Input from "@/components/UI/Input";
import { FormFields } from "@/pages/Auth/constants";
import Button from "@/components/UI/Button";
import { loginUser } from "@/pages/Auth/service";
import { useAppDispatch } from "@/store";
import { setUser } from "@/store/slices/userSlice";
import { PATHS } from "@/constants";
import type { AuthFormData } from "@/pages/Auth/types";
import { authFormSchema } from "./schema";
import InputPassword from "@/components/UI/InputPassword";
import { setAuthToken } from "@/api";
import Flex from "@/components/UI/Flex";
import { toast } from "react-toastify";
import styles from "./styles.module.scss";
import { NavLink } from "react-router-dom";

const initialValues = {
  email: "",
  password: "",
};

const AuthForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isFetching, setIsFetching] = useState(false);

  const handleAuth = async (values: AuthFormData) => {
    setIsFetching(true);

    try {
      const user = await loginUser(values);
      dispatch(setUser(user));
      setAuthToken(user.token as string);
      toast.success("Вы вошли в систему");
      navigate(PATHS.ORDER);
    } catch (err) {
      setIsFetching(false);
      toast.error("Ошибка авторизации");
    }
  };

  // background-color: var(--color-bright-secondary);
  // padding: 10px 16px;
  // border-radius: 12px;
  // color: var(--color-text-bright-secondary);
  // height: 40px;
  // display: grid;
  // place-items: center;

  return (
    <div className={styles.wrapper}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleAuth}
        validationSchema={authFormSchema}
        validateOnChange={false}
      >
        {({ values, errors, handleChange }) => (
          <Form className="flex flex-col gap-4 md:min-w-[350px]">
            <h1 className="text-md lg:text-xxl m-0 font-medium">Авторизация</h1>
            <div className="p-4 text-sm bg-main-quinary rounded-lg border-l border-main-default text-bright-primary">
              Для входа в систему, вам необходимо
              <br /> использовать предоставленный пароль
            </div>
            <Input
              error={errors.email}
              name={FormFields.Email}
              type="text"
              placeholder="Логин"
              onChange={handleChange}
              value={values.email}
            />
            <InputPassword
              error={errors.password}
              name={FormFields.Password}
              placeholder="Пароль"
              onChange={handleChange}
              value={values.password}
            />
            <Flex className="flex-col md:flex-row md:items-center md:justify-center gap-3">
              <Button
                className="lg:w-1/2 whitespace-nowrap text-sm"
                isLoading={isFetching}
                type="submit"
              >
                Авторизоваться
              </Button>
              <NavLink
                to={PATHS.RESET_PASSWORD}
                className="lg:w-1/2 h-10 bg-bright-secondary grid place-items-center px-2 rounded-xl whitespace-nowrap text-sm"
              >
                Восстановить пароль
              </NavLink>
            </Flex>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AuthForm;
