import { AxiosError, AxiosResponse, isAxiosError } from "axios";

export enum ErrorCodeEnum {
  UserNotFound = "USER_NOT_FOUND",
  InvalidCredentials = "INVALID_CREDENTIALS",
  InvalidToken = "INVALID_TOKEN",
  InvalidOldPassword = "INVALID_OLD_PASSWORD",
  DomainAlreadyTaken = "DOMAIN_ALREADY_TAKEN",
  UploadFailed = "UPLOAD_FAILED",
  InsufficientPerms = "INSUFFICIENT_PERMS",
  FileNotFound = "FILE_NOT_FOUND",
}

export const errorCodes: Record<ErrorCodeEnum, string> = {
  [ErrorCodeEnum.UserNotFound]: "Пользователь не найден",
  [ErrorCodeEnum.InvalidCredentials]: "",
  [ErrorCodeEnum.InvalidToken]: "Невалидный токен",
  [ErrorCodeEnum.InvalidOldPassword]: "",
  [ErrorCodeEnum.DomainAlreadyTaken]: "",
  [ErrorCodeEnum.UploadFailed]: "",
  [ErrorCodeEnum.InsufficientPerms]: "",
  [ErrorCodeEnum.FileNotFound]: "Файл не найден",
};

export const getErrorMessage = (error: AxiosError, defaultMessage = "") => {
  if (isAxiosError(error)) {
    return errorCodes[error?.response?.data?.code as ErrorCodeEnum];
  }
  return defaultMessage;
};
