import { PatternFormat } from "react-number-format";
import cn from "classnames";
import Input from "@/components/UI/Input";
import type { InputPhoneProps } from "./types";
import styles from "./styles.module.scss";

const InputPhone = ({
  error,
  label,
  name,
  onChange,
  placeholder,
  value,
}: InputPhoneProps) => {
  return (
    <div className={styles.container}>
      {Boolean(label) && label}
      <PatternFormat
        name={name}
        className={cn(styles.input, error && styles.invalid)}
        placeholder={placeholder}
        onChange={onChange}
        customInput={Input}
        format="+#(###)###-##-##"
        inputMode="tel"
        value={value}
      />
      {Boolean(error) && <p className={styles.error}>{error}</p>}
    </div>
  );
};

export default InputPhone;
