import type { AgentFormProps } from "./types";
import AgentIndividualForm from "../AgentIndividualForm";
import AgentLegalForm from "../AgentLegalForm";
import { CounterpartTypeEnum, IndividualAgent, LegalAgent } from "@/types";
import { AgentType } from "@/features/Agent/constants";

const AgentForm = ({
  agentData,
  agentType = CounterpartTypeEnum.Individual,
  showTitle = true,
  isEdit,
}: AgentFormProps) => {
  if (agentType === CounterpartTypeEnum.Individual) {
    return (
      <AgentIndividualForm
        agentData={agentData as IndividualAgent}
        isEdit={isEdit}
        showTitle={showTitle}
      />
    );
  }

  return <AgentLegalForm agentData={agentData as LegalAgent} />;
};

export default AgentForm;
