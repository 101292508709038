import cn from "classnames";
import type { IconButtonProps } from "./types";
import styles from "./styles.module.scss";
import IconLoading from "@/components/Icons/IconLoading";

const IconButton = ({
  className,
  icon: Icon,
  disabled,
  isLoading,
  label,
  onClick,
  size = "md",
  type = "button",
  variant = "empty",
}: IconButtonProps) => {
  return (
    <button
      aria-label={label}
      className={cn(
        styles.button,
        styles[`button--${variant}`],
        styles[`button--size-${size}`],
        className,
      )}
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      {isLoading ? <IconLoading /> : <Icon />}
    </button>
  );
};

export default IconButton;
