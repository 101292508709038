import { AppLayoutProps } from "./types";
import { PropsWithChildren } from "react";
import Sidebar from "@/components/Sidebar";
import AgentWelcomeForm from "@/features/Agent/components/AgentWelcomeForm";
import Logo from "@/components/Logo";
import { BurgerMenu } from "@/components/BurgerMenu";

const AppLayout = ({ children }: PropsWithChildren<AppLayoutProps>) => {
  return (
    <>
      <div className="grid h-full md:grid-cols-[216px_1fr] grid-rows-[64px_1fr] md:grid-rows-none">
        <header className="flex md:hidden bg-dark-primary justify-between px-2">
          <Logo />
          <BurgerMenu />
        </header>
        <div className="md:grid hidden">
          <Sidebar />
        </div>
        <div className="h-full">{children}</div>
      </div>
      <AgentWelcomeForm />
    </>
  );
};

export default AppLayout;
