import Typography from "@/components/UI/Typography";
import { map } from "lodash";
import { markers } from "./constants";
import Flex from "@/components/UI/Flex";
import { CalendarMarker } from "./CalendarMarker";
import { CalendarMarkerProps, CalendarMarkersProps } from "./types";

export const CalendarMarkers = ({
  onSelect,
  variant,
}: CalendarMarkersProps) => {
  const renderMarkers = () => {
    return map(markers, (marker) => (
      <CalendarMarker
        key={marker.color}
        onSelect={onSelect}
        isActive={variant === marker.color}
        variant={marker.color}
      />
    ));
  };

  return (
    <Flex alignItems="center">
      <Typography variant="highlight">Добавить маркер</Typography>
      <Flex columnGap="sm">{renderMarkers()}</Flex>
    </Flex>
  );
};
