import TabsItem from "./TabsItem";
import cn from "classnames";
import {
  Children,
  cloneElement,
  isValidElement,
  PropsWithChildren,
} from "react";
import type { TabsProps, TabsItemProps } from "./types";
import styles from "./styles.module.scss";

const Tabs = ({
  children,
  className,
  onSelect,
  value,
}: PropsWithChildren<TabsProps>) => {
  const enhancedChildren = Children.map(children, (child) => {
    if (!isValidElement(child) || child.type !== TabsItem) {
      return child;
    }

    return cloneElement(child, {
      active: child.props.value === value,
      onSelect,
    } as TabsItemProps);
  });

  return (
    <div className={styles.tabs}>
      <div className={cn(styles.tabsList, className)} role="tablist">
        {enhancedChildren}
      </div>
    </div>
  );
};

Tabs.Item = TabsItem;

export default Tabs;
