import JobView from "@/features/Job/components/JobView";
import Meta from "@/components/Meta";

const Jobs = () => {
  return (
    <>
      <Meta title="Каталог работ" />
      <JobView />
    </>
  );
};

export default Jobs;
