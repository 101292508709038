const getStringLength = (value: string | null | undefined) =>
  String(value || "")
    .replace(/_+/g, "")
    .replace(/\s+/g, "").length;

export const stringLength =
  (expectedLength: number) => (value: string | null | undefined) => {
    const length = getStringLength(String(value || ""));

    if (!length) {
      return true;
    }

    return length === expectedLength;
  };

export const phoneNumber = (value: string | null | undefined) => {
  return stringLength(11)(String(value).replace(/\D/giu, ""));
};
