import { useCallback, useState } from "react";
import PaymentTerminalTableList from "../PaymentTerminalTableList";
import Header from "@/components/Header";
import styles from "./styles.module.scss";
import PaymentTerminalFormModal from "../PaymentTerminalFormModal";
import Button from "@/components/UI/Button";
import IconAdd from "@/components/Icons/IconAdd";
import BalanceForm from "@/features/Balance/components/BalanceForm";
import Modal from "@/components/UI/Modal";
import ModalHeader from "@/components/UI/Modal/ModalHeader";
import Meta from "@/components/Meta";

const PaymentTerminalView = () => {
  const [showForm, setShowForm] = useState(false);

  const handleShowForm = useCallback(() => {
    setShowForm(true);
  }, []);

  const handleHideForm = useCallback(() => {
    setShowForm(false);
  }, []);

  return (
    <div className={styles.container}>
      <Meta title="Балансы и касса" />
      <Header
        title="Балансы и касса"
        showSearch={false}
        actions={
          <Button endIcon={IconAdd} onClick={handleShowForm}>
            Баланс
          </Button>
        }
      />
      <PaymentTerminalTableList />
      <Modal className={styles.modal} isOpen={showForm}>
        <ModalHeader>
          <h2>Добавить баланс</h2>
        </ModalHeader>
        <BalanceForm onClose={handleHideForm} />
      </Modal>
      {/*<PaymentTerminalFormModal isOpen={showForm} onClose={handleHideForm} />*/}
    </div>
  );
};

export default PaymentTerminalView;
