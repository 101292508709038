import * as Yup from "yup";
import * as messages from "@/utils/validationMessages";
import { CarColorEnum } from "@/features/Car/types";
import { CarEngineTypeEnum } from "@/types";

export enum FormFields {
  Vin = "vin",
  CarType = "carType",
  LicensePlate = "licensePlate",
  Brand = "brand",
  Model = "model",
  Owner = "owner",
  BodyType = "bodyType",
  Modification = "modification",
  Generation = "generation",
  Year = "year",
  STS = "STS",
  PTS = "PTS",
  OwnerAddress = "ownerAddress",
  OwnerFIO = "ownerFIO",
  Mileage = "mileage",
  Series = "series",
  Color = "color",
  EngineType = "engineType",
  MaintenanceDate = "maintenanceDate",
}

export const carInitialValues = {
  [FormFields.CarType]: "",
  [FormFields.LicensePlate]: "",
  [FormFields.Brand]: "",
  [FormFields.Model]: "",
  [FormFields.BodyType]: "",
  [FormFields.Modification]: "",
  [FormFields.Generation]: "",
  [FormFields.Year]: "",
};

export const carColors = {
  [CarColorEnum.Red]: "Красный",
  [CarColorEnum.Blue]: "Синий",
  [CarColorEnum.Green]: "Зеленый",
  [CarColorEnum.Black]: "Черный",
  [CarColorEnum.White]: "Белый",
  [CarColorEnum.Silver]: "Серебряный",
  [CarColorEnum.Grey]: "Серый",
  [CarColorEnum.Yellow]: "Желтый",
  [CarColorEnum.Orange]: "Оранжевый",
  [CarColorEnum.Purple]: "Пурпурный",
  [CarColorEnum.Brown]: "Коричневый",
  [CarColorEnum.Gold]: "Золотой",
  [CarColorEnum.Pink]: "Розовый",
};

export const carEngineTypes = {
  [CarEngineTypeEnum.Petrol]: "Бензин",
  [CarEngineTypeEnum.Diesel]: "Дизель",
  [CarEngineTypeEnum.Electric]: "Электро",
  [CarEngineTypeEnum.Hybrid]: "Гибрид",
  [CarEngineTypeEnum.Hydrogen]: "Водород",
  [CarEngineTypeEnum.NaturalGas]: "Газ",
  [CarEngineTypeEnum.Biofuel]: "Биотопливо",
};
