import { map } from "lodash";
import { useMemo } from "react";
import { CalendarRange, getRangeValues } from "./constants";
import Flex from "@/components/UI/Flex";
import cn from "classnames";
import type { CalendarRangeProps } from "./types";
import styles from "./styles.module.scss";

export const CalendarRangeCells = ({ range }: CalendarRangeProps) => {
  const ranges = useMemo(() => getRangeValues(range), [range]);

  const renderCells = useMemo(() => {
    return map(ranges.values, (value, index) => (
      <div className={cn(styles.td)} key={index}>
        {value.format(ranges.format)}
      </div>
    ));
  }, [range]);

  return (
    <Flex className={styles.row} columnGap="zero" rowGap="zero">
      <div
        className={cn(
          styles.td,
          range === CalendarRange.Month ? styles.tdMonth : styles.tdFirst,
        )}
      >
        {ranges.label}
      </div>
      {renderCells}
    </Flex>
  );
};
