import WarehouseArrivalView from "@/features/Warehouse/components/WarehouseArrivalView";
import Meta from "@/components/Meta";

const WarehouseArrival = () => {
  return (
    <>
      <Meta title="Склад - Поступления" />
      <WarehouseArrivalView />
    </>
  );
};

export default WarehouseArrival;
