import { useTable } from "@/hooks";
import Table from "@/components/UI/Table";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { OrderEntity } from "../../types";
import { getOrders } from "@/features/Order/services";
import { getAgentFullName } from "@/pages/AgentDetail/utils";
import { getBaseDate } from "@/utils/date";
import Pagination from "@/components/UI/Pagination";
import { If } from "@/components/ConditionalRendering/If";
import { Link } from "react-router-dom";
import { IndividualAgent, UserType } from "@/types";
import { Scrollbar } from "react-scrollbars-custom";

const OrderTableList = () => {
  const [items, setItems] = useState<OrderEntity[]>([]);
  const [isFetching, setIsFetching] = useState(true);
  const {
    pageNumber,
    pageSize,
    setTotalPages,
    setTotalItems,
    totalPages,
    totalItems,
    handlePageChange,
  } = useTable();

  const fetchItems = () => {
    setIsFetching(true);

    getOrders({ pageNumber, pageSize })
      .then((data) => {
        setItems(data.items);
        setTotalPages(data.pagination.totalPages || 1);
        setTotalItems(data.pagination.totalItems || 0);
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  useEffect(() => {
    fetchItems();
  }, [pageNumber, pageSize]);

  return (
    <>
      <Scrollbar>
        <Table
          header={
            <Table.Row showDropdown>
              <Table.Cell>Номер</Table.Cell>
              <Table.Cell>Контакт</Table.Cell>
              <Table.Cell>Сумма в сделке</Table.Cell>
              <Table.Cell>Статус оплаты</Table.Cell>
              <Table.Cell>Ответственный</Table.Cell>
              <Table.Cell>Дата последней сделки</Table.Cell>
              <Table.Cell>Дата первого закрытия сделки</Table.Cell>
              <Table.Cell>Подразделение</Table.Cell>
              <Table.Cell>Пробег</Table.Cell>
            </Table.Row>
          }
          isEmpty={isEmpty(items)}
          isFetching={isFetching}
        >
          {items.map((item) => (
            <Table.Row key={item._id}>
              <Table.Cell>
                <Link to={`/orders/${item._id}`}>{item.increment}</Link>
              </Table.Cell>
              <Table.Cell>
                <If condition={Boolean(item.client?.counterpartProfile)}>
                  <Link
                    to={`/agents/${item.client?.counterpartProfile?._id}/${item.client?.counterpartType}`}
                  >
                    {getAgentFullName(
                      item.client?.counterpartProfile as IndividualAgent,
                    )}
                  </Link>
                </If>
              </Table.Cell>
              <Table.Cell>{item.totalPrice} ₽</Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell>
                <If condition={Boolean(item.responsible?.counterpartProfile)}>
                  <Link
                    to={`/agents/${item.responsible?.counterpartProfile?._id}/${item.responsible?.counterpartType}`}
                  >
                    {getAgentFullName(item.responsible?.counterpartProfile)}
                  </Link>
                </If>
              </Table.Cell>
              <Table.Cell>{getBaseDate(item.closedAt)}</Table.Cell>
              <Table.Cell>{getBaseDate(item.closedAt)}</Table.Cell>
              <Table.Cell>{item.section?.title}</Table.Cell>
              <Table.Cell>{item.car?.mileage}</Table.Cell>
            </Table.Row>
          ))}
        </Table>
      </Scrollbar>
      <If condition={Boolean(items.length)}>
        <Pagination
          activePage={pageNumber}
          pageSize={pageSize}
          totalPages={totalPages}
          totalItems={totalItems}
          onPageChange={handlePageChange}
        />
      </If>
    </>
  );
};

export default OrderTableList;
