export enum SettingsOrdersTab {
  Calendar = "calendar",
  Docs = "docs",
  Order = "order",
  Warehouse = "warehouse",
}

export const settingsOrdersTabs = [
  {
    label: "Сделка",
    value: SettingsOrdersTab.Order,
  },
  {
    label: "Склад",
    value: SettingsOrdersTab.Warehouse,
  },
  {
    label: "Календарь",
    value: SettingsOrdersTab.Calendar,
  },
  {
    label: "Печатаемые документы",
    value: SettingsOrdersTab.Docs,
  },
];
