import React, { useState } from "react";
import { map } from "lodash";
import Header from "@/components/Header";
import Tabs from "@/components/UI/Tabs";
import { SettingsOrdersTab, settingsOrdersTabs } from "./constants";
import styles from "./styles.module.scss";

export const SettingsOrdersView = () => {
  const [tab, setTab] = useState<SettingsOrdersTab>(SettingsOrdersTab.Order);

  return (
    <div>
      <Header title="Сделки" showSearch={false} />
      <Tabs className={styles.tabs} value={tab} onSelect={setTab}>
        {map(settingsOrdersTabs, ({ label, value }) => (
          <Tabs.Item className={styles.tabsItem} key={value} value={value}>
            {label}
          </Tabs.Item>
        ))}
      </Tabs>
    </div>
  );
};
