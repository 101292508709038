import { useEventListener, useTable } from "@/hooks";
import { fetchGetBrands, fetchRemoveBrand } from "@/features/Brand/services";
import { useCallback, useEffect, useState } from "react";
import { BrandEntity, BrandsEntity } from "@/features/Brand/types";
import Table from "@/components/UI/Table";
import { isEmpty } from "lodash";
import LoadingIndicatorPage from "@/components/LoadingIndicatorPage";
import { BrandForm } from "@/features/Brand/components/BrandForm";
import Button from "@/components/UI/Button";
import IconAdd from "@/components/Icons/IconAdd";
import styles from "./styles.module.scss";
import { EVENTS } from "@/constants";
import {
  fetchGetCountries,
  fetchRemoveCountry,
} from "@/features/Country/services";
import { CountriesEntity, CountryEntity } from "@/features/Country/types";
import { CountryForm } from "@/features/Country/components/CountryForm";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { triggerEvent } from "@/utils/event";
import ModalHeader from "@/components/UI/Modal/ModalHeader";
import ModalBody from "@/components/UI/Modal/ModalBody";
import ModalFooter from "@/components/UI/Modal/ModalFooter";
import Flex from "@/components/UI/Flex";
import Modal from "@/components/UI/Modal";
import IconTrash from "@/components/Icons/IconTrash";
import IconButton from "@/components/UI/IconButton";

export const CountryTableList = () => {
  const [countries, setCountries] = useState<CountriesEntity>([]);
  const [isFetching, setIsFetching] = useState(true);
  const [modal, setModal] = useState<{
    country?: CountryEntity | null;
    show: boolean;
  }>({
    country: null,
    show: false,
  });

  const [removeModal, setRemoveModal] = useState<{
    country?: BrandEntity | null;
    show: boolean;
  }>({
    country: null,
    show: false,
  });

  const { pageNumber, pageSize } = useTable();

  const handleFetchGetCountries = useCallback(() => {
    fetchGetCountries()
      .then((data) => {
        setCountries(data.items);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [pageNumber, pageSize]);

  useEffect(() => {
    handleFetchGetCountries();
  }, [pageNumber, pageSize]);

  const handleCloseRemoveModal = useCallback(() => {
    setRemoveModal({ country: null, show: false });
  }, []);

  useEventListener(EVENTS.REFRESH_DATA, handleFetchGetCountries);

  if (isFetching) {
    return <LoadingIndicatorPage />;
  }

  return (
    <>
      <Button
        className={styles.btn}
        onClick={() => setModal({ show: true })}
        endIcon={IconAdd}
        variant="secondary"
      >
        Добавить страну
      </Button>
      <Table
        header={
          <Table.Row>
            <Table.Cell>Название</Table.Cell>
          </Table.Row>
        }
        isEmpty={isEmpty(countries)}
      >
        {countries.map((country, index) => (
          <Table.Row key={country._id}>
            <Table.Cell>
              <Button
                className={styles.itemBtn}
                onClick={() => setModal({ country, show: true })}
                small
                variant="empty"
              >
                {country.title}
              </Button>
              <IconButton
                icon={IconTrash}
                onClick={() =>
                  setRemoveModal({
                    country,
                    show: true,
                  })
                }
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table>

      <CountryForm
        country={modal.country}
        isOpen={modal.show}
        onClose={() => setModal({ show: false })}
      />

      <Modal isOpen={removeModal.show}>
        <CountryRemoveModal
          id={removeModal.country?._id as string}
          onClose={handleCloseRemoveModal}
        />
      </Modal>
    </>
  );
};

const CountryRemoveModal = ({
  id,
  onClose,
}: {
  id: string;
  onClose: () => void;
}) => {
  const { mutateAsync, isLoading } = useMutation({
    mutationKey: "remove-country",
    mutationFn: async (brandId: string) => fetchRemoveCountry(brandId),
    onSuccess: () => {
      toast.success("Страна успешно удалена");
      triggerEvent(EVENTS.REFRESH_DATA);
      onClose();
    },
  });

  return (
    <>
      {isLoading}
      <ModalHeader onClose={onClose}>
        <h2>Удаление страны</h2>
      </ModalHeader>
      <ModalBody>Вы уверены, что хотите удалить страну?</ModalBody>
      <ModalFooter>
        <Flex>
          <Button onClick={() => mutateAsync(id)}>Удалить</Button>
          <Button onClick={onClose} variant="secondary">
            Отменить
          </Button>
        </Flex>
      </ModalFooter>
    </>
  );
};
