import * as Yup from "yup";
import * as messages from "@/utils/validationMessages";
import { Payment } from "@/features/Payment/types";

// @ts-ignore
export const paymentFormSchema: Yup.ObjectSchema<Payment> = Yup.object().shape({
  comment: Yup.string(),
  paymentType: Yup.string(),
  createdAt: Yup.date(),
  from: Yup.string().required(messages.required),
  to: Yup.string().required(messages.required),
  amount: Yup.number().required(messages.required),
  reason: Yup.string(),
  accountingDate: Yup.date().required(messages.required),
  executionDate: Yup.date(),
});
