import Flex from "@/components/UI/Flex";
import Button from "@/components/UI/Button";
import IconAdd from "@/components/Icons/IconAdd";
import SettingsIPAddressesTableList from "../SettingsIPAddressesTableList";
import styles from "./styles.module.scss";

export const SettingsIPAddresses = () => {
  return (
    <div className={styles.wrapper}>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        className={styles.wrapperTop}
      >
        <h2 className={styles.title}>
          Список разрешенных IP адресов для доступа в систему
        </h2>
        <Button endIcon={IconAdd} onClick={() => {}} variant="secondary">
          IP Адрес
        </Button>
      </Flex>
      <SettingsIPAddressesTableList />
    </div>
  );
};
