import { useLocation, useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";

const useTable = (pSize = 20) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const pageNumber = Number(queryParams.get("page")) || 1;
  const pageSize = Number(queryParams.get("pageSize")) || pSize;
  const [totalItems, setTotalItems] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);

  const handlePageChange = useCallback((nextPage: number) => {
    queryParams.set("page", String(nextPage));
    replaceQuery();
  }, []);

  const replaceQuery = useCallback(() => {
    navigate({
      ...location,
      search: String(queryParams),
    });
  }, []);

  return {
    pageNumber,
    pageSize,
    replaceQuery,
    totalItems,
    totalPages,
    setTotalItems,
    setTotalPages,
    handlePageChange,
  };
};

export default useTable;
