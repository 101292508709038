import Flex from "@/components/UI/Flex";
import Button from "@/components/UI/Button";
import IconAdd from "@/components/Icons/IconAdd";
import { SettingsMarkupForWorkTableList } from "../SettingsMarkupForWorkTableList";
import styles from "./styles.module.scss";

export const SettingsMarkupForWork = () => {
  return (
    <div className={styles.wrapper}>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        className={styles.wrapperTop}
      >
        <h2 className={styles.title}>Наценки на работы</h2>
        <Button endIcon={IconAdd} onClick={() => {}} variant="secondary">
          Наценка
        </Button>
      </Flex>
      <SettingsMarkupForWorkTableList />
    </div>
  );
};
