export const agentSocialMediaFormInitialValues = {
  platform: "",
  nickname: "",
};

export enum FormFields {
  Platform = "platform",
  NickName = "nickname",
  IsCompleted = "isCompleted",
}
