import cn from "classnames";
import type { TableCellProps } from "./types";
import type { PropsWithChildren } from "react";
import { isNumber } from "lodash";
import { Dropdown } from "rsuite";
import IconButton from "@/components/UI/IconButton";
import IconArrowDown from "@/components/Icons/IconArrowDown";
import { If } from "@/components/ConditionalRendering/If";
import styles from "./styles.module.scss";

const TableCell = ({
  index,
  children,
  className,
  colSpan,
  showDropdown = false,
  width,
}: PropsWithChildren<TableCellProps>) => {
  const style: Record<string, unknown> = {
    maxWidth: width || "auto",
  };

  if (isNumber(index)) {
    style.zIndex = 2 + index;
  }

  return (
    <td className={cn(styles.cell, className)} colSpan={colSpan} style={style}>
      <div className={styles.cellInner}>
        {children}
        <If condition={showDropdown}>
          <Dropdown
            renderToggle={renderButton}
            title="Dropdown"
            className={styles.dropdown}
            placement="bottomEnd"
          >
            <Dropdown.Item>Скрытые столбцы</Dropdown.Item>
            <Dropdown.Item>Закрепить в начале</Dropdown.Item>
            <Dropdown.Item>Переместить влево</Dropdown.Item>
            <Dropdown.Item>Переместить вправо</Dropdown.Item>
            <Dropdown.Item>Сортировка, A-Z</Dropdown.Item>
            <Dropdown.Item>Сортировка, Z-A</Dropdown.Item>
          </Dropdown>
        </If>
      </div>
    </td>
  );
};

const renderButton = (props: any, ref: any) => {
  return (
    <IconButton
      className={styles.dropdownButton}
      variant="empty"
      {...props}
      ref={ref}
      icon={IconArrowDown}
    />
  );
};

export default TableCell;
