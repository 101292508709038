import cn from "classnames";
import { PatternFormat, PatternFormatProps } from "react-number-format";
import Input from "@/components/UI/Input";
import type { InputDateProps } from "./types";
import styles from "./styles.module.scss";
import { forwardRef } from "react";

// @ts-ignore
const InputNumber = forwardRef<NumericFormat, InputDateProps>(
  (
    {
      error,
      label,
      format = "##.##.####",
      name,
      onChange,
      placeholder,
      value,
      ...props
    },
    ref,
  ) => {
    return (
      <div className={styles.container}>
        {Boolean(label) && label}
        <PatternFormat
          {...props}
          name={name}
          // @ts-ignore
          ref={ref}
          format={format}
          className={cn(styles.input, error && styles.invalid)}
          placeholder={placeholder}
          onChange={onChange}
          customInput={Input}
          inputMode="tel"
          value={value}
        />
        {Boolean(error) && <p className={styles.error}>{error}</p>}
      </div>
    );
  },
);

export default InputNumber;
