import Table from "@/components/UI/Table";
import { useAppProvider } from "@/providers/AppProvider/hooks";
import useEventListener from "@/hooks/useEventListener";
import { EVENTS } from "@/constants";

const SettingsServicesTableList = () => {
  return (
    <Table
      header={
        <Table.Row>
          <Table.Cell>Название IP адреса</Table.Cell>
          <Table.Cell>IP адрес</Table.Cell>
          <Table.Cell>Дата создания</Table.Cell>
          <Table.Cell>Создатель</Table.Cell>
          <Table.Cell>Статус записи</Table.Cell>
        </Table.Row>
      }
    ></Table>
  );
};

export default SettingsServicesTableList;
