import * as Yup from "yup";
import * as messages from "@/utils/validationMessages";
import { Payment } from "@/features/Payment/types";
import { FormFields } from "./constants";

// @ts-ignore
export const orderPaymentFormSchema: Yup.ObjectSchema<Payment> =
  Yup.object().shape({
    [FormFields.Amount]: Yup.number().required(messages.required),
  });
