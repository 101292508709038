const IconWallet = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.167 18.9583H5.83366C2.96699 18.9583 1.04199 17.0333 1.04199 14.1667V10C1.04199 7.43334 2.62533 5.57502 5.08366 5.26668C5.31699 5.23335 5.57533 5.20834 5.83366 5.20834H14.167C14.367 5.20834 14.6253 5.21667 14.892 5.25834C17.3503 5.54167 18.9587 7.40834 18.9587 10V14.1667C18.9587 17.0333 17.0337 18.9583 14.167 18.9583ZM5.83366 6.45834C5.63366 6.45834 5.44199 6.475 5.25033 6.5C3.41699 6.73333 2.29199 8.06668 2.29199 10V14.1667C2.29199 16.3167 3.68366 17.7083 5.83366 17.7083H14.167C16.317 17.7083 17.7087 16.3167 17.7087 14.1667V10C17.7087 8.05001 16.567 6.70835 14.717 6.49168C14.517 6.45835 14.342 6.45834 14.167 6.45834H5.83366Z"
        fill="#D4D4DE"
      />
      <path
        d="M5.15805 6.50835C4.95805 6.50835 4.77471 6.41669 4.64971 6.25002C4.50805 6.05835 4.49138 5.80835 4.59971 5.60002C4.74138 5.31669 4.94138 5.04169 5.19971 4.79169L7.90805 2.07501C9.29138 0.700012 11.5414 0.700012 12.9247 2.07501L14.383 3.55003C14.9997 4.15836 15.3747 4.97503 15.4164 5.8417C15.4247 6.03336 15.3497 6.21667 15.208 6.34167C15.0664 6.46667 14.8747 6.52503 14.6914 6.49169C14.5247 6.46669 14.3497 6.45835 14.1664 6.45835H5.83305C5.63305 6.45835 5.44138 6.47501 5.24971 6.50001C5.22471 6.50834 5.19138 6.50835 5.15805 6.50835ZM6.54971 5.20835H14.0164C13.908 4.92502 13.733 4.66669 13.4997 4.43336L12.033 2.95003C11.1414 2.06669 9.68305 2.06669 8.78305 2.95003L6.54971 5.20835Z"
        fill="#D4D4DE"
      />
      <path
        d="M18.3337 14.375H15.8337C14.567 14.375 13.542 13.35 13.542 12.0834C13.542 10.8167 14.567 9.79169 15.8337 9.79169H18.3337C18.6753 9.79169 18.9587 10.075 18.9587 10.4167C18.9587 10.7584 18.6753 11.0417 18.3337 11.0417H15.8337C15.2587 11.0417 14.792 11.5084 14.792 12.0834C14.792 12.6584 15.2587 13.125 15.8337 13.125H18.3337C18.6753 13.125 18.9587 13.4084 18.9587 13.75C18.9587 14.0917 18.6753 14.375 18.3337 14.375Z"
        fill="#D4D4DE"
      />
    </svg>
  );
};

export default IconWallet;
