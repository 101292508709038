import api from "@/api";
import { UploadFile, UploadFileResponse } from "@/types";

export const uploadFile = async (file: File) => {
  const body = new FormData();

  body.append("file", file);

  const response = await api.post<UploadFileResponse>("/files/upload", body);

  return response.data;
};
