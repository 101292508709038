import styles from "@/features/Settings/components/SettingsServicesForm/styles.module.scss";
import Checkbox from "@/components/UI/Checkbox";
import Flex from "@/components/UI/Flex";
import Input from "@/components/UI/Input";
import IconClock from "@/components/Icons/IconClock";
import { SettingsServicesScheduleFormProps } from "./types";
import { useFormikContext } from "formik";
import { CarServiceSubdivisionEntity } from "@/features/Subdivision/types";
import { FormFields } from "@/features/Settings/components/SettingsServicesForm/constants";
import { SubdivisionWorkday } from "@/types";
import { isBoolean } from "lodash";

const SettingsServicesScheduleForm = ({
  index,
  scheduler,
}: SettingsServicesScheduleFormProps) => {
  const { setValues, values } = useFormikContext<CarServiceSubdivisionEntity>();

  const item = values[FormFields.Schedule][index];

  const handleChange = (name: keyof SubdivisionWorkday, value: any) => {
    item[name] = value;
    setValues(values);
  };

  const handleSetDay = (value: boolean) => {
    item["day"] = value ? scheduler.value : null;
    setValues(values);
  };

  return (
    <Flex alignItems="center">
      <div className={styles.schedulerDay}>
        <Checkbox
          onChange={(e) => {
            handleSetDay(e.target.checked);
          }}
          value={scheduler.label}
        />
      </div>
      <Flex columnGap="sm">
        <Input
          endIcon={IconClock}
          onChange={(e) => {
            handleChange("workStart", e.target.value);
          }}
          label="Время от"
          placeholder="09:00"
          variant="small"
        />
        <Input
          endIcon={IconClock}
          onChange={(e) => {
            handleChange("workEnd", e.target.value);
          }}
          label="Время до"
          placeholder="12:00"
          variant="small"
        />
      </Flex>
    </Flex>
  );
};

export default SettingsServicesScheduleForm;
