import cn from "classnames";
import { OrderStatusesItemProps } from "./types";
import { OrderStatusEnum } from "@/features/Order/types";
import styles from "./styles.module.scss";

const OrderStatusesItem = ({
  isActive = false,
  label,
  status,
  onClick,
}: OrderStatusesItemProps) => {
  return (
    <li>
      <button
        className={cn(
          styles.statusesBtn,
          status === OrderStatusEnum.Success && styles.statusesBtnGreen,
          status === OrderStatusEnum.Junk && styles.statusesBtnRed,
          status === OrderStatusEnum.Declined && styles.statusesBtnGray,
          isActive && styles.statusesBtnActive,
        )}
        disabled={isActive}
        onClick={() => onClick(status)}
      >
        {label}
      </button>
    </li>
  );
};

export default OrderStatusesItem;
