import { useOnce } from "@/hooks";
import { EVENTS } from "@/constants";

const useEventListener = (
  event: EVENTS,
  handler: (params: unknown) => void,
) => {
  useOnce(() => {
    document.addEventListener(event, handler);

    return () => document.removeEventListener(event, handler);
  });
};

export default useEventListener;
