import { CalendarEntryColorEnum } from "@/features/Calendar/types";

export const markers = [
  {
    color: CalendarEntryColorEnum.Blue,
  },
  {
    color: CalendarEntryColorEnum.Black,
  },
  {
    color: CalendarEntryColorEnum.LightPurple,
  },
  {
    color: CalendarEntryColorEnum.Pink,
  },
  {
    color: CalendarEntryColorEnum.Orange,
  },
  {
    color: CalendarEntryColorEnum.LightGreen,
  },
  {
    color: CalendarEntryColorEnum.Green,
  },
  {
    color: CalendarEntryColorEnum.Red,
  },
  {
    color: CalendarEntryColorEnum.Brown,
  },
  {
    color: CalendarEntryColorEnum.Teal,
  },
];
