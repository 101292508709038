import Select from "@/components/UI/Select";
import { useAppProvider } from "@/providers/AppProvider/hooks";
import styles from "./styles.module.scss";
import { CarServiceSubdivisionEntity } from "@/features/Subdivision/types";
import IconLoading from "@/components/Icons/IconLoading";

const SubdivisionsList = () => {
  const {
    appIsReady,
    subdivisions,
    currentSubdivision,
    onSetCurrentSubdivision,
  } = useAppProvider();

  if (!appIsReady) {
    return (
      <div className={styles.loader}>
        <IconLoading stroke="white" width={20} height={20} />
      </div>
    );
  }

  return (
    <Select
      className={styles.select}
      options={subdivisions.map((subdivision) => ({
        label: subdivision.title,
        value: subdivision._id,
      }))}
      onChange={(e) => onSetCurrentSubdivision(e?.value as string)}
      placeholder="Автосервис"
      size="sm"
      value={{
        label: currentSubdivision?.title || "Выберите из списка",
        value: currentSubdivision?._id,
      }}
    />
  );
};

export default SubdivisionsList;
