import Meta from "@/components/Meta";
import Header from "@/pages/CreateAgent/components/Header";
import WarehouseTransferCreateView from "@/features/Warehouse/components/WarehouseTransferCreateView";
import { PATHS } from "@/constants";
import styles from "./styles.module.scss";

const WarehouseTransferCreate = () => {
  return (
    <div className={styles.content}>
      <Meta title="Склад - Перемещения - Новое перемещение" />
      <Header
        breadcrumbs={[
          { label: "Перемещения", path: PATHS.TRANSFER },
          { label: "Новое перемещение" },
        ]}
        title="Новое перемещение"
      />
      <WarehouseTransferCreateView />
    </div>
  );
};

export default WarehouseTransferCreate;
