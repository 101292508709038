import api from "@/api";
import {
  GetWalletsRequestPayload,
  GetWalletsService,
  WalletsFromServer,
} from "@/features/Wallet/types";
import { walletsSerialize } from "./serializer";

export const getWallets: GetWalletsService = async ({
  pageNumber,
  pageSize,
}) => {
  const params: GetWalletsRequestPayload = {
    pageNumber: pageNumber || 1,
    pageSize: pageSize || 20,
  };

  const response = await api.get<WalletsFromServer>("/wallet", {
    params,
  });

  return walletsSerialize(response.data);
};
